const locale = {
  blogCategory: "Blog Category",
  blogCategories: "Blog Categories",
  create: "Create Category",
  update: "Update Category",
  delete: "Delete Category",
  view: "View Category",
  list: "List Categories",
  createdSuccessfully: "Category created successfully",
  updatedSuccessfully: "Category updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Category deleted successfully",
  name: "Name",
  icon: "Icon",
  parentCategory: "Parent Category",
};

export default locale;
