import { createSlice } from "@reduxjs/toolkit";
import { commonInitialState, commonReducers } from "context/slices";

const { actions, reducer } = createSlice({
  name: "policy",
  initialState: {
    ...commonInitialState,
  },
  reducers: {
    ...commonReducers,
  },
});

export const policyActions = actions;
export const policySlice = reducer;
