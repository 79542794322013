import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import {
  useUpdateBlogTargetCategoryMutation,
  useBlogTargetCategoryQuery,
} from "../context/blogTargetCategory.api";
import { useFormik } from "formik";
import { useAppDispatch } from "context";
import { blogActions } from "../../../context";
import { IUpdateBlogTargetCategoryInput } from "corede-common-cocrm";
import { IBaseError, IGraphqlVariables } from "corede-common";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { t } from "i18next";
import { useParams, useNavigate } from "react-router-dom";
import { validateUpdateBlogTargetCategoryInput } from "../validations/update.validation";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { listBlogTargetCategoryRoute } from "../../../routes/blog.base.route";

const Update = () => {

  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const { id } = useParams();
  if (!id) {
    navigate(listBlogTargetCategoryRoute());
  }

  // queries
  const {
    data: blogTargetCategoryData,
    isLoading: blogTargetCategoryIsLoading,
    error: blogTargetCategoryError,
  } = useBlogTargetCategoryQuery({ input: { _id: id! } });

  // mutations
  const [
    updateBlogTargetCategory,
    {
      data: updateBlogTargetCategoryData,
      isLoading: updateBlogTargetCategoryIsLoading,
      error: updateBlogTargetCategoryError,
    },
  ] = useUpdateBlogTargetCategoryMutation();

  // constants
  const {
    values,
    handleSubmit,
    handleBlur,
    setFieldValue,
    errors,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      input: {
        name: "",
        icon: "",
        nameTranslation: undefined,
      },
    },
    enableReinitialize: true,
    validationSchema: validateUpdateBlogTargetCategoryInput,
    onSubmit: (values: IGraphqlVariables<IUpdateBlogTargetCategoryInput>) => {
      updateBlogTargetCategory({
        filter: { _id: id! },
        input: values.input,
      });
    },
  });

  // useEffects.success
  useEffect(() => {
    console.log(blogTargetCategoryData);
    if (blogTargetCategoryData) {
      setValues({
        input: {
          name: blogTargetCategoryData.name,
          icon: blogTargetCategoryData.icon,
          nameTranslation: blogTargetCategoryData.nameTranslation
        },
      });
    }
  }, [blogTargetCategoryData, setValues]);

  useEffect(() => {
    if (updateBlogTargetCategoryData) {
      resetForm();
      enqueueSnackbar(t("main.blog.blogTargetCategory.updatedSuccessfully"), {
        variant: "success",
      });
    }
  }, [updateBlogTargetCategoryData, resetForm]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      updateBlogTargetCategoryError as IBaseError,
      currentLanguage
    );
  }, [updateBlogTargetCategoryError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      blogTargetCategoryError as IBaseError,
      currentLanguage
    );
  }, [blogTargetCategoryError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    dispatch(blogActions.setTitle(t("main.blog.blogTargetCategory.update")));
    dispatch(blogActions.setBackButton(true));
    dispatch(blogActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2} maxWidth={400}>
        {blogTargetCategoryIsLoading ? (
          <CircularProgress />
        ) : (
          <Stack direction="column" gap={2}>
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              value={(values as any).input.name}
              onChange={(e) => setFieldValue("input.name", e.target.value)}
              onBlur={() => handleBlur("input.name")}
              error={Boolean((errors as any).input?.name)}
              helperText={(errors as any).input?.name}
            />
            <TextField
              fullWidth
              label="Name - English"
              variant="outlined"
              value={(values as any).input.nameTranslation?.en}
              onChange={(e) => setFieldValue("input.nameTranslation.en", e.target.value)}
              onBlur={() => handleBlur("input.nameTranslation.en")}
              error={Boolean((errors as any).input?.nameTranslation?.en)}
              helperText={(errors as any).input?.nameTranslation?.en}
            />
            <TextField
              fullWidth
              label="Name - Turkish"
              variant="outlined"
              value={(values as any).input.nameTranslation?.tr}
              onChange={(e) => setFieldValue("input.nameTranslation.tr", e.target.value)}
              onBlur={() => handleBlur("input.nameTranslation.tr")}
              error={Boolean((errors as any).input?.nameTranslation?.tr)}
              helperText={(errors as any).input?.nameTranslation?.tr}
            />
            <TextField
              fullWidth
              label="Icon"
              variant="outlined"
              value={(values as any).input.icon}
              onChange={(e) => setFieldValue("input.icon", e.target.value)}
              onBlur={() => handleBlur("input.icon")}
              error={Boolean((errors as any).input?.icon)}
              helperText={(errors as any).input?.icon}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
              disabled={updateBlogTargetCategoryIsLoading}
            >
              {updateBlogTargetCategoryIsLoading ? (
                <CircularProgress size="1rem" sx={{ mr: 2 }} />
              ) : (
                ""
              )}
              {t("update")}
            </Button>
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default Update;
