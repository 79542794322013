import { Grid, Typography } from "@mui/material";
import { useAppDispatch } from "context";
import { dashboardActions } from "../context";

const Dashboard = () => {
  const dispatch = useAppDispatch();

  dispatch(dashboardActions.setTitle("Dashboard"));

  return (
    <Grid item xs={12}>
      <Typography variant="body1">Dashboard Page</Typography>
    </Grid>
  );
};

export default Dashboard;
