import { Route } from "react-router-dom";

import { paymentProductDomainRoutes } from "../domains/paymentProduct/routes/paymentProduct.base.route";
import { PaymentProductDomainRouter } from "../domains/paymentProduct/routes/PaymentProductDomainRouter";


import { notificationDomainRoutes } from "../domains/notification/routes/notification.base.route";
import { NotificationDomainRouter } from "../domains/notification/routes/NotificationDomainRouter";

import { helpCenterDomainRoutes } from "../domains/helpCenter/routes/helpCenter.base.route";
import { HelpCenterDomainRouter } from "../domains/helpCenter/routes/HelpCenterDomainRouter";

import { subscriptionDomainRoutes } from "../domains/subscription/routes/subscription.base.route";
import { SubscriptionDomainRouter } from "../domains/subscription/routes/SubscriptionDomainRouter";

import { contactFormDomainRoutes } from "../domains/contactForm/routes/contactForm.base.route";
import { ContactFormDomainRouter } from "../domains/contactForm/routes/ContactFormDomainRouter";

import { webinarDomainRoutes } from "../domains/webinar/routes/webinar.base.route";
import { WebinarDomainRouter } from "../domains/webinar/routes/WebinarDomainRouter";

import { policyDomainRoutes } from "../domains/policy/routes/policy.base.route";
import { PolicyDomainRouter } from "../domains/policy/routes/PolicyDomainRouter";

import { formDomainRoutes } from "../domains/form/routes/form.base.route";
import { FormDomainRouter } from "../domains/form/routes/FormDomainRouter";

import { faqDomainRoutes } from "../domains/faq/routes/faq.base.route";
import { FaqDomainRouter } from "../domains/faq/routes/FaqDomainRouter";

import MainLayout from "../layouts/MainLayout";
import { BlogDomainRouter } from "../domains/blog/routes/BlogDomainRouter";
import { DashboardRouter } from "../domains/dashboard/routes/DashboardRouter";
import { blogDomainRoutes } from "../domains/blog/routes/blog.base.route";
import { dashboardDomainRoutes } from "../domains/dashboard/routes/dashboard.base.route";

export const MainRouter = () => {
  return (
    <Route path="/" element={<MainLayout />}>
      <Route index element={<DashboardRouter />} />
      <Route
        path={`${dashboardDomainRoutes.base}/*`}
        element={<DashboardRouter />}
      />
      <Route path={`${blogDomainRoutes.base}/*`} element={<BlogDomainRouter />} />
      <Route path="*" element={<DashboardRouter />} />
			<Route 
				path={`${faqDomainRoutes.base}/*`}
				element={<FaqDomainRouter />}
			/>
			<Route 
				path={`${formDomainRoutes.base}/*`}
				element={<FormDomainRouter />}
			/>
			<Route 
				path={`${policyDomainRoutes.base}/*`}
				element={<PolicyDomainRouter />}
			/>
			<Route 
				path={`${webinarDomainRoutes.base}/*`}
				element={<WebinarDomainRouter />}
			/>
			<Route 
				path={`${contactFormDomainRoutes.base}/*`}
				element={<ContactFormDomainRouter />}
			/>
			<Route 
				path={`${subscriptionDomainRoutes.base}/*`}
				element={<SubscriptionDomainRouter />}
			/>
			<Route 
				path={`${helpCenterDomainRoutes.base}/*`}
				element={<HelpCenterDomainRouter />}
			/>
			<Route 
				path={`${notificationDomainRoutes.base}/*`}
				element={<NotificationDomainRouter />}
			/>
			<Route 
				path={`${paymentProductDomainRoutes.base}/*`}
				element={<PaymentProductDomainRouter />}
			/>
    </Route>
  );
};
