import { Button, Grid, Stack, Typography } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppSelector } from "context";
import { ArrowBackIos } from "@mui/icons-material";
import { handleRightButton } from "context/actions";

export const WebinarDomainLayout = () => {
  const navigate = useNavigate();
  const { title, rightButton, backButton } = useAppSelector(
    (state) => state.webinar
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          gap={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" alignItems="center">
            {backButton && (
              <ArrowBackIos
                onClick={() => navigate(-1)}
                style={{ cursor: "pointer" }}
                fontSize="large"
              />
            )}
            <Typography
              sx={{ fontSize: { xs: "32px", md: "42px" }, fontWeight: "bold" }}
            >
              {title}
            </Typography>
          </Stack>
          {rightButton && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleRightButton(rightButton.path, navigate)}
            >
              {rightButton?.title}
            </Button>
          )}
        </Stack>
      </Grid>
      <Outlet />
    </Grid>
  );
};
