import {
  IBlogTargetCategoriesInput,
  IBlogTargetCategoriesRequest,
  IBlogTargetCategoriesResponse,
  IBlogTargetCategoriesResult,
  blogTargetCategoryQuery,
  blogTargetCategoriesQuery,
  IBlogTargetCategoryInput,
  IBlogTargetCategoryRequest,
  IBlogTargetCategoryResponse,
  IBlogTargetCategoryResult,
  ICreateBlogTargetCategoryInput,
  ICreateBlogTargetCategoryRequest,
  ICreateBlogTargetCategoryResponse,
  ICreateBlogTargetCategoryResult,
  createBlogTargetCategoryQuery,
  IUpdateBlogTargetCategoryInput,
  IUpdateBlogTargetCategoryRequest,
  IUpdateBlogTargetCategoryResponse,
  IUpdateBlogTargetCategoryResult,
  updateBlogTargetCategoryQuery,
  IUpdateBlogTargetCategoryFilterInput,
  deleteBlogTargetCategoryQuery,
  IDeleteBlogTargetCategoryInput,
  IDeleteBlogTargetCategoryRequest,
  IDeleteBlogTargetCategoryResponse,
  IDeleteBlogTargetCategoryResult,
} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { blogTargetCategoryTags } from "./blogTargetCategory.tags";
import { IGraphqlVariables } from "corede-common";

export const blogTargetCategoryApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    blogTargetCategories: builder.query<
      IBlogTargetCategoriesResult,
      IGraphqlVariables<IBlogTargetCategoriesInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IBlogTargetCategoriesRequest,
        IBlogTargetCategoriesResponse,
        IBlogTargetCategoriesResult,
        IBlogTargetCategoriesInput
      >({
        query: blogTargetCategoriesQuery,
        providesTags: [blogTargetCategoryTags.blogTargetCategories],
      })
    ),

    blogTargetCategory: builder.query<
      IBlogTargetCategoryResult,
      IGraphqlVariables<IBlogTargetCategoryInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IBlogTargetCategoryRequest,
        IBlogTargetCategoryResponse,
        IBlogTargetCategoryResult,
        IBlogTargetCategoryInput
      >({
        query: blogTargetCategoryQuery,
        providesTags: [blogTargetCategoryTags.blogTargetCategory],
      })
    ),

    // mutation
    createBlogTargetCategory: builder.mutation<
      ICreateBlogTargetCategoryResult,
      IGraphqlVariables<ICreateBlogTargetCategoryInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICreateBlogTargetCategoryRequest,
        ICreateBlogTargetCategoryResponse,
        ICreateBlogTargetCategoryResult,
        ICreateBlogTargetCategoryInput
      >({
        query: createBlogTargetCategoryQuery,
        invalidatesTags: [blogTargetCategoryTags.blogTargetCategories],
      })
    ),

    updateBlogTargetCategory: builder.mutation<
      IUpdateBlogTargetCategoryResult,
      IGraphqlVariables<
        IUpdateBlogTargetCategoryInput,
        IUpdateBlogTargetCategoryFilterInput
      >
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUpdateBlogTargetCategoryRequest,
        IUpdateBlogTargetCategoryResponse,
        IUpdateBlogTargetCategoryResult,
        IUpdateBlogTargetCategoryInput,
        IUpdateBlogTargetCategoryFilterInput
      >({
        query: updateBlogTargetCategoryQuery,
        invalidatesTags: [
          blogTargetCategoryTags.blogTargetCategories,
          blogTargetCategoryTags.blogTargetCategory,
        ],
      })
    ),

    deleteBlogTargetCategory: builder.mutation<
      IDeleteBlogTargetCategoryResult,
      IGraphqlVariables<IDeleteBlogTargetCategoryInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IDeleteBlogTargetCategoryRequest,
        IDeleteBlogTargetCategoryResponse,
        IDeleteBlogTargetCategoryResult,
        IDeleteBlogTargetCategoryInput
      >({
        query: deleteBlogTargetCategoryQuery,
        invalidatesTags: [
          blogTargetCategoryTags.blogTargetCategories,
          blogTargetCategoryTags.blogTargetCategory,
        ],
      })
    ),
  }),
});

export const {
  useBlogTargetCategoriesQuery,
  useLazyBlogTargetCategoriesQuery,

  useBlogTargetCategoryQuery,
  useLazyBlogTargetCategoryQuery,

  useCreateBlogTargetCategoryMutation,
  useUpdateBlogTargetCategoryMutation,
  useDeleteBlogTargetCategoryMutation,
} = blogTargetCategoryApi;
