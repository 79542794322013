import {
  Autocomplete,
  Button,
  Card,
  Chip,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  useCreateBlogMutation,
} from "../context/blog.api";
import { useFormik } from "formik";
import { useAppDispatch } from "context";
import { blogActions } from "../../../context";
import { ICreateBlogInput } from "corede-common-cocrm";
import { IBaseError, IFileMetadata, IGraphqlVariables, Language } from "corede-common";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { validateCreateBlogInput } from "../validations/create.validation";
import { t } from "i18next";
import { RequestTransformerHelper } from "validations/request.transformer.helper";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { compressImage } from "utils/_imageUpload";
import { useAuthorsQuery } from "../../author";
import { useBlogCategoriesQuery } from "../../blogCategory";
import { useBlogTargetCategoriesQuery } from "../../blogTargetCategory";
import { UploadBlogImage } from "components";


const Create = () => {
  // general
  const dispatch = useAppDispatch();
  const currentLanguage = getCurrentLanguage();
  const [blogImage, setBlogImage] = useState<File | undefined>(undefined);
  const [tagValue, setTagValue] = useState('');
  const [referenceValue, setReferenceValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState<IFileMetadata | null>(null);

  console.log(imageFile, 'imageFile');

  // queries
  const {
    data: authorsData,
    isLoading: authorsIsLoading,
    error: authorsError,
  } = useAuthorsQuery({});
  const {
    data: blogCategoriesData,
    isLoading: blogCategoriesIsLoading,
    error: blogCategoriesError,
  } = useBlogCategoriesQuery({});
  const {
    data: blogTargetCategoriesData,
    isLoading: blogTargetCategoriesIsLoading,
    error: blogTargetCategoriesError,
  } = useBlogTargetCategoriesQuery({});

  // mutations
  const [
    createBlog,
    {
      data: createBlogData,
      isLoading: createBlogIsLoading,
      error: createBlogError,
    },
  ] = useCreateBlogMutation();

  // constants
  const { values, handleSubmit, handleBlur, setFieldValue, errors, resetForm } =
    useFormik({
      initialValues: {
        input: {
          title: "",
          content: "",
          description: "",
          image: undefined,
          readingTime: "",
          tags: [],
          references: [],
          relatedVideo: "",
          authorId: "",
          categoryId: "",
          targetCategoryId: "",
          language: currentLanguage,
        },
      },
      enableReinitialize: true,
      validationSchema: validateCreateBlogInput,
      onSubmit: async (values: IGraphqlVariables<ICreateBlogInput>) => {
        setLoading(true);
        const transformedValues =
          RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
            input: values.input,
          });

        createBlog({
          ...values,
          input: {
            ...transformedValues,
            title: values?.input?.title ?? "",
            content: values?.input?.content ?? "",
            description: values?.input?.description ?? "",
            readingTime: values?.input?.readingTime ?? "",
            tags: values?.input?.tags ?? [""],
            authorId: values?.input?.authorId ?? "",
            categoryId: values?.input?.categoryId ?? "",
            targetCategoryId: values?.input?.targetCategoryId ?? "",
            language: values?.input?.language ?? currentLanguage,
            image: imageFile ?? undefined,
          },



        }).catch((error) => {
          console.log(error, 'error');
          setLoading(false);
        });


      },
    });


  const handleSetBlogImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = (event.target as HTMLInputElement).files?.[0];
    const compressedImage = file && await compressImage(file, 0.5)
    console.log({ file, compressedImage });
    setBlogImage(compressedImage as File);
  }

  const handleKeyDownTag = (event: any) => {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault();
      const value = tagValue.trim();
      if (value && !(values as any).input.tags.includes(value)) {
        setFieldValue("input.tags", [...(values as any).input.tags, value]);
        setTagValue('');
      }
    }
  };

  const handleKeyDownReference = (event: any) => {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault();
      const value = referenceValue.trim();
      if (value && !(values as any).input.references.includes(value)) {
        setFieldValue("input.references", [...(values as any).input.references, value]);
        setReferenceValue('');
      }
    }
  }

  const handleDeleteTag = (tagToDelete: string) => {
    setFieldValue("input.tags", (values as any).input.tags.filter((tag: string) => tag !== tagToDelete));
  };

  const handleDeleteReference = (referenceToDelete: string) => {
    setFieldValue("input.references", (values as any).input.references.filter((reference: string) => reference !== referenceToDelete));
  };



  // useEffects.success
  useEffect(() => {
    if (createBlogData) {
      console.log(createBlogData, "data");
      resetForm();
      setBlogImage(undefined);
      enqueueSnackbar(t("main.blog.blog.createdSuccessfully"), {
        variant: "success",
      });
    }
  }, [createBlogData, resetForm]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      createBlogError as IBaseError,
      currentLanguage
    );
  }, [createBlogError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      authorsError as IBaseError,
      currentLanguage
    );
  }, [authorsError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      blogCategoriesError as IBaseError,
      currentLanguage
    );
  }, [blogCategoriesError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      blogTargetCategoriesError as IBaseError,
      currentLanguage
    );
  }, [blogTargetCategoriesError, currentLanguage]);


  // useEffects.init
  useEffect(() => {
    dispatch(blogActions.setTitle(t("main.blog.blog.create")));
    dispatch(blogActions.setBackButton(true));
    dispatch(blogActions.setRightButton(null));
  }, [dispatch]);

  // useEffect(() => {
  //   if (blogImage) {
  //     refetch();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [blogImage]);

  console.log((errors as any).input?.description, 'errors');
  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2}>
        <TextField
          fullWidth
          name="input.title"
          label={t("main.blog.blog.title")}
          value={(values as any).input.title}
          onChange={(e) => setFieldValue("input.title", e.target.value)}
          onBlur={() => handleBlur("input.title")}
          error={!!(errors as any).input?.title}
          helperText={(errors as any).input?.title}
        />
        <TextField
          fullWidth
          id="content"
          multiline
          rows={2}
          name="input.content"
          label={t("main.blog.blog.content")}
          value={(values as any).input.content}
          onChange={(e) => setFieldValue("input.content", e.target.value)}
          onBlur={() => handleBlur("input.content")}
          error={!!(errors as any).input?.content}
          helperText={(errors as any).input?.content}
        />
        <ReactQuill
          theme="snow"
          value={(values as any).input.description}
          onChange={(e) => setFieldValue("input.description", e)}
          onBlur={() => handleBlur("input.description")}
          placeholder={t("main.blog.blog.description")}
          scrollingContainer={'.ql-editor'}
          style={{ height: 300, marginBottom: 20, paddingBottom: 10 }}
        />
        {(errors as any).input?.description && (
          <Typography color="error" variant="caption">
            {(errors as any).input?.description}
          </Typography>
        )}

        {/* <Box
          sx={{
            width: "100% -20px",
            height: "100px",
            backgroundColor: "#F3F6F8",
            borderRadius: 2,
            border: "1px solid #CDCFD1",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 1,
            pl: "20px",
          }}
          onClick={() => document.getElementById("profile_image_input")?.click()}
        >
          {blogImage ? <img src={URL.createObjectURL(blogImage)} alt="blogImage"
            style={{ width: "250px", height: "90px", borderRadius: "15px", objectFit: "cover" }} /> : null}
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              color: "#A0A3A8",
            }}
          >
            {blogImage ? blogImage.name : "Upload Image"}
          </Typography>
        </Box>



        <Box
          component="input"
          type="file"
          accept="image/jpeg, image/png"
          sx={{ display: 'none' }}
          id="profile_image_input"
          onChange={handleSetBlogImage}
        /> */}

        <UploadBlogImage
          setImageFile={setImageFile}
        />

        <TextField
          fullWidth
          id="readingTime"
          name="input.readingTime"
          label={t("main.blog.blog.readingTime")}
          value={(values as any).input.readingTime}
          onChange={(e) => setFieldValue("input.readingTime", e.target.value)}
          onBlur={() => handleBlur("input.readingTime")}
          error={!!(errors as any).input?.readingTime}
          helperText={(errors as any).input?.readingTime}
          inputProps={{ type: "number" }}
        />
        <Card sx={{ padding: 2 }}>
          <Stack direction="row" gap={1} alignItems={"center"} flexWrap={"wrap"} mb={2}>
            {t("main.blog.blog.tags")}:
            {(values as any).input.tags.map((tag: any, index: any) => (
              <Chip
                key={index}
                label={tag}
                onDelete={() => handleDeleteTag(tag)}
                style={{ margin: '4px' }}
              />
            ))}
          </Stack>
          <TextField
            value={tagValue}
            onChange={(e) => setTagValue(e.target.value)}
            onKeyDown={handleKeyDownTag}
            label={t("main.blog.blog.addTags")}
            size="small"
            variant="outlined"
            fullWidth
            placeholder={t("main.blog.blog.pleaseEnterTags")}
          />
        </Card>
        <Card sx={{ padding: 2 }}>
          <Stack direction="row" gap={1} alignItems={"center"} flexWrap={"wrap"} mb={2}>
            {t("main.blog.blog.references")}:
            {(values as any).input.references.map((reference: any, index: any) => (
              <Chip
                key={index}
                label={reference}
                onDelete={() => handleDeleteReference(reference)}
                style={{ margin: '4px' }}
              />
            ))}
          </Stack>
          <TextField
            value={referenceValue}
            size="small"
            onChange={(e) => setReferenceValue(e.target.value)}
            onKeyDown={handleKeyDownReference}
            label={t("main.blog.blog.addReferences")}
            variant="outlined"
            fullWidth
            placeholder={t("main.blog.blog.pleaseEnterReferences")}
          />
        </Card>
        <TextField
          fullWidth
          name="input.relatedVideo"
          label={t("main.blog.blog.relatedVideo")}
          value={(values as any).input.relatedVideo}
          onChange={(e) => setFieldValue("input.relatedVideo", e.target.value)}
          onBlur={() => handleBlur("input.relatedVideo")}
          error={!!(errors as any).input?.relatedVideo}
          helperText={(errors as any).input?.relatedVideo}
        />
        <Autocomplete
          id="authorId"
          isOptionEqualToValue={(option, value) => option._id === value._id}
          getOptionLabel={(option) => option.name}
          options={authorsData?.data || []}
          loading={authorsIsLoading}
          renderInput={(params) => <TextField {...params} label={t("main.blog.blog.author")}
            error={!!(errors as any).input?.authorId}
            helperText={(errors as any).input?.authorId}
          />}
          value={authorsData?.data?.find((category) => category._id === (values as any).input.authorId) || null}
          onChange={(e, value) => {
            setFieldValue("input.authorId", value?._id);
          }}
          onBlur={() => handleBlur("input.authorId")}
        />
        <Autocomplete
          id="categoryId"
          isOptionEqualToValue={(option, value) => option._id === value._id}
          getOptionLabel={(option) => option.name}
          options={blogCategoriesData?.data || []}
          loading={blogCategoriesIsLoading}
          renderInput={(params) => <TextField {...params} label={t("main.blog.blog.category")}
            error={!!(errors as any).input?.categoryId}
            helperText={(errors as any).input?.categoryId}
          />}
          value={blogCategoriesData?.data?.find((category) => category._id === (values as any).input.categoryId) || null}
          onChange={(e, value) => {
            setFieldValue("input.categoryId", value?._id);
          }}
          onBlur={() => handleBlur("input.categoryId")}
        />
        <Autocomplete
          id="targetCategoryId"
          isOptionEqualToValue={(option, value) => option._id === value._id}
          getOptionLabel={(option) => option.name}
          options={blogTargetCategoriesData?.data || []}
          loading={blogTargetCategoriesIsLoading}
          renderInput={(params) => <TextField {...params} label={t("main.blog.blog.targetCategory")}
            error={!!(errors as any).input?.targetCategoryId}
            helperText={(errors as any).input?.targetCategoryId}
          />}
          value={blogTargetCategoriesData?.data?.find((category) => category._id === (values as any).input.targetCategoryId) || null}
          onChange={(e, value) => {
            setFieldValue("input.targetCategoryId", value?._id);
          }}
          onBlur={() => handleBlur("input.targetCategoryId")}
        />
        <Autocomplete
          id="language"
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => option}
          options={Object.values(Language)}
          renderInput={(params) => <TextField {...params} label={t("main.blog.blog.language")}
            error={!!(errors as any).input?.language}
            helperText={(errors as any).input?.language}
          />}
          value={(values as any).input.language}
          onChange={(e, value) => {
            setFieldValue("input.language", value);
          }}
          onBlur={() => handleBlur("input.language")}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
          disabled={createBlogIsLoading || authorsIsLoading || loading || blogCategoriesIsLoading || blogTargetCategoriesIsLoading}
        >
          {(createBlogIsLoading || loading) ? (
            <CircularProgress size="1rem" sx={{ mr: 2 }} />
          ) : (
            ""
          )}
          Create
        </Button>
      </Stack>
    </Grid >
  );
};

export default Create;
