import faqLocale from "../subdomains/faq/locales/tr";
import faqCategoryLocale from "../subdomains/faqCategory/locales/tr";

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////

  faq: {
    ...faqLocale,
  },

  // TODO: import subdomain locales
  faqCategory: {
    ...faqCategoryLocale,
  },
};

export default locale;
