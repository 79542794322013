import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import ListItemText from '@mui/material/ListItemText';
// routes
import { RouterLink } from 'minimal/routes/components';
//
import Iconify from '../../iconify';
import { Iconify as MyIconify } from 'components';
//
import { StyledItem, StyledIcon, StyledDotIcon } from './styles';

import { useTheme } from '@mui/material';

// ----------------------------------------------------------------------



export default function NavItem({ item, open, depth, active, config, externalLink, ...other }) {
  const { title, path, icon, iconName, info, children, disabled, caption, roles } = item;
  const theme = useTheme();

  const StyleSheet = {
    imgIcon: {
      width: '24px', height: '24px', marginRight: 10, filter: active ? 'invert(29%) sepia(75%) saturate(1807%) hue-rotate(231deg) brightness(85%) contrast(96%)' : 'contrast(45%) brightness(200%) saturate(0%)'
    }
  }

  const subItem = depth !== 1;

  const renderContent = <>
    <StyledItem
      disableGutters
      disabled={disabled}
      active={active}
      depth={depth}
      config={config}
      {...other}
    >
      <>


        {
          iconName ? <MyIconify icon={iconName} fontSize={24} sx={{ mr: 2 }} /> :
            icon ? <Box sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>{icon}</Box> :
              null
        }

        {subItem && (
          <StyledIcon size={config.iconSize}>
            <StyledDotIcon active={active} />
          </StyledIcon>
        )}
      </>

      {!(config.hiddenLabel && !subItem) && (
        <ListItemText
          primary={title}
          secondary={
            caption ? (
              <Tooltip title={caption} placement="top-start">
                <span>{caption}</span>
              </Tooltip>
            ) : null
          }
          primaryTypographyProps={{
            noWrap: true,
            typography: 'body2',
            fontSize: 15,
            textTransform: 'capitalize',
            fontWeight: active ? 'fontWeightBold' : 'fontWeightSemiBold',
          }}
          secondaryTypographyProps={{
            noWrap: true,
            component: 'span',
            typography: 'caption',
            color: 'text.disabled',
          }}
        />
      )}

      {info && (
        <Box component="span" sx={{ ml: 1, lineHeight: 0 }}>
          {info}
        </Box>
      )}

      {!!children && (
        <Iconify
          width={16}
          icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
          sx={{ ml: 1, flexShrink: 0 }}
        />
      )}
    </StyledItem>
  </>;

  // Hidden item by role
  if (roles && !roles.includes(`${config.currentRole}`)) {
    return null;
  }

  // External link
  if (externalLink)
    return (
      <Link
        href={path}
        target="_blank"
        rel="noopener"
        underline="none"
        color="inherit"
        sx={{
          ...(disabled && {
            cursor: 'default',
          }),
        }}
      >
        {renderContent}
      </Link>
    );

  // Has child
  if (children) {
    return <Link
      component={RouterLink}
      href={path}
      underline="none"
      color="inherit"
      sx={{
        ...(disabled && {
          cursor: 'default',
        }),
      }}
    >
      {renderContent}
    </Link>;
  }

  // Default
  return (
    <Link
      component={RouterLink}
      href={path}
      underline="none"
      color="inherit"
      sx={{
        ...(disabled && {
          cursor: 'default',
        }),
      }}
    >
      {renderContent}
    </Link>
  );
}

NavItem.propTypes = {
  active: PropTypes.bool,
  config: PropTypes.object,
  depth: PropTypes.number,
  externalLink: PropTypes.bool,
  item: PropTypes.object,
  open: PropTypes.bool,
};
