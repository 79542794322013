import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { t } from "i18next";
import { useEffect } from "react";
import { useDeleteBlogCategoryMutation } from "../context/blogCategory.api";

import { IBaseError } from "corede-common";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { IBaseBlogCategoryEntity } from "corede-common-cocrm";

const Delete = (prop: {
  open: boolean;
  item: IBaseBlogCategoryEntity;
  onClose: () => void;
}) => {
  const currentLanguage = getCurrentLanguage();
  // queries
  // mutations
  const [
    deleteBlogCategory,
    {
      data: deleteBlogCategoryData,
      isLoading: deleteBlogCategoryIsLoading,
      error: deleteBlogCategoryError,
    },
  ] = useDeleteBlogCategoryMutation();
  // constants
  // action handlers
  const handleDelete = (_id: string) => {
    deleteBlogCategory({ input: { _id: _id } });
  };

  // useEffects.success
  useEffect(() => {
    if (deleteBlogCategoryData) {
      prop?.onClose();
      enqueueSnackbar(t("main.blog.blogCategory.deletedSuccessfully"), {
        variant: "success",
      });
    }
  }, [deleteBlogCategoryData]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      deleteBlogCategoryError as IBaseError,
      currentLanguage
    );
  }, [deleteBlogCategoryError, currentLanguage]);

  // useEffects.init

  return (
    <Dialog
      open={prop?.open}
      onClose={prop?.onClose}
      sx={{
        "& .MuiDialog-paper": {
          padding: 1,
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle>{t("main.blog.blogCategory.delete")}</DialogTitle>
      <DialogContent>
        {t("main.blog.blogCategory.deleteConfirm")} {prop?.item?.name}?
      </DialogContent>
      <DialogActions>
        <Button onClick={prop?.onClose} color="primary">
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="error"
          disabled={deleteBlogCategoryIsLoading}
          onClick={() => {
            handleDelete(prop?.item?._id);
          }}
        >
          {t("delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Delete;
