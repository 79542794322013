const locale = {
  author: "Yazar",
  authors: "Yazarlar",
  create: "Oluştur",
  update: "Güncelle",
  delete: "Sil",
  view: "Görüntüle",
  list: "Listele",
  createdSuccessfully: "Başarıyla oluşturuldu.", 
  updatedSuccessfully: "Başarıyla güncellendi.",
  deleteConfirm: "Silmek istediğinizden emin misiniz: ",
  deletedSuccessfully: "Başarıyla silindi.",
  name: "Ad",
  bio: "Biyografi",
};

export default locale;
