import { getTranslatedText } from "localization";
import { ValidationHelper } from "validations/validation.helper";

export const validateUpdateBlogCategoryInput =
  ValidationHelper.BuildObjectValidationSchema({
    input: ValidationHelper.BuildObjectValidationSchema({
      name: ValidationHelper.RequiredString(
        getTranslatedText("nameIsRequired")
      ),
      icon: ValidationHelper.NotRequiredString(),
      parentCategoryId: ValidationHelper.NotRequiredString(),
    }),
  });
