import blogCategoryLocale from "../subdomains/blogCategory/locales/tr";
import blogTargetCategoryLocale from "../subdomains/blogTargetCategory/locales/tr";
import authorLocale from "../subdomains/author/locales/tr";
import blogLocale from "../subdomains/blog/locales/tr";

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////

  blog: {
    ...blogLocale,
  },
  blogCategory: {
    ...blogCategoryLocale,
  },
  author: {
    ...authorLocale,
  },
  blogTargetCategory: {
    ...blogTargetCategoryLocale,
  },
};

export default locale;
