const locale = {
  blogTargetCategory: "Blogtargetcategory",
  blogTargetCategories: "Blogtargetcategories",
  create: "Create Blogtargetcategory",
  update: "Update Blogtargetcategory",
  delete: "Delete Blogtargetcategory",
  view: "View Blogtargetcategory",
  list: "List Blogtargetcategories",
  createdSuccessfully: "Blogtargetcategory created successfully",
  updatedSuccessfully: "Blogtargetcategory updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Blogtargetcategory deleted successfully",
};

export default locale;
