


import { IMenuItem } from "interfaces/menu-item.interface";
import { getTranslatedText } from "localization";
import { baseWebinarRoute } from "../routes/webinar.base.route";

export const webinarDomainMenuItem: IMenuItem = {
  title: getTranslatedText("webinar"),
  path: baseWebinarRoute(),
  iconName: "heroicons-outline:status-online"
};
