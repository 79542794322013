import { BlogTargetCategoryRouter } from "../subdomains/blogTargetCategory/routes/BlogTargetCategoryRouter";

import { BlogCategoryRouter } from "../subdomains/blogCategory/routes/BlogCategoryRouter";
import { BlogRouter } from "../subdomains/blog/routes/BlogRouter";
import { AuthorRouter } from "../subdomains/author/routes/AuthorRouter";
import { BlogDomainLayout } from "../layouts";
import { blogDomainRoutes } from "./blog.base.route";
import { Route, Routes } from "react-router-dom";


export const BlogDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<BlogDomainLayout />}>
        <Route index element={<BlogRouter />} />
        <Route
          path={`/${blogDomainRoutes.subdomains.blog.base}/*`}
          element={<BlogRouter />}
        />
        <Route
          path={`/${blogDomainRoutes.subdomains.blogCategory.base}/*`}
          element={<BlogCategoryRouter />}
        />
        <Route
          path={`/${blogDomainRoutes.subdomains.author.base}/*`}
          element={<AuthorRouter />}
        />
        <Route
          path={`/${blogDomainRoutes.subdomains.blogTargetCategory.base}/*`}
          element={< BlogTargetCategoryRouter />}
        />

        {/* "add subdomain Route here" */}
      </Route>
    </Routes>
  );
};
