import contactFormLocale from "../subdomains/contactForm/locales/en";
import requestFormLocale from "../subdomains/requestForm/locales/en";

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////

  contactForm: {
    ...contactFormLocale,
  },
  requestForm: {
    ...requestFormLocale,
  },
};

export default locale;
