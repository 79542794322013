

import { commonRoutes } from "routes/route.common";

export const policyDomainRoutes = {
  base: "policy",
  subdomains: {
    policy: {
      base: "policies",
    },
    // add subdomain route names here
  },
};

// Policy routes
export const basePolicyRoute = (): string => `/${policyDomainRoutes.base}/`;
export const listPolicyRoute = (): string =>
  `/${policyDomainRoutes.base}/${policyDomainRoutes.subdomains.policy.base}`;
export const viewPolicyRoute = (id: string): string =>
  `/${policyDomainRoutes.base}/${policyDomainRoutes.subdomains.policy.base}/${id}`;
export const createPolicyRoute = (): string =>
  `/${policyDomainRoutes.base}/${policyDomainRoutes.subdomains.policy.base}/${commonRoutes.create}`;
export const updatePolicyRoute = (id: string): string =>
  `/${policyDomainRoutes.base}/${policyDomainRoutes.subdomains.policy.base}/${id}/${commonRoutes.update}`;
export const updatePolicyWithVersionRoute = (id: string): string =>
  `/${policyDomainRoutes.base}/${policyDomainRoutes.subdomains.policy.base}/${id}/${commonRoutes.updateWVersion}`;

// add subdomain routes here
