import {
  ISubscriptionFormsInput,
  ISubscriptionFormsRequest,
  ISubscriptionFormsResponse,
  ISubscriptionFormsResult,
  subscriptionFormQuery,
  subscriptionFormsQuery,
  ISubscriptionFormInput,
  ISubscriptionFormRequest,
  ISubscriptionFormResponse,
  ISubscriptionFormResult,
  deleteSubscriptionFormQuery,
  IDeleteSubscriptionFormInput,
  IDeleteSubscriptionFormRequest,
  IDeleteSubscriptionFormResponse,
  IDeleteSubscriptionFormResult,
} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { subscriptionFormTags } from "./subscriptionForm.tags";
import { IGraphqlVariables } from "corede-common";

export const subscriptionFormApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    subscriptionForms: builder.query<
      ISubscriptionFormsResult,
      IGraphqlVariables<ISubscriptionFormsInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ISubscriptionFormsRequest,
        ISubscriptionFormsResponse,
        ISubscriptionFormsResult,
        ISubscriptionFormsInput
      >({
        query: subscriptionFormsQuery,
        providesTags: [subscriptionFormTags.subscriptionForms],
      })
    ),

    subscriptionForm: builder.query<
      ISubscriptionFormResult,
      IGraphqlVariables<ISubscriptionFormInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ISubscriptionFormRequest,
        ISubscriptionFormResponse,
        ISubscriptionFormResult,
        ISubscriptionFormInput
      >({
        query: subscriptionFormQuery,
        providesTags: [subscriptionFormTags.subscriptionForm],
      })
    ),

    // mutation

    deleteSubscriptionForm: builder.mutation<
      IDeleteSubscriptionFormResult,
      IGraphqlVariables<IDeleteSubscriptionFormInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IDeleteSubscriptionFormRequest,
        IDeleteSubscriptionFormResponse,
        IDeleteSubscriptionFormResult,
        IDeleteSubscriptionFormInput
      >({
        query: deleteSubscriptionFormQuery,
        invalidatesTags: [
          subscriptionFormTags.subscriptionForms,
          subscriptionFormTags.subscriptionForm,
        ],
      })
    ),
  }),
});

export const {
  useSubscriptionFormsQuery,
  useLazySubscriptionFormsQuery,

  useSubscriptionFormQuery,
  useLazySubscriptionFormQuery,

  useDeleteSubscriptionFormMutation,
} = subscriptionFormApi;
