import { getTranslatedText } from "localization";
import { ValidationHelper } from "validations/validation.helper";

export const validateUpdatePolicyInput =
  ValidationHelper.BuildObjectValidationSchema({
    input: ValidationHelper.BuildObjectValidationSchema({
      title: ValidationHelper.RequiredString(getTranslatedText("titleIsRequired")),
      content: ValidationHelper.RequiredString(getTranslatedText("contentIsRequired")),
    }),
  });
