import contactFormLocale from "../subdomains/contactForm/locales/tr";
import requestFormLocale from "../subdomains/requestForm/locales/tr";

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////

  contactForm: {
    ...contactFormLocale,
  },

  // TODO: import subdomain locales
  requestForm: {
    ...requestFormLocale,
  },
};

export default locale;
