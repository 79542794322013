

import { PolicyRouter } from "../subdomains/policy/routes/PolicyRouter";
import { PolicyDomainLayout } from "../layouts";
import { policyDomainRoutes } from "./policy.base.route";
import { Route, Routes } from "react-router-dom";

export const PolicyDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<PolicyDomainLayout />}>
        <Route index element={<PolicyRouter />} />
        <Route
          path={`/${policyDomainRoutes.subdomains.policy.base}/*`}
          element={<PolicyRouter />}
        />
        {/*
          // TODO: import subdomains
        */}
      </Route>
    </Routes>
  );
};
