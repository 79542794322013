import {
  Grid,
  Stack,
  Button,
  Skeleton,
  Card,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Autocomplete,
  TableSortLabel
} from "@mui/material";
import { useAppDispatch } from "context";
import { subscriptionActions } from "../../../context";
import { viewSubscriptionRoute } from "../../../routes/subscription.base.route";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSubscriptionsQuery } from "../context/subscription.api";
import { Delete as DeleteIcon, Download, RemoveRedEye, Upload } from "@mui/icons-material";
import { MyTablePagination } from "components";
import { IBaseError, IGraphqlVariables, Language, TNullable } from "corede-common";
import {
  IBaseSubscriptionFormEntity,
  ISubscriptionFormsInput,
  SubscriptionFormStatus,
  SubscriptionFormTopic,
  SubscriptionFormUserType,
} from "corede-common-cocrm";
import { getCurrentLanguage } from "localization";
import { useNavigate } from "react-router-dom";
import Delete from "./Delete";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import moment from "moment";
import { useUsersQuery } from "apps/auth/context";
import dayjs from "dayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { getAccessToken } from "utils/getUserInfo";
import axios from "axios";
import { BASE_URL } from "utils";
import { enqueueSnackbar } from "notistack";


const pages = [
  "home",
  "about-us",
  "contact-us",
  "careers",
  "affiliates",
  "crm",
  "lead",
  "community",
  "developers",
  "blog",
  "faq",
  "security",
  "sitemap",
  "help-center",
  "terms-and-conditions",
  "privacy-policy",
  "refund-policy",
];


const List = () => {
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();

  // useStates
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 0,
  });
  const [selectedItemForDelete, setSelectedItemForDelete] =
    useState<TNullable<IBaseSubscriptionFormEntity>>(null);
  const [variables, setVariables] = useState<
    IGraphqlVariables<ISubscriptionFormsInput>
  >({
    input: {
      filter: {
        email: undefined,
        userTypes: undefined,
        statuses: undefined,
        languages: undefined,
        userIds: undefined,
        pages: undefined,
        subscribedTopics: undefined,
        updatedAtDateFilter: undefined,
      },
      pagination: {
        pageSize: pagination.pageSize,
        page: pagination.page + 1,
        sort: undefined
      },
    },
  });

  // queries
  const {
    data: subscriptionsData,
    isLoading: subscriptionsIsLoading,
    error: subscriptionsError,
  } = useSubscriptionsQuery(variables);

  const { data: usersData, isLoading: usersIsLoading, error: usersError } = useUsersQuery({})

  // mutations

  // constants

  // action handlers

  const handleDelete = (item: IBaseSubscriptionFormEntity) => {
    setSelectedItemForDelete(item);
  };

  const handleView = (id: string) => {
    navigate(viewSubscriptionRoute(id));
  };

  const handleSortRequest = (sortName: string) => {

    sortName === "index" ?
      setVariables({
        ...variables,
        input: {
          ...variables.input,
          pagination: {
            ...variables.input?.pagination,
            sort: undefined
          }
        }
      })
      :
      setVariables({
        ...variables,
        input: {
          ...variables.input,
          pagination: {
            ...variables.input?.pagination,
            sort: {
              [sortName]: variables.input?.pagination?.sort?.[sortName] === 1 ? -1 : 1
            }
          }
        }
      })
  };

  const handleDownloadExampleForm = () => {

    let data = JSON.stringify({
      "filter": {}
    });

    let config = {
      method: 'post',
      url: `${BASE_URL}/subscriptionForms/import/example`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`,
      },
      data: data,
      responseType: 'blob'
    };

    axios.request(config as any)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `subscription-form-example-import.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        enqueueSnackbar(t("main.subscription.subscription.downloadExampleFormSuccessfully"), { variant: "success" });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(t("main.subscription.subscription.downloadExampleFormError"), { variant: "error" });
      });



  };

  const handleDownloadForm = () => {

    let data = JSON.stringify({
      "filter": variables.input?.filter
    });

    let config = {
      method: 'post',
      url: `${BASE_URL}/subscriptionForms/export`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`,
      },
      data: data,
      responseType: 'blob'
    };

    axios.request(config as any)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `subscription-forms-${moment().format("DD-MM-YYYY")}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        enqueueSnackbar(t("main.subscription.subscription.downloadFormSuccessfully"), { variant: "success" });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(t("main.subscription.subscription.downloadFormError"), { variant: "error" });
      });



  };

  const handleImportForm = (file: File) => {

    let formData = new FormData();
    formData.append("file", file);

    let config = {
      method: 'post',
      url: `${BASE_URL}/subscriptionForms/import`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${getAccessToken()}`,
      },
      data: formData,
    };

    axios.request(config as any)
      .then((response) => {
        console.log(response);
        enqueueSnackbar(t("main.subscription.subscription.importedSuccessfully"), { variant: "success" });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(t("main.subscription.subscription.importedError"), { variant: "error" });
      });

  };


  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      subscriptionsError as IBaseError,
      currentLanguage
    );
  }, [subscriptionsError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(usersError as IBaseError, currentLanguage);
  }, [usersError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    setVariables({
      input: {
        pagination: {
          pageSize: pagination.pageSize,
          page: pagination.page + 1,
        },
      },
    });
  }, [pagination]);

  useEffect(() => {
    dispatch(subscriptionActions.setTitle(t("main.subscription.subscription.subscriptions")));
    dispatch(subscriptionActions.setBackButton(false));
    dispatch(subscriptionActions.setRightButton(null));
  }, [dispatch]);



  return (
    <Grid item xs={12}>
      {/* Filters */}
      <Stack direction={"row"} gap={2} mb={2} alignItems={"center"} justifyContent={"flex-end"}>
        <Button
          component="label"
          variant="outlined"
          color="primary"
          sx={{ height: 44 }}
        >
          <Upload sx={{ mr: 1 }} />
          {t("main.subscription.subscription.importForm")}
          <input
            type="file"
            hidden
            onChange={(e) => {
              handleImportForm(e.target.files![0]);
            }}
          />
        </Button>
        <Button
          onClick={() => handleDownloadExampleForm()}
          variant="outlined"
          color="primary"
          sx={{ height: 44 }}
        >
          <Download sx={{ mr: 1 }} />
          {t("main.subscription.subscription.downloadExampleForm")}
        </Button>
        <Button
          onClick={() => handleDownloadForm()}
          variant="contained"
          color="primary"
          sx={{ height: 44 }}
        >
          <Download sx={{ mr: 1 }} />
          {t("main.subscription.subscription.downloadForm")}
        </Button>
      </Stack>
      <Stack direction={"column"} gap={2} mb={2} border={"1px solid #CDCFD1"} borderRadius={2} p={2}>
        <Stack direction="row" gap={2}>
          <Typography variant="h5">{t("main.subscription.subscription.filters")}</Typography>
          <Stack direction="row" gap={1}>
            {
              Object.values(Language).map((language) => (
                <Chip
                  key={language}
                  label={language}
                  variant={variables.input?.filter?.languages?.[0] === language ? "filled" : "outlined"}
                  onClick={() => {
                    setVariables({
                      ...variables,
                      input: {
                        ...variables.input,
                        filter: {
                          ...(variables.input?.filter || {}),
                          languages: variables.input?.filter?.languages?.[0] === language ? undefined : [language],
                        },
                      },
                    });
                  }}
                />
              ))
            }
          </Stack>
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} gap={2}>
          <TextField
            fullWidth
            label={t("main.subscription.subscription.email")}
            value={variables.input?.filter?.email}
            onChange={(e) => {
              setVariables({
                ...variables,
                input: {
                  ...variables.input,
                  filter: {
                    ...(variables.input?.filter || {}),
                    email: e.target.value,
                  },
                },
              });
            }}
          />

          <Autocomplete
            id="userType"
            fullWidth
            options={Object.values(SubscriptionFormUserType).map((userType) => ({ name: userType, _id: userType }))}
            getOptionLabel={(option) => option._id}
            renderInput={(params) => <TextField {...params} label={t("main.subscription.subscription.userType")} />}
            value={variables.input?.filter?.userTypes?.[0] ? { name: variables.input?.filter?.userTypes?.[0], _id: variables.input?.filter?.userTypes?.[0] } : null}
            onChange={(e, value) => {
              setVariables({
                ...variables,
                input: {
                  ...variables.input,
                  filter: {
                    ...(variables.input?.filter || {}),
                    userTypes: value?._id ? [value?._id] : undefined,
                  },
                },
              });
            }}
          />
          <Autocomplete
            id="statuses"
            fullWidth
            options={Object.values(SubscriptionFormStatus).map((status) => ({ name: status, _id: status }))}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label={t("main.subscription.subscription.statuses")} />}
            value={variables.input?.filter?.statuses?.[0] ? { name: variables.input?.filter?.statuses?.[0], _id: variables.input?.filter?.statuses?.[0] } : null}
            onChange={(e, value) => {
              setVariables({
                ...variables,
                input: {
                  ...variables.input,
                  filter: {
                    ...(variables.input?.filter || {}),
                    statuses: value?._id ? [value?._id] : undefined,
                  },
                },
              });
            }}
          />
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} gap={2}>
          <Autocomplete
            id="user"
            isOptionEqualToValue={(option, value) => option._id === value._id}
            fullWidth
            disabled
            getOptionLabel={(option) => option._id}
            options={usersData?.data  || []}
            loading={usersIsLoading}
            renderInput={(params) => <TextField {...params} label={t("main.subscription.subscription.user")} />}
            value={usersData?.data?.find((user) => user._id === variables.input?.filter?.users?.[0])}
            onChange={(e, value) => {
              setVariables({
                ...variables,
                input: {
                  ...variables.input,
                  filter: {
                    ...(variables.input?.filter || {}),
                    users: value?._id ? [value?._id] : undefined,
                  },
                },
              });
            }}
          />

          <Autocomplete
            id="page"
            isOptionEqualToValue={(option, value) => option === value}
            fullWidth
            getOptionLabel={(option) => option}
            options={pages}
            renderInput={(params) => <TextField {...params} label={t("main.subscription.subscription.page")} />}
            multiple
            value={
              variables.input?.filter?.pages && variables.input.filter.pages.length > 0
                ? variables.input.filter.pages.map((page) => (page))
                : undefined
            }
            onChange={(e, value) => {
              setVariables({
                ...variables,
                input: {
                  ...variables.input,
                  filter: {
                    ...(variables.input?.filter || {}),
                    pages: value?.length > 0 ? value.map((page) => page) : undefined,
                  },
                },
              });
            }}
          />
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} gap={2}>
          <Autocomplete
            id="subscribedTopics"
            fullWidth
            options={Object.values(SubscriptionFormTopic).map((status) => ({ name: status, _id: status }))}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label={t("main.subscription.subscription.subscribedTopics")} />}
            value={variables.input?.filter?.subscribedTopics?.[0] ? { name: variables.input?.filter?.subscribedTopics?.[0], _id: variables.input?.filter?.subscribedTopics?.[0] } : null}
            onChange={(e, value) => {
              setVariables({
                ...variables,
                input: {
                  ...variables.input,
                  filter: {
                    ...(variables.input?.filter || {}),
                    subscribedTopics: value?._id ? [value?._id] : undefined,
                  },
                },
              });
            }}
          />

          <DateRangePicker
            sx={{ width: "100%" }}
            label={t("main.subscription.subscription.updatedAtDateFilter")}
            value={variables.input?.filter?.updatedAtDateFilter ?
              [dayjs(variables.input.filter.updatedAtDateFilter.from),
              dayjs(variables.input.filter.updatedAtDateFilter.to)]
              : undefined}
            onChange={(e) => {
              setVariables({
                ...variables,
                input: {
                  ...variables.input,
                  filter: {
                    ...(variables.input?.filter || {}),
                    updatedAtDateFilter: {
                      from: e?.[0]?.toDate() || undefined,
                      to: e?.[1]?.toDate() || undefined,
                    }
                  },
                },
              });
            }}
          />
        </Stack>
      </Stack>

      <Card sx={{ p: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={variables.input?.pagination?.sort?.index !== undefined}
                    direction={variables.input?.pagination?.sort?.index === 1 ? "asc" : "desc"}
                    onClick={() => handleSortRequest("index")}
                  >#</TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={variables.input?.pagination?.sort?.email !== undefined}
                    direction={variables.input?.pagination?.sort?.email === 1 ? "asc" : "desc"}
                    onClick={() => handleSortRequest("email")}
                  >{t("email")}
                  </TableSortLabel>
                  /
                  <TableSortLabel
                    active={variables.input?.pagination?.sort?.userType !== undefined}
                    direction={variables.input?.pagination?.sort?.userType === 1 ? "asc" : "desc"}
                    onClick={() => handleSortRequest("userType")}
                  >{t("userType")}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={variables.input?.pagination?.sort?.page !== undefined}
                    direction={variables.input?.pagination?.sort?.page === 1 ? "asc" : "desc"}
                    onClick={() => handleSortRequest("page")}
                  >{t("page")}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={variables.input?.pagination?.sort?.blogTopicSubscribed !== undefined}
                    direction={variables.input?.pagination?.sort?.blogTopicSubscribed === 1 ? "asc" : "desc"}
                    onClick={() => handleSortRequest("blogTopicSubscribed")}
                  >{t("blog")}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={variables.input?.pagination?.sort?.newsTopicSubscribed !== undefined}
                    direction={variables.input?.pagination?.sort?.newsTopicSubscribed === 1 ? "asc" : "desc"}
                    onClick={() => handleSortRequest("newsTopicSubscribed")}
                  >{t("news")}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={variables.input?.pagination?.sort?.offerTopicSubscribed !== undefined}
                    direction={variables.input?.pagination?.sort?.offerTopicSubscribed === 1 ? "asc" : "desc"}
                    onClick={() => handleSortRequest("offerTopicSubscribed")}
                  >{t("offer")}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={variables.input?.pagination?.sort?.productTopicSubscribed !== undefined}
                    direction={variables.input?.pagination?.sort?.productTopicSubscribed === 1 ? "asc" : "desc"}
                    onClick={() => handleSortRequest("productTopicSubscribed")}
                  >{t("product")}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={variables.input?.pagination?.sort?.status !== undefined}
                    direction={variables.input?.pagination?.sort?.status === 1 ? "asc" : "desc"}
                    onClick={() => handleSortRequest("status")}
                  >{t("status")}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={variables.input?.pagination?.sort?.createdAt !== undefined}
                    direction={variables.input?.pagination?.sort?.createdAt === 1 ? "asc" : "desc"}
                    onClick={() => handleSortRequest("createdAt")}
                  >{t("createdAt")}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">{t("actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!subscriptionsIsLoading
                ? subscriptionsData?.data?.map((row: any, index: number) => (
                  <TableRow
                    key={row._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{(pagination.page ?? 0) * pagination.pageSize + index + 1}</TableCell>
                    <TableCell>
                      <Typography variant="body2" fontWeight={"bold"}>{row.email}</Typography>
                      <Typography variant="body2">{row.userType}</Typography>
                    </TableCell>
                    <TableCell>{row.page}</TableCell>
                    <TableCell><Chip variant="outlined" label={row.blogTopicSubscribed ? "Yes" : "No"} color={row.blogTopicSubscribed ? "success" : "error"} /></TableCell>
                    <TableCell><Chip variant="outlined" label={row.newsTopicSubscribed ? "Yes" : "No"} color={row.newsTopicSubscribed ? "success" : "error"} /></TableCell>
                    <TableCell><Chip variant="outlined" label={row.offerTopicSubscribed ? "Yes" : "No"} color={row.offerTopicSubscribed ? "success" : "error"} /></TableCell>
                    <TableCell><Chip variant="outlined" label={row.productTopicSubscribed ? "Yes" : "No"} color={row.productTopicSubscribed ? "success" : "error"} /></TableCell>
                    <TableCell><Chip label={row.status} /></TableCell>
                    <TableCell>{moment(row.createdAt).format("DD.MM.YYYY HH:mm")}</TableCell>
                    <TableCell align="right">
                      <Stack direction="row" justifyContent={"flex-end"} gap={1} flexWrap={"wrap"}>
                        <Button
                          onClick={() => handleView(row._id)}
                          variant="outlined"
                          size="small"
                          sx={{ height: 32, minWidth: 10 }}
                        >
                          <RemoveRedEye sx={{ fontSize: "16px" }} />
                        </Button>
                        <Button
                          onClick={() => handleDelete(row)}
                          variant="outlined"
                          size="small"
                          color="error"
                          sx={{ height: 32, minWidth: 10 }}
                        >
                          <DeleteIcon sx={{ fontSize: "16px" }} />
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
                : [1, 2, 3].map((key) => (
                  <TableRow key={key}>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <Stack
        direction="row"
        justifyContent={{ xs: "center", md: "flex-start" }}
        mt={2}
        mb={2}
      >
        <MyTablePagination
          count={subscriptionsData?.count ?? 0}
          pageSize={pagination.pageSize}
          page={pagination.page ?? 0}
          onPageChange={(e, page) => {
            setPagination({
              ...pagination,
              page: page,
            });
          }}
          onRowsPerPageChange={(e) => {
            setPagination({
              page: 0,
              pageSize: parseInt(e.target.value)
            });
          }}
        />
      </Stack>

      <Delete
        open={!!selectedItemForDelete}
        item={selectedItemForDelete!}
        onClose={() => {
          setSelectedItemForDelete(null);
        }}
      />
    </Grid>
  );
};

export default List;
