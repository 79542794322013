import { Route, Routes } from "react-router-dom";

import { Create, List, Update, View , UpdateWithVersion} from "../pages";

import { PolicyLayout } from "../layouts/PolicyLayout";
import { commonRoutes } from "routes/route.common";

export const PolicyRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<PolicyLayout />}>
        <Route index element={<List />} />
        <Route path="/:id" element={<View />} />
        <Route path={`/${commonRoutes.create}`} element={<Create />} />
        <Route path={`/:id/${commonRoutes.update}`} element={<Update />} />
        <Route path={`/:id/${commonRoutes.updateWVersion}`} element={<UpdateWithVersion />} />
      </Route>
    </Routes>
  );
};
