const locale = {
  blog:  "Blog",
  blogs: "Bloglar",
  create: "Oluştur",
  update: "Güncelle",
  delete: "Sil",
  view: "Görüntüle",
  list: "Listele",
  createdSuccessfully: "Başarıyla oluşturuldu.", 
  updatedSuccessfully: "Başarıyla güncellendi.",
  deleteConfirm: "Silmek istediğinizden emin misiniz: ",
  deletedSuccessfully: "Başarıyla silindi.",
  title: "Başlık",
  content: "İçerik",
  description: "Açıklama",
  readingTime: "Okuma Süresi",
  tags: "Etiketler",
  addTags: "Etiket Ekle",
  references: "Referanslar",
  addReferences: "Referans Ekle",
  relatedVideo: "İlgili Video",
  author: "Yazar",
  category: "Kategori",
  targetCategory: "Hedef Kategori",
  language: "Dil",
  filters: "Filters",
  pleaseEnterTags: "Etiket giriniz",
  pleaseEnterReferences: "Referans giriniz",
};

export default locale;
