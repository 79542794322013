import { CircularProgress, Grid, Stack, TextField } from "@mui/material";
import { useContactFormQuery } from "../../contactForm/context/contactForm.api";
import { useAppDispatch } from "context";
import { contactFormActions } from "../../../context";
import { useEffect } from "react";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import { IBaseError } from "corede-common";
import { getCurrentLanguage } from "localization";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { listRequestFormRoute } from "../../../routes/contactForm.base.route";

const View = () => {
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const { id } = useParams();
  if (!id) {
    navigate(listRequestFormRoute());
  }
  // queries
  const {
    data: viewRequestFormData,
    isLoading: viewRequestFormIsLoading,
    error: viewRequestFormError,
  } = useContactFormQuery({
    input: { _id: id! },
  });

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      viewRequestFormError as IBaseError,
      currentLanguage
    );
  }, [viewRequestFormError, currentLanguage]);

  useEffect(() => {
    dispatch(contactFormActions.setTitle(t("main.contactForm.requestForm.view")));
    dispatch(contactFormActions.setBackButton(true));
    dispatch(contactFormActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2} maxWidth={400}>
        {viewRequestFormIsLoading ? (
          <CircularProgress />
        ) : (
          <Stack direction="column" gap={2}>
            {/*
              // TODO: add field views
            */}
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default View;
