import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import {
  useUpdateContactFormMutation,
  useContactFormQuery,
} from "../../contactForm/context/contactForm.api";
import { useFormik } from "formik";
import { useAppDispatch } from "context";
import { contactFormActions } from "../../../context";
import { IUpdateContactFormInput } from "corede-common-cocrm";
import { IBaseError, IGraphqlVariables } from "corede-common";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { t } from "i18next";
import { useParams, useNavigate } from "react-router-dom";
import { validateUpdateRequestFormInput } from "../validations/update.validation";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { listRequestFormRoute } from "../../../routes/contactForm.base.route";

const Update = () => {
  
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const { id } = useParams();
  if (!id) {
    navigate(listRequestFormRoute());
  }

  // queries
  const {
    data: requestFormData,
    isLoading: requestFormIsLoading,
    error: requestFormError,
  } = useContactFormQuery({ input: { _id: id! } });

  // mutations
  const [
    updateRequestForm,
    {
      data: updateRequestFormData,
      isLoading: updateRequestFormIsLoading,
      error: updateRequestFormError,
    },
  ] = useUpdateContactFormMutation();
  
  // constants
  const {
    values,
    handleSubmit,
    handleBlur,
    setFieldValue,
    errors,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      input: {
        // TODO: add field initializations
      },
    },
    enableReinitialize: true,
    validationSchema: validateUpdateRequestFormInput,
    onSubmit: (values: IGraphqlVariables<IUpdateContactFormInput>) => {
      updateRequestForm({
        filter: { _id: id! },
        input: values.input,
      });
    },
  });

  // useEffects.success
  useEffect(() => {
    console.log(requestFormData);
    if (requestFormData) {
      setValues({
        input: {
          // TODO: add field assignments
        },
      });
    }
  }, [requestFormData, setValues]);

  useEffect(() => {
    if (updateRequestFormData) {
      resetForm();
      enqueueSnackbar(t("main.contactForm.requestForm.updatedSuccessfully"), {
        variant: "success",
      });
    }
  }, [updateRequestFormData, resetForm]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      updateRequestFormError as IBaseError,
      currentLanguage
    );
  }, [updateRequestFormError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      requestFormError as IBaseError,
      currentLanguage
    );
  }, [requestFormError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    dispatch(contactFormActions.setTitle(t("main.contactForm.requestForm.update")));
    dispatch(contactFormActions.setBackButton(true));
    dispatch(contactFormActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2} maxWidth={400}>
        {requestFormIsLoading ? (
          <CircularProgress />
        ) : (
          <Stack direction="column" gap={2}>
            {/*
              // TODO: add edit views for update fields
            */}

            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
              disabled={updateRequestFormIsLoading}
            >
              {updateRequestFormIsLoading ? (
                <CircularProgress size="1rem" sx={{ mr: 2 }} />
              ) : (
                ""
              )}
              {t("update")}
            </Button>
          </Stack>
        )}      
      </Stack>
    </Grid>
  );
};

export default Update;
