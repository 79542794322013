import { Grid, Stack, Typography } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "context";

export const DashboardLayout = () => {
  const { title } = useAppSelector((state) => state.dashboard);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          gap={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            sx={{ fontSize: { xs: "32px", md: "42px" }, fontWeight: "bold" }}
          >
            {title}
          </Typography>
        </Stack>

        <Outlet />
      </Grid>
    </Grid>
  );
};
