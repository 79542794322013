const locale = {
  blogCategory: "Blog Kategori",
  blogCategories: "Blog Kategorileri",
  create: "Kategori Oluştur",
  update: "Kategori Düzenle",
  delete: "Kategori Sil",
  view: "Kategori Görüntüle",
  list: "Kategorileri listele",
  createdSuccessfully: "Kategori başarılı şekilde kaydedildi",
  updatedSuccessfully: "Kategori başarılı şekilde güncellendi",
  deleteConfirm: "Silmek istediğinize emin misiniz: ",
  deletedSuccessfully: "Kategori başarılı şekilde silindi",
  name: "Ad",
  icon: "İkon",
  parentCategory: "Üst Kategori",
};

export default locale;
