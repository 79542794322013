const locale = {
  helpCenter: "Helpcenter",
  helpCenters: "Helpcenters",
  create: "Create Helpcenter",
  update: "Update Helpcenter",
  delete: "Delete Helpcenter",
  view: "View Helpcenter",
  list: "List Helpcenters",
  createdSuccessfully: "Helpcenter created successfully",
  updatedSuccessfully: "Helpcenter updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Helpcenter deleted successfully",
};

export default locale;
