import { Box, Stack, Avatar, Typography } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { compressImageAndGetUrls } from "utils/imageUpload";
import UploadImage from "assets/icons/UploadImage.png"
import { useBlogImageCreateMutation } from "apps/main/domains/blog/subdomains/blog";





export const UploadBlogImage = ({ setImageFile }: any) => {


    const [profileImageFile, setProfileImageFile] = useState<File | null>(null);
    const [userProfileImageCreate, { data: userProfileImageCreateData, isLoading: userProfileImageCreateLoading, error: userProfileImageCreateError }] = useBlogImageCreateMutation();



    const handleProfileImageChange = async (e: any) => {
        const file = e.target.files[0];

        if (!file) {
            return;
        }

        setProfileImageFile(file);

        const compressedImages = await compressImageAndGetUrls({
            file: file,
            quality: 0.8,
            maxWidth: 1200,
            thumbnailQuality: 0.5,
            thumbnailMaxWidth: 500,
        });

        if (compressedImages) {
            const { compressedProfileImageFile, compressedProfileImageThumbnail } = compressedImages;

            await userProfileImageCreate({
                input: {
                    tags: [""],
                    size: (compressedProfileImageFile as any)?.size * 0.001,
                    mimeType: (compressedProfileImageFile as any)?.type

                }
            })
                .then(async (res) => {
                    console.log("then", res)
                    try {
                        const config = {
                            method: 'PUT',
                            maxBodyLength: Infinity,
                            url: res?.data?.presignedUrl?.uploadPresignedUrl ?? "",
                            headers: {
                                'x-amz-acl': 'public-read',
                                'Content-Type': (compressedProfileImageFile as any)?.type
                            },
                            data: compressedProfileImageFile
                        };
                        const configThumbnail = {
                            method: 'PUT',
                            maxBodyLength: Infinity,
                            url: res?.data?.presignedUrl?.uploadThumbnailPresignedUrl ?? "",
                            headers: {
                                'x-amz-acl': 'public-read',
                                'Content-Type': (compressedProfileImageThumbnail as any)?.type
                            },
                            data: compressedProfileImageThumbnail
                        }

                        const response = await axios(config)
                        const responseThumbnail = await axios(configThumbnail)

                        if (response.status === 200 && responseThumbnail.status === 200) {
                            setImageFile(res?.data?.fileMetadata);
                        }

                    } catch (error) {
                        return error
                    }

                })
        }
    }


    return <Box>
        <Box
            onClick={() => document.getElementById('profile-image-input')?.click()}
            sx={{ width: 'calc(100% - 20px)', border: '1px dashed', borderColor: "primary.light", borderRadius: '16px', p: 1, flexGrow: 1, cursor: 'pointer' }}>
            <Stack direction="column" justifyContent="center" alignItems="center" gap={1} sx={{ width: '100%', height: '100%' }}>
                <Avatar
                    sx={{
                        width: profileImageFile ? "60px" : "40px",
                        height: profileImageFile ? "60px" : "40px",
                        padding: profileImageFile ? "0px" : "10px",
                        backgroundColor: "background.default"
                    }}
                    src={profileImageFile ? URL.createObjectURL(profileImageFile) : UploadImage}
                />
                <Typography variant="body2"> {"Upload a picture"}</Typography>
            </Stack>
        </Box>
        <input
            id="profile-image-input"
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={(e) => handleProfileImageChange(e)}
        />
    </Box>
}


