const locale = {
  author: "Author",
  authors: "Authors",
  create: "Create Author",
  update: "Update Author",
  delete: "Delete Author",
  view: "View Author",
  list: "List Authors",
  createdSuccessfully: "Author created successfully",
  updatedSuccessfully: "Author updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Author deleted successfully",
  name: "Name",
  bio: "Bio",
};

export default locale;
