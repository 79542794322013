import paymentProductLocale from "../subdomains/paymentProduct/locales/en";

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////

  paymentProduct: {
    ...paymentProductLocale,
  },
};

export default locale;
