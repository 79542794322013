


import { IMenuItem } from "interfaces/menu-item.interface";
import { getTranslatedText } from "localization";
import {
  basePolicyRoute,
} from "../routes/policy.base.route";

export const policyDomainMenuItem: IMenuItem = {
  title: getTranslatedText("policy"),
  path: basePolicyRoute(),
  iconName: "ic:outline-policy",
};
