import { useState } from "react";
import { paymentProductDomainMenuItem } from "../domains/paymentProduct/menu/items";
import { notificationDomainMenuItem } from "../domains/notification/menu/items";
import { helpCenterDomainMenuItem } from "../domains/helpCenter/menu/items";
import { subscriptionDomainMenuItem } from "../domains/subscription/menu/items";
import { contactFormDomainMenuItem } from "../domains/contactForm/menu/items";
import { webinarDomainMenuItem } from "../domains/webinar/menu/items";
import { policyDomainMenuItem } from "../domains/policy/menu/items";
import { formDomainMenuItem } from "../domains/form/menu/items";
import { faqDomainMenuItem } from "../domains/faq/menu/items";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Container,
  Drawer,
  IconButton,
  Stack,
  Toolbar,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "context";
import NavDefault from "components/navigations/NavDefault";
import { useLocales } from "minimal/locales";
import { removeUser } from "apps/auth/context";
import { Menu as MenuIcon } from "@mui/icons-material";
import Logo from "assets/images/logo.png";
import { Outlet } from "react-router";
import { dashboardDomainRoutes } from "../domains/dashboard/routes/dashboard.base.route";
import { blogDomainMenuItem } from "../domains/blog/menu/items";
import { dashboardDomainMenuItem } from "../domains/dashboard/menu/items";

const MainLayout = () => {
  const { t } = useLocales();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const { type } = useAppSelector((state) => state.auth);
  const [mobileDrawer, setMobileDrawer] = useState(false);
  const [isNavbarMini, setIsNavbarMini] = useState(false);

  const toggleNavbarSize = () => {
    setIsNavbarMini(!isNavbarMini);
  };

  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path = "/") => {
    navigate(path);
    handleClose();
    setAnchorEl(null);
  };

  const handleAccount = () => {
    type === "admin" && handleNavigation(dashboardDomainRoutes.base);
  };

  const handleLogout = () => {
    dispatch(removeUser());
    document.location.reload();
  };

  const handleSettings = () => {
    handleNavigation("/settings");
  };

  const renderMainMenu = () => {
    switch (type) {
      case "cocrm":
        return [
          {
            items: [
              dashboardDomainMenuItem,
              blogDomainMenuItem,
              contactFormDomainMenuItem,
              subscriptionDomainMenuItem,
              faqDomainMenuItem,
              policyDomainMenuItem,
              webinarDomainMenuItem,
              helpCenterDomainMenuItem,
              notificationDomainMenuItem,
              paymentProductDomainMenuItem
            ],
          },
        ];

      default:
        return null;
    }
  };

  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      sx={{
        height: "100%",
      }}
    >
      {/* ******************   menü ********************** */}
      <Box sx={{ width: { xs: "100%", md: "400px" } }}>
        {/* ******************   desktop navbar ********************** */}
        <Box
          sx={{
            height: "100%",
            position: "fixed",
            display: { xs: "none", md: "flex" },
          }}
        >
          <NavDefault
            open={open}
            anchorEl={anchorEl}
            navData={renderMainMenu()}
            handleClose={handleClose}
            handleClick={handleClick}
            handleLogout={handleLogout}
            handleAccount={handleAccount}
            handleSettings={handleSettings}
            isNavbarMini={isNavbarMini}
            toggleNavbarSize={toggleNavbarSize}
          />
        </Box>

        {/* ******************   mobile navbar ********************** */}
        <AppBar
          position="relative"
          sx={{
            display: { xs: "block", md: "none" },
            boxShadow: "1px 1px 5px 0px #ccc",
            py: 1,
            backgroundColor: "#FFF",
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              zIndex: 1,
            }}
          >
            <IconButton
              onClick={() => setMobileDrawer(true)}
              size="large"
              edge="start"
              color="default"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>

            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                zIndex: -3,
              }}
            >
              <img width="150px" src={Logo} alt="logo" style={{ zIndex: 2 }} />
            </Stack>

            {/* <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                            <NotificationDrawer />
                        </Box> */}
          </Toolbar>
        </AppBar>

        <Drawer
          anchor="left"
          open={mobileDrawer}
          onClose={() => setMobileDrawer(false)}
        >
          <NavDefault
            open={open}
            anchorEl={anchorEl}
            navData={renderMainMenu()}
            handleClose={handleClose}
            handleClick={handleClick}
            handleLogout={handleLogout}
            handleAccount={handleAccount}
            handleSettings={handleSettings}
          />
        </Drawer>
      </Box>

      {/* ******************   main sections ********************** */}
      <Box sx={{ overflow: "auto", width: "100%", minHeight: "100vh" }}>
        <Container
          sx={{
            p: 3,
            ml: 0,
            py: 8,
            pt: { xs: 2, sm: 10 },
            position: "relative",
          }}
        >
          <Outlet />
          <Box
            sx={{
              display: { xs: "block", md: "none" },
              position: "absolute",
              bgcolor: "primary.main",
              bottom: 0,
              left: 0,
              right: 0,
              textAlign: "center",
              height: 10,
              width: "100%",
            }}
          />
        </Container>
      </Box>
    </Stack>
  );
};

export default MainLayout;
