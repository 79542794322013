import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Autocomplete,
} from "@mui/material";
import {
  useCreateFaqMutation,
} from "../context/faq.api";
import { useFormik } from "formik";
import { useAppDispatch } from "context";
import { faqActions } from "../../../context";
import { ICreateFaqInput } from "corede-common-cocrm";
import { IBaseError, IGraphqlVariables, Language } from "corede-common";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { validateCreateFaqInput } from "../validations/create.validation";
import { t } from "i18next";
import { RequestTransformerHelper } from "validations/request.transformer.helper";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { useFaqCategoriesQuery } from "../../faqCategory";

const Create = () => {
  // general
  const dispatch = useAppDispatch();
  const currentLanguage = getCurrentLanguage();


  // queries

  const {
    data: faqCategoriesData,
    isLoading: faqCategoriesIsLoading,
    error: faqCategoriesError,
  } = useFaqCategoriesQuery({});

  // mutations
  const [
    createFaq,
    {
      data: createFaqData,
      isLoading: createFaqIsLoading,
      error: createFaqError,
    },
  ] = useCreateFaqMutation();

  // constants
  const { values, handleSubmit, handleBlur, setFieldValue, errors, resetForm } =
    useFormik({
      initialValues: {
        input: {
          question: "",
          answer: "",
          readingTime: "",
          categoryId: "",
          language: currentLanguage,
        },
      },
      enableReinitialize: true,
      validationSchema: validateCreateFaqInput,
      onSubmit: (values: IGraphqlVariables<ICreateFaqInput>) => {
        const transformedValues =
          RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
            input: values.input,
          });
        createFaq({
          ...values,
          input: transformedValues,
        });
      },
    });

  // useEffects.success
  useEffect(() => {
    if (createFaqData) {
      console.log(createFaqData, "data");
      resetForm();
      enqueueSnackbar(t("main.faq.faq.createdSuccessfully"), {
        variant: "success",
      });
    }
  }, [createFaqData, resetForm]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      createFaqError as IBaseError,
      currentLanguage
    );
  }, [createFaqError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      faqCategoriesError as IBaseError,
      currentLanguage
    );
  }, [faqCategoriesError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    dispatch(faqActions.setTitle(t("main.faq.faq.create")));
    dispatch(faqActions.setBackButton(true));
    dispatch(faqActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2} maxWidth={400}>
        <TextField
          fullWidth
          name="input.question"
          multiline
          rows={2}
          label={t("main.faq.faq.question")}
          value={(values as any).input.question}
          onChange={(e) => setFieldValue("input.question", e.target.value)}
          onBlur={() => handleBlur("input.question")}
          error={!!(errors as any).input?.question}
          helperText={(errors as any).input?.question}
        />
        <TextField
          fullWidth
          multiline
          rows={4}
          name="input.answer"
          label={t("main.faq.faq.answer")}
          value={(values as any).input.answer}
          onChange={(e) => setFieldValue("input.answer", e.target.value)}
          onBlur={() => handleBlur("input.answer")}
          error={!!(errors as any).input?.answer}
          helperText={(errors as any).input?.answer}
        />
        <TextField
          fullWidth
          name="input.readingTime"
          label={t("main.faq.faq.readingTime")}
          value={(values as any).input.readingTime}
          onChange={(e) => setFieldValue("input.readingTime", e.target.value)}
          onBlur={() => handleBlur("input.readingTime")}
          error={!!(errors as any).input?.readingTime}
          helperText={(errors as any).input?.readingTime}
        />
        <Autocomplete
          id="categoryId"
          isOptionEqualToValue={(option, value) => option._id === value._id}
          getOptionLabel={(option) => option.name}
          options={faqCategoriesData?.data || []}
          loading={faqCategoriesIsLoading}
          renderInput={(params) => <TextField {...params} label={t("main.faq.faq.category")} />}
          value={faqCategoriesData?.data?.find((category) => category._id === (values as any).input.categoryId) || null}
          onChange={(e, value) => {
            setFieldValue("input.categoryId", value?._id);
          }}
          onBlur={() => handleBlur("input.categoryId")}
        />
        <Autocomplete
          id="language"
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => option}
          options={Object.values(Language)}
          renderInput={(params) => <TextField {...params} label={t("main.faq.faq.language")} />}
          value={(values as any).input.language}
          onChange={(e, value) => {
            setFieldValue("input.language", value);
          }}
          onBlur={() => handleBlur("input.language")}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
          disabled={createFaqIsLoading || !(values as any).input.question || !(values as any).input.answer  || !(values as any).input.categoryId  || !(values as any).input.language}
        >
          {createFaqIsLoading ? (
            <CircularProgress size="1rem" sx={{ mr: 2 }} />
          ) : (
            ""
          )}
          Create
        </Button>
      </Stack>
    </Grid>
  );
};

export default Create;
