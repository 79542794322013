const locale = {
  faq: "SSS",
  faqs: "SSS'ler",
  create: "SSS Oluştur",
  update: "SSS Güncelle",
  delete: "SSS Sil",
  view: "SSS Görüntüle",
  list: "SSS Listesi",
  createdSuccessfully: "SSS başarıyla oluşturuldu",
  updatedSuccessfully: "SSS başarıyla güncellendi",
  deleteConfirm: "Silmek istediğinizden emin misiniz: ",
  deletedSuccessfully: "SSS başarıyla silindi",
  category: "Kategori",
  targetCategory: "Hedef Kategori",
  language: "Dil",
  pleaseEnterTags: "Lütfen etiketleri girin",
  title: "Başlık",
  content: "İçerik",
  description: "Açıklama",
  tags: "Etiketler",
  question: "Soru",
  answer: "Cevap",
  readingTime: "Okuma Süresi",
  filters: "Filtreler",
};

export default locale;
