const locale = {
  webinar: "Webinar",
  webinars: "Webinars",
  create: "Create Webinar",
  update: "Update Webinar",
  delete: "Delete Webinar",
  view: "View Webinar",
  list: "List Webinars",
  createdSuccessfully: "Webinar created successfully",
  updatedSuccessfully: "Webinar updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Webinar deleted successfully",
  title: "Title",
  content: "Content",
  description: "Description",
  language: "Language",
  type: "Type",
  quota: "Quota",
  startDate: "Start Date",
  lastApplicationDate: "Last Application Date",
  duration: "Duration (min)",
  participationLink: "Participation Link",
  status: "Status",
  lastParticipationDate: "Last Participation Date",
  statuses: "Statuses",
  updatedAtDate: "Updated At Date",
  filters: "Filters",
  participants: "Participants",
};

export default locale;
