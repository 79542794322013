

import { commonRoutes } from "routes/route.common";

export const notificationDomainRoutes = {
  base: "notification",
  subdomains: {
    notification: {
      base: "notifications",
    },
    webNotification: {
      base: "WebNotification",
    },
    // add subdomain route names here
  },
};

// Notification routes
export const baseNotificationRoute = (): string => `/${notificationDomainRoutes.base}/`;
export const listNotificationRoute = (): string =>
  `/${notificationDomainRoutes.base}/${notificationDomainRoutes.subdomains.notification.base}`;
export const viewNotificationRoute = (id: string): string =>
  `/${notificationDomainRoutes.base}/${notificationDomainRoutes.subdomains.notification.base}/${id}`;
export const createNotificationRoute = (): string =>
  `/${notificationDomainRoutes.base}/${notificationDomainRoutes.subdomains.notification.base}/${commonRoutes.create}`;
export const updateNotificationRoute = (id: string): string =>
  `/${notificationDomainRoutes.base}/${notificationDomainRoutes.subdomains.notification.base}/${id}/${commonRoutes.update}`;

// WebNotification routes
export const baseWebNotificationRoute = (): string => listWebNotificationRoute();
export const listWebNotificationRoute = (): string =>
  `/${notificationDomainRoutes.base}/${notificationDomainRoutes.subdomains.webNotification.base}`;
export const viewWebNotificationRoute = (id: string): string =>
  `/${notificationDomainRoutes.base}/${notificationDomainRoutes.subdomains.webNotification.base}/${id}`;
export const createWebNotificationRoute = (): string =>
  `/${notificationDomainRoutes.base}/${notificationDomainRoutes.subdomains.webNotification.base}/${commonRoutes.create}`;
export const updateWebNotificationRoute = (id: string): string =>
  `/${notificationDomainRoutes.base}/${notificationDomainRoutes.subdomains.webNotification.base}/${id}/${commonRoutes.update}`;

// add subdomain routes here
