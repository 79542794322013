import {
  IWebinarsInput,
  IWebinarsRequest,
  IWebinarsResponse,
  IWebinarsResult,
  webinarQuery,
  webinarsQuery,
  IWebinarInput,
  IWebinarRequest,
  IWebinarResponse,
  IWebinarResult,
  ICreateWebinarInput,
  ICreateWebinarRequest,
  ICreateWebinarResponse,
  ICreateWebinarResult,
  createWebinarQuery,
  IUpdateWebinarInput,
  IUpdateWebinarRequest,
  IUpdateWebinarResponse,
  IUpdateWebinarResult,
  updateWebinarQuery,
  IUpdateWebinarFilterInput,
  deleteWebinarQuery,
  IDeleteWebinarInput,
  IDeleteWebinarRequest,
  IDeleteWebinarResponse,
  IDeleteWebinarResult,
} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { webinarTags } from "./webinar.tags";
import { IGraphqlVariables } from "corede-common";

export const webinarApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    webinars: builder.query<
      IWebinarsResult,
      IGraphqlVariables<IWebinarsInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IWebinarsRequest,
        IWebinarsResponse,
        IWebinarsResult,
        IWebinarsInput
      >({
        query: webinarsQuery,
        providesTags: [webinarTags.webinars],
      })
    ),

    webinar: builder.query<
      IWebinarResult,
      IGraphqlVariables<IWebinarInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IWebinarRequest,
        IWebinarResponse,
        IWebinarResult,
        IWebinarInput
      >({
        query: webinarQuery,
        providesTags: [webinarTags.webinar],
      })
    ),

    // mutation
    createWebinar: builder.mutation<
      ICreateWebinarResult,
      IGraphqlVariables<ICreateWebinarInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICreateWebinarRequest,
        ICreateWebinarResponse,
        ICreateWebinarResult,
        ICreateWebinarInput
      >({
        query: createWebinarQuery,
        invalidatesTags: [webinarTags.webinars],
      })
    ),

    updateWebinar: builder.mutation<
      IUpdateWebinarResult,
      IGraphqlVariables<
        IUpdateWebinarInput,
        IUpdateWebinarFilterInput
      >
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUpdateWebinarRequest,
        IUpdateWebinarResponse,
        IUpdateWebinarResult,
        IUpdateWebinarInput,
        IUpdateWebinarFilterInput
      >({
        query: updateWebinarQuery,
        invalidatesTags: [
          webinarTags.webinars,
          webinarTags.webinar,
        ],
      })
    ),

    deleteWebinar: builder.mutation<
      IDeleteWebinarResult,
      IGraphqlVariables<IDeleteWebinarInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IDeleteWebinarRequest,
        IDeleteWebinarResponse,
        IDeleteWebinarResult,
        IDeleteWebinarInput
      >({
        query: deleteWebinarQuery,
        invalidatesTags: [
          webinarTags.webinars,
          webinarTags.webinar,
        ],
      })
    ),
  }),
});

export const {
  useWebinarsQuery,
  useLazyWebinarsQuery,

  useWebinarQuery,
  useLazyWebinarQuery,

  useCreateWebinarMutation,
  useUpdateWebinarMutation,
  useDeleteWebinarMutation,
} = webinarApi;
