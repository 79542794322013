const locale = {
  contactForm: "Contact Form",
  contactForms: "Contact Forms",
  create: "Create Contact Form",
  update: "Update Contact Form",
  delete: "Delete Contact Form",
  view: "View Contact Form",
  list: "List Contact Forms",
  createdSuccessfully: "Contact Form created successfully",
  updatedSuccessfully: "Contact Form updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Contact Form deleted successfully",
  status: "Status",
  escalatedUser: "Escalated User",
  responsibleUser: "Responsible User",
  title: "Title",
  email: "Email",
  statuses: "Statuses",
  filters: "Filters",
};

export default locale;
