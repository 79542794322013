import { SubscriptionFormRouter } from "../subdomains/subscriptionForm/routes/SubscriptionFormRouter";

import { ContactFormRouter } from "../subdomains/contactForm/routes/ContactFormRouter";
import { FormDomainLayout } from "../layouts";
import { formDomainRoutes } from "./form.base.route";
import { Route, Routes } from "react-router-dom";

export const FormDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<FormDomainLayout />}>
        <Route index element={<ContactFormRouter />} />
        <Route
          path={`/${formDomainRoutes.subdomains.contactForm.base}/*`}
          element={<ContactFormRouter />}
        />
        <Route
          path={`/${formDomainRoutes.subdomains.subscriptionForm.base}/*`}
          element={<SubscriptionFormRouter />}
        />
        {/*
          // TODO: import subdomains
        */}
      </Route>
    </Routes>
  );
};
