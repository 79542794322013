import {
  IPaymentProductListInput,
  IPaymentProductListRequest,
  IPaymentProductListResponse,
  IPaymentProductListResult,
  paymentProductDetailQuery,
  paymentProductListQuery,
  IPaymentProductDetailRequest,
  IPaymentProductDetailResponse,
  IPaymentProductDetailResult,
  IPaymentProductCreateInput,
  IPaymentProductCreateRequest,
  IPaymentProductCreateResponse,
  paymentProductCreateQuery,
  IPaymentProductUpdateInput,
  IPaymentProductUpdateRequest,
  IPaymentProductUpdateResponse,
  paymentProductUpdateQuery,
  paymentProductDeleteQuery,
  IPaymentProductDeleteRequest,
  IPaymentProductDeleteResponse,
} from "corede-common-cocrm";
import { IEntity, IBaseResult } from "corede-common";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { paymentProductTags } from "./paymentProduct.tags";
import { IGraphqlVariables } from "corede-common";
import gql from "graphql-tag";

const PAYMENT_PRODUCT_FIELDS = gql`
  fragment PaymentProductFields on PaymentProductListItemResult {
    _id
    name
  }
`;

const PAYMENT_PRODUCT_FIELDS_DETAIL = gql`
  fragment PaymentProductFieldsDetail on PaymentProductDetailResult {
    _id
    baseCurrency
    createdAt
    description
    hasShipping
    isRefundable
    isUpgradable
    maxCount
    name
    paymentProductStatus
    pricing {
      basePrice
      discountRate
      discountedPrice
      salesPrices {
        applicableCountries
        currency
        exchangeRate
      }
    }
    productType
    quota
    recurring {
      daily {
        createdAt
        entitlements {
          storageLimit
          userLimit
        }
        interval
        packageName
        updatedAt
      }
      level
      mainSubscription
      monthly {
        createdAt
        interval
        entitlements {
          storageLimit
          userLimit
          }
          pricing {
          basePrice
          discountRate
          discountedPrice
          salesPrices {
            applicableCountries
            currency
            exchangeRate
          }
        }
        packageName
        updatedAt
      }
      weekly {
        createdAt
        interval
        packageName
        updatedAt
      }
      yearly {
        createdAt
        interval
        packageName
        entitlements {
          storageLimit
          userLimit
          } 
          pricing {
          basePrice
          discountRate
          discountedPrice
          salesPrices {
            applicableCountries
            currency
            exchangeRate
          }
        }
        updatedAt
      }
    }
    relatedFeatures
    restrictedFeatures
    shippingPrice
    soldOut
    updatedAt
  }
`;

export const paymentProductApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    paymentProducts: builder.query<
      IPaymentProductListResult,
      IGraphqlVariables<IPaymentProductListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IPaymentProductListRequest,
        IPaymentProductListResponse,
        IPaymentProductListResult,
        IPaymentProductListInput
      >({
        query: paymentProductListQuery({
          fragment: PAYMENT_PRODUCT_FIELDS,
          fragmentName: "PaymentProductFields",
        }),
        providesTags: [paymentProductTags.paymentProducts],
      })
    ),

    paymentProduct: builder.query<
      IPaymentProductDetailResult,
      IGraphqlVariables<IEntity>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IPaymentProductDetailRequest,
        IPaymentProductDetailResponse,
        IPaymentProductDetailResult,
        IEntity
      >({
        query: paymentProductDetailQuery({
          fragment: PAYMENT_PRODUCT_FIELDS_DETAIL,
          fragmentName: "PaymentProductFieldsDetail",
        }),
        providesTags: [paymentProductTags.paymentProduct],
      })
    ),

    // mutation
    createPaymentProduct: builder.mutation<
      IEntity,
      IGraphqlVariables<IPaymentProductCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IPaymentProductCreateRequest,
        IPaymentProductCreateResponse,
        IEntity,
        IPaymentProductCreateInput
      >({
        query: paymentProductCreateQuery,
        invalidatesTags: [paymentProductTags.paymentProducts],
      })
    ),

    updatePaymentProduct: builder.mutation<
      IEntity,
      IGraphqlVariables<
        IPaymentProductUpdateInput, IEntity
      >
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IPaymentProductUpdateRequest,
        IPaymentProductUpdateResponse,
        IEntity,
        IPaymentProductUpdateInput,
        IEntity
      >({
        query: paymentProductUpdateQuery,
        invalidatesTags: [
          paymentProductTags.paymentProducts,
          paymentProductTags.paymentProduct,
        ],
      })
    ),

    deletePaymentProduct: builder.mutation<
      IBaseResult,
      IGraphqlVariables<IEntity>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IPaymentProductDeleteRequest,
        IPaymentProductDeleteResponse,
        IBaseResult,
        IEntity
      >({
        query: paymentProductDeleteQuery,
        invalidatesTags: [
          paymentProductTags.paymentProducts,
          paymentProductTags.paymentProduct,
        ],
      })
    ),
  }),
});

export const {
  usePaymentProductsQuery,
  useLazyPaymentProductsQuery,

  usePaymentProductQuery,
  useLazyPaymentProductQuery,

  useCreatePaymentProductMutation,
  useUpdatePaymentProductMutation,
  useDeletePaymentProductMutation,
} = paymentProductApi;
