import { getTranslatedText } from "localization";
import { ValidationHelper } from "validations/validation.helper";

export const validateCreateBlogTargetCategoryInput =
  ValidationHelper.BuildObjectValidationSchema({
    input: ValidationHelper.BuildObjectValidationSchema({
      name: ValidationHelper.RequiredString(
        getTranslatedText("nameIsRequired")
      ),
      icon: ValidationHelper.RequiredString(
        getTranslatedText("iconIsRequired")
      ),
    }),
  });