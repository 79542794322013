import policyLocale from "../subdomains/policy/locales/tr";

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////

  policy: {
    ...policyLocale,
  },

  // TODO: import subdomain locales
};

export default locale;
