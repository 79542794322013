import { createSlice } from "@reduxjs/toolkit";
import { commonInitialState, commonReducers } from "context/slices";

const { actions, reducer } = createSlice({
  name: "contactForm",
  initialState: {
    ...commonInitialState,
  },
  reducers: {
    ...commonReducers,
  },
});

export const contactFormActions = actions;
export const contactFormSlice = reducer;
