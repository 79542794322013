const locale = {
  policy: "Policy",
  policies: "Policies",
  create: "Create Policy",
  update: "Update Policy",
  delete: "Delete Policy",
  view: "View Policy",
  list: "List Policies",
  createdSuccessfully: "Policy created successfully",
  updatedSuccessfully: "Policy updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Policy deleted successfully",
  title: "Title",
  content: "Content",
  domain: "Domain",
  type: "Type",
  target: "Target",
  language: "Language",
  versionCount: "Version Count",
  createdAt: "Created At",
  updatedAt: "Updated At",
  filters: "Filters",
  updateWithVersion: "Update Policy With Version",
  updateWithoutVersion: "Update Policy Without Version",
};

export default locale;
