import {
  IBlogCategoriesInput,
  IBlogCategoriesRequest,
  IBlogCategoriesResponse,
  IBlogCategoriesResult,
  blogCategoryQuery,
  blogCategoriesQuery,
  IBlogCategoryInput,
  IBlogCategoryRequest,
  IBlogCategoryResponse,
  IBlogCategoryResult,
  ICreateBlogCategoryInput,
  ICreateBlogCategoryRequest,
  ICreateBlogCategoryResponse,
  ICreateBlogCategoryResult,
  createBlogCategoryQuery,
  IUpdateBlogCategoryInput,
  IUpdateBlogCategoryRequest,
  IUpdateBlogCategoryResponse,
  IUpdateBlogCategoryResult,
  updateBlogCategoryQuery,
  IUpdateBlogCategoryFilterInput,
  deleteBlogCategoryQuery,
  IDeleteBlogCategoryInput,
  IDeleteBlogCategoryRequest,
  IDeleteBlogCategoryResponse,
  IDeleteBlogCategoryResult,
} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { blogCategoryTags } from "./blogCategory.tags";
import { IGraphqlVariables } from "corede-common";

export const blogCategoryApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    blogCategories: builder.query<
      IBlogCategoriesResult,
      IGraphqlVariables<IBlogCategoriesInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IBlogCategoriesRequest,
        IBlogCategoriesResponse,
        IBlogCategoriesResult,
        IBlogCategoriesInput
      >({
        query: blogCategoriesQuery,
        providesTags: [blogCategoryTags.blogCategories],
      })
    ),

    blogCategory: builder.query<
      IBlogCategoryResult,
      IGraphqlVariables<IBlogCategoryInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IBlogCategoryRequest,
        IBlogCategoryResponse,
        IBlogCategoryResult,
        IBlogCategoryInput
      >({
        query: blogCategoryQuery,
        providesTags: [blogCategoryTags.blogCategory],
      })
    ),

    // mutation
    createBlogCategory: builder.mutation<
      ICreateBlogCategoryResult,
      IGraphqlVariables<ICreateBlogCategoryInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICreateBlogCategoryRequest,
        ICreateBlogCategoryResponse,
        ICreateBlogCategoryResult,
        ICreateBlogCategoryInput
      >({
        query: createBlogCategoryQuery,
        invalidatesTags: [blogCategoryTags.blogCategories],
      })
    ),

    updateBlogCategory: builder.mutation<
      IUpdateBlogCategoryResult,
      IGraphqlVariables<
        IUpdateBlogCategoryInput,
        IUpdateBlogCategoryFilterInput
      >
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUpdateBlogCategoryRequest,
        IUpdateBlogCategoryResponse,
        IUpdateBlogCategoryResult,
        IUpdateBlogCategoryInput,
        IUpdateBlogCategoryFilterInput
      >({
        query: updateBlogCategoryQuery,
        invalidatesTags: [
          blogCategoryTags.blogCategories,
          blogCategoryTags.blogCategory,
        ],
      })
    ),

    deleteBlogCategory: builder.mutation<
      IDeleteBlogCategoryResult,
      IGraphqlVariables<IDeleteBlogCategoryInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IDeleteBlogCategoryRequest,
        IDeleteBlogCategoryResponse,
        IDeleteBlogCategoryResult,
        IDeleteBlogCategoryInput
      >({
        query: deleteBlogCategoryQuery,
        invalidatesTags: [
          blogCategoryTags.blogCategories,
          blogCategoryTags.blogCategory,
        ],
      })
    ),
  }),
});

export const {
  useBlogCategoriesQuery,
  useLazyBlogCategoriesQuery,

  useBlogCategoryQuery,
  useLazyBlogCategoryQuery,

  useCreateBlogCategoryMutation,
  useUpdateBlogCategoryMutation,
  useDeleteBlogCategoryMutation,
} = blogCategoryApi;
