import webinarLocale from "../subdomains/webinar/locales/tr";

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////

  webinar: {
    ...webinarLocale,
  },

  // TODO: import subdomain locales
};

export default locale;
