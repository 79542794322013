const locale = {
  paymentProduct: "Payment Product",
  paymentProducts: "Payment Products",
  create: "Create Payment Product",
  update: "Update Payment Product",
  delete: "Delete Payment Product",
  view: "View Payment Product",
  list: "List Payment Products",
  createdSuccessfully: "Payment Product created successfully",
  updatedSuccessfully: "Payment Product updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Payment Product deleted successfully",
  name: "Name",
  description: "Description",
  currency: "Currency",
  hasShipping: "Has Shipping",
  isRefundable: "Is Refundable",
  maxCount: "Max Count",
  status: "Status",
  type: "Type",
  quota: "Quota",
  soldOut: "Sold Out",
  isUpgradable: "Is Upgradable",
  recurringLevel: "Recurring Level",
  monthly: "Monthly",
  yearly: "Yearly",
  packageName: "Package Name",
  storageLimit: "Storage Limit",
  userLimit: "User Limit",
  basePrice: "Base Price",
  discountedPrice: "Discounted Price",
  discountRate: "Discount Rate",
  
};

export default locale;
