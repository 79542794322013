import { Box, Button, CircularProgress, Popper, Stack } from "@mui/material";
import { t } from "i18next";

type TStatusPopper = {
    open: boolean;
    anchorEl: null | HTMLElement;
    dataList: any;
    row: any;
    handleEdit: (id: string) => void;
    handleSelectStatus: (status: any) => void;
    handleChangeStatus: (row: any) => void;
    selectedStatus: string;
    loading: boolean;
}

export const StatusPopper = (props: TStatusPopper) => {


    return <Popper id={props.row.id} open={props.open} anchorEl={props.anchorEl}>
        <Box sx={{ border: 1, bgcolor: 'background.paper', borderRadius: 2, p: 3 }}>
            <Stack direction={"column"} spacing={1} gap={1}>
                {
                    props.dataList.map((status: any, index: number) => (
                        <Button
                            key={index}
                            onClick={() => {
                                status === "escalated" ? props.handleEdit(props.row.id) : props.handleSelectStatus(status)
                            }}
                            variant="outlined"
                            size="small"
                            disabled={props.row.status === status}
                            sx={{
                                height: 32, minWidth: 10,
                                border: status === props.selectedStatus ? "2px solid #000000" : "1px solid #bbb",
                                backgroundColor: status === props.selectedStatus ? "#ededed" : "transparent",
                            }}
                        >
                            {status}
                        </Button>
                    ))
                }
            </Stack>
            <Button
                onClick={() => props.handleChangeStatus(props.row)}
                variant="contained"
                size="small"
                disabled={props.loading}
                sx={{ height: 48, width: '100%', mt: 4 }}
            >
                {props.loading && <CircularProgress size={"1rem"} sx={{mr:1}} />}
                {t("update")}
            </Button>

        </Box>
    </Popper>
}
