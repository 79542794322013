import subscriptionLocale from "../subdomains/subscription/locales/en";

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////

  subscription: {
    ...subscriptionLocale,
  },
};

export default locale;
