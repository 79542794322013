import { Language } from "corede-common";
import en from "./locales/en";
import tr from "./locales/tr";

export const resources = {
  [Language.en]: {
    translation: en,
  },
  [Language.tr]: {
    translation: tr,
  },
};
