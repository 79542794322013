import { getTranslatedText } from "localization";
import { ValidationHelper } from "validations/validation.helper";

export const validateCreateWebinarInput =
  ValidationHelper.BuildObjectValidationSchema({
    input: ValidationHelper.BuildObjectValidationSchema({
      status: ValidationHelper.NotRequiredString(),
      title: ValidationHelper.RequiredString(getTranslatedText("titleIsRequired"))
        .min(25, getTranslatedText("titleIsTooShort25")).max(50, getTranslatedText("titleIsTooLong50")),
      description: ValidationHelper.RequiredString(getTranslatedText("descriptionIsRequired"))
        .min(400, getTranslatedText("descriptionIsTooShort400")).max(4000, getTranslatedText("descriptionIsTooLong4000")),
      content: ValidationHelper.RequiredString(getTranslatedText("contentIsRequired"))
        .min(50, getTranslatedText("contentIsTooShort50")).max(250, getTranslatedText("contentIsTooLong250")),
      language: ValidationHelper.RequiredString(getTranslatedText("languageIsRequired")),
      type: ValidationHelper.RequiredString(getTranslatedText("typeIsRequired")),
      quota: ValidationHelper.RequiredNumber(getTranslatedText("quotaIsRequired"))
        .positive(getTranslatedText("quotaMustBePositive"))
        .test(
          "is-multiple-of-10",
          getTranslatedText("quotaMustBeMultipleOf10"),
          (value) => (value ?? 0) % 10 === 0
        ),
      lastApplicationDate: ValidationHelper.RequiredDate(getTranslatedText("lastApplicationDateIsRequired")),
      startDate: ValidationHelper.RequiredDate(getTranslatedText("startDateIsRequired")),
      duration: ValidationHelper.RequiredNumber(getTranslatedText("durationIsRequired"))
        .positive(getTranslatedText("durationMustBePositive"))
        .test(
          "is-multiple-of-5",
          getTranslatedText("durationMustBeMultipleOf5"),
          (value) => (value ?? 0) % 5 === 0
        ),
      participationLink: ValidationHelper.RequiredString(getTranslatedText("participationLinkIsRequired")),
    }),
  });