


import { IMenuItem } from "interfaces/menu-item.interface";
import { getTranslatedText } from "localization";
import {
  baseFaqRoute,
  listFaqCategoryRoute,
  listFaqRoute,
} from "../routes/faq.base.route";

export const faqDomainMenuItem: IMenuItem = {
  title: getTranslatedText("faq"),
  path: baseFaqRoute(),
  iconName: "mdi:faq",
  children: [
    {
      title: getTranslatedText("faqs"),
      path: listFaqRoute(),
    },
    {
      title: getTranslatedText("faqCategory"),
      path: listFaqCategoryRoute()
    }
    // TODO: import subdomain menu items
  ],
};
