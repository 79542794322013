import { Box, Card, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { useBlogQuery } from "../context/blog.api";
import { useAppDispatch } from "context";
import { blogActions } from "../../../context";
import { useEffect } from "react";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import { IBaseError } from "corede-common";
import { getCurrentLanguage } from "localization";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { listBlogRoute, updateBlogRoute } from "../../../routes/blog.base.route";
import parse from "html-react-parser";

const View = () => {
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const { id } = useParams();
  if (!id) {
    navigate(listBlogRoute());
  }
  // queries
  const {
    data: viewBlogData,
    isLoading: viewBlogIsLoading,
    error: viewBlogError,
  } = useBlogQuery({
    input: { _id: id! },
  });

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      viewBlogError as IBaseError,
      currentLanguage
    );
  }, [viewBlogError, currentLanguage]);

  useEffect(() => {
    dispatch(blogActions.setTitle(t("main.blog.blog.view")));
    dispatch(blogActions.setBackButton(true));
    dispatch(
      blogActions.setRightButton({
        title: t(`main.blog.blog.update`),
        path: updateBlogRoute(id!),
        icon: null,
      })
    );
  }, [dispatch, id]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2}>
        {viewBlogIsLoading ? (
          <CircularProgress />
        ) : (
          <Card sx={{ p: 4 }}>
            <Stack direction={"column"} width={1} gap={1}>
              <Box width={1} height={"300px"} sx={{ overflow: "hidden" }} borderRadius={2} mb={2}>
                <img src={viewBlogData?.image?.publicUrl}
                  alt={viewBlogData?.title} style={{ width: '100%', objectFit:'cover', borderRadius: 20 }} />
              </Box>
              <Typography variant="h1">{viewBlogData?.title}</Typography>
              <Typography variant="body1">{parse(viewBlogData?.description || "")}</Typography>
              <Typography variant="body2"><b>{t("main.blog.blog.content")}: </b> {viewBlogData?.content}</Typography>
              <Typography variant="body2"><b>{t("main.blog.blog.readingTime")}: </b> {viewBlogData?.readingTime}</Typography>
              <Typography variant="body2"><b>{t("main.blog.blog.tags")}: </b> {viewBlogData?.tags}</Typography>
              <Typography variant="body2"><b>{t("main.blog.blog.references")}: </b> {viewBlogData?.references}</Typography>
              <Typography variant="body2"><b>{t("main.blog.blog.relatedVideo")}: </b> {viewBlogData?.relatedVideo}</Typography>
              <Typography variant="body2"><b>{t("main.blog.blog.author")}: </b> {viewBlogData?.author?.name}</Typography>
              <Typography variant="body2"><b>{t("main.blog.blog.category")}: </b> {viewBlogData?.category?.name}</Typography>
              <Typography variant="body2"><b>{t("main.blog.blog.targetCategory")}: </b> {viewBlogData?.targetCategory?.name}</Typography>
              <Typography variant="body2"><b>{t("main.blog.blog.language")}: </b> {viewBlogData?.language}</Typography>
            </Stack>
          </Card>
        )}
      </Stack>
    </Grid>
  );
};

export default View;
