import { getTranslatedText } from "localization";
import { ValidationHelper } from "validations/validation.helper";

export const validateCreateFaqInput =
  ValidationHelper.BuildObjectValidationSchema({
    input: ValidationHelper.BuildObjectValidationSchema({
      question: ValidationHelper.RequiredString(getTranslatedText("questionIsRequired")),
      answer: ValidationHelper.RequiredString(getTranslatedText("answerIsRequired")),
      readingTime: ValidationHelper.RequiredString(getTranslatedText("readingTimeIsRequired")),
      categoryId: ValidationHelper.RequiredString(getTranslatedText("categoryIsRequired")),
      language: ValidationHelper.RequiredString(getTranslatedText("languageIsRequired")),
    }),
  });