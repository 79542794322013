import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import { t } from "i18next";
import { useEffect } from "react";
import { useDeletePaymentProductMutation } from "../context/paymentProduct.api";

import { IBaseError } from "corede-common";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { IBasePaymentProductEntity } from "corede-common-cocrm";

const Delete = (prop: {
    open: boolean;
    item: IBasePaymentProductEntity;
    onClose: () => void;
}) => {
    // general
    const currentLanguage = getCurrentLanguage();

    // queries

    // mutations
    const [
        deletePaymentProduct,
        {
            data: deletePaymentProductData,
            isLoading: deletePaymentProductIsLoading,
            error: deletePaymentProductError,
        },
    ] = useDeletePaymentProductMutation();

    // constants

    // action handlers
    const handleDelete = (_id: string) => {
        deletePaymentProduct({ input: { _id: _id } });
    };

    // useEffects.success
    useEffect(() => {
        if (deletePaymentProductData) {
            prop?.onClose();
            enqueueSnackbar(t("main.paymentProduct.paymentProduct.deletedSuccessfully"), {
                variant: "success",
            });
        }
    }, [deletePaymentProductData]);

    // useEffects.error
    useEffect(() => {
        DefaultErrorHandlerUseEffect(
            deletePaymentProductError as IBaseError,
            currentLanguage
        );
    }, [deletePaymentProductError, currentLanguage]);

    // useEffects.init

    return (
        <Dialog
            open={prop?.open}
            onClose={prop?.onClose}
            sx={{
                "& .MuiDialog-paper": {
                    padding: 1,
                    borderRadius: 2,
                },
            }}
        >
            <DialogTitle>{t("main.paymentProduct.paymentProduct.delete")}</DialogTitle>
            <DialogContent>
                {t("main.paymentProduct.paymentProduct.deleteConfirm")} {prop?.item?.name}?
            </DialogContent>
            <DialogActions>
                <Button onClick={prop?.onClose} color="primary">
                    {t("cancel")}
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    disabled={deletePaymentProductIsLoading}
                    onClick={() => {
                        handleDelete(prop?.item?._id);
                    }}
                >
                    {t("delete")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Delete;
