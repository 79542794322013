

import { commonRoutes } from "routes/route.common";

export const faqDomainRoutes = {
  base: "faq",
  subdomains: {
    faq: {
      base: "faqs",
    },
		faqCategory: {
      base: "categories",
    },
    // add subdomain route names here
  },
};

// Faq routes
export const baseFaqRoute = (): string => `/${faqDomainRoutes.base}/`;
export const listFaqRoute = (): string =>
  `/${faqDomainRoutes.base}/${faqDomainRoutes.subdomains.faq.base}`;
export const viewFaqRoute = (id: string): string =>
  `/${faqDomainRoutes.base}/${faqDomainRoutes.subdomains.faq.base}/${id}`;
export const createFaqRoute = (): string =>
  `/${faqDomainRoutes.base}/${faqDomainRoutes.subdomains.faq.base}/${commonRoutes.create}`;
export const updateFaqRoute = (id: string): string =>
  `/${faqDomainRoutes.base}/${faqDomainRoutes.subdomains.faq.base}/${id}/${commonRoutes.update}`;

// FaqCategory routes
export const baseFaqCategoryRoute = (): string => `/${faqDomainRoutes.base}/`;
export const listFaqCategoryRoute = (): string =>
  `/${faqDomainRoutes.base}/${faqDomainRoutes.subdomains.faqCategory.base}`;
export const viewFaqCategoryRoute = (id: string): string =>
  `/${faqDomainRoutes.base}/${faqDomainRoutes.subdomains.faqCategory.base}/${id}`;
export const createFaqCategoryRoute = (): string =>
  `/${faqDomainRoutes.base}/${faqDomainRoutes.subdomains.faqCategory.base}/${commonRoutes.create}`;
export const updateFaqCategoryRoute = (id: string): string =>
  `/${faqDomainRoutes.base}/${faqDomainRoutes.subdomains.faqCategory.base}/${id}/${commonRoutes.update}`;

// add subdomain routes here
