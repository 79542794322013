const locale = {
  blogTargetCategory: "Blog Target Category",
  blogTargetCategories: "Blog Target Categories",
  create: "Create Blog Target Category",
  update: "Update Blog Target Category",
  delete: "Delete Blog Target Category",
  view: "View Blog Target Category",
  list: "List Blog Target Categories",
  createdSuccessfully: "Blog Target Category created successfully",
  updatedSuccessfully: "Blog Target Category updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Blog Target Category deleted successfully",
};

export default locale;
