

import { WebinarRouter } from "../subdomains/webinar/routes/WebinarRouter";
import { WebinarDomainLayout } from "../layouts";
import { webinarDomainRoutes } from "./webinar.base.route";
import { Route, Routes } from "react-router-dom";

export const WebinarDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<WebinarDomainLayout />}>
        <Route index element={<WebinarRouter />} />
        <Route
          path={`/${webinarDomainRoutes.subdomains.webinar.base}/*`}
          element={<WebinarRouter />}
        />
        {/*
          // TODO: import subdomains
        */}
      </Route>
    </Routes>
  );
};
