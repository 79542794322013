import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Autocomplete,
} from "@mui/material";
import {
  useHelpCenterCreateMutation,
} from "../context/helpCenter.api";
import { useFormik } from "formik";
import { useAppDispatch } from "context";
import { helpCenterActions } from "../../../context";
import { IHelpCenterCreateInput } from "corede-common-cocrm";
import { IBaseError, IFileMetadata, IGraphqlVariables, Language } from "corede-common";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { validateCreateHelpCenterInput } from "../validations/create.validation";
import { t } from "i18next";
import { RequestTransformerHelper } from "validations/request.transformer.helper";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { useHelpCenterCategoriesQuery } from "../../helpCenterCategory";
import { UploadVideoAttachment } from "components/uploadFile/UploadVideoAttachment";

const Create = () => {
  // general
  const dispatch = useAppDispatch();
  const currentLanguage = getCurrentLanguage();
  const [videoFile, setVideoFile] = useState<IFileMetadata | undefined>(undefined);

  // queries

  const {
    data: helpCenterCategoriesData,
    isLoading: helpCenterCategoriesIsLoading,
    error: helpCenterCategoriesError,
  } = useHelpCenterCategoriesQuery({});

  // mutations
  const [
    createHelpCenter,
    {
      data: createHelpCenterData,
      isLoading: createHelpCenterIsLoading,
      error: createHelpCenterError,
    },
  ] = useHelpCenterCreateMutation();

  // constants
  const { values, handleSubmit, handleBlur, setFieldValue, errors, resetForm } =
    useFormik({
      initialValues: {
        input: {
          question: "",
          answer: "",
          readingTime: "",
          categoryId: "",
          language: currentLanguage,
        },
      },
      enableReinitialize: true,
      validationSchema: validateCreateHelpCenterInput,
      onSubmit: (values: IGraphqlVariables<IHelpCenterCreateInput>) => {
        const transformedValues =
          RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
            input: values.input,
          });
        createHelpCenter({
          ...values,
          input: transformedValues,
        });
      },
    });

  // useEffects.success
  useEffect(() => {
    if (createHelpCenterData) {
      console.log(createHelpCenterData, "data");
      resetForm();
      enqueueSnackbar(t("main.helpCenter.helpCenter.createdSuccessfully"), {
        variant: "success",
      });
    }
  }, [createHelpCenterData, resetForm]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      createHelpCenterError as IBaseError,
      currentLanguage
    );
  }, [createHelpCenterError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      helpCenterCategoriesError as IBaseError,
      currentLanguage
    );
  }, [helpCenterCategoriesError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    dispatch(helpCenterActions.setTitle(t("main.helpCenter.helpCenter.create")));
    dispatch(helpCenterActions.setBackButton(true));
    dispatch(helpCenterActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2} maxWidth={400}>
        <TextField
          fullWidth
          name="input.question"
          multiline
          rows={2}
          label={t("main.helpCenter.helpCenter.question")}
          value={(values as any).input.question}
          onChange={(e) => setFieldValue("input.question", e.target.value)}
          onBlur={() => handleBlur("input.question")}
          error={!!(errors as any).input?.question}
          helperText={(errors as any).input?.question}
        />
        <TextField
          fullWidth
          multiline
          rows={4}
          name="input.answer"
          label={t("main.helpCenter.helpCenter.answer")}
          value={(values as any).input.answer}
          onChange={(e) => setFieldValue("input.answer", e.target.value)}
          onBlur={() => handleBlur("input.answer")}
          error={!!(errors as any).input?.answer}
          helperText={(errors as any).input?.answer}
        />
        <TextField
          fullWidth
          name="input.readingTime"
          label={t("main.helpCenter.helpCenter.readingTime")}
          value={(values as any).input.readingTime}
          onChange={(e) => setFieldValue("input.readingTime", e.target.value)}
          onBlur={() => handleBlur("input.readingTime")}
          error={!!(errors as any).input?.readingTime}
          helperText={(errors as any).input?.readingTime}
        />
        <Autocomplete
          id="categoryId"
          isOptionEqualToValue={(option, value) => option._id === value._id}
          getOptionLabel={(option) => option.name}
          options={helpCenterCategoriesData?.data || []}
          loading={helpCenterCategoriesIsLoading}
          renderInput={(params) => <TextField {...params} label={t("main.helpCenter.helpCenter.category")} />}
          value={helpCenterCategoriesData?.data?.find((category) => category._id === (values as any).input.categoryId) || null}
          onChange={(e, value) => {
            setFieldValue("input.categoryId", value?._id);
          }}
          onBlur={() => handleBlur("input.categoryId")}
        />
        <Autocomplete
          id="language"
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => option}
          options={Object.values(Language)}
          renderInput={(params) => <TextField {...params} label={t("main.helpCenter.helpCenter.language")} />}
          value={(values as any).input.language}
          onChange={(e, value) => {
            setFieldValue("input.language", value);
          }}
          onBlur={() => handleBlur("input.language")}
        />
        <UploadVideoAttachment
          setVideoFile={setVideoFile}
        />
        <video
          src={videoFile ? URL.createObjectURL(videoFile as any) : undefined}
          controls
          style={{ width: "100%" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
          disabled={createHelpCenterIsLoading || !(values as any).input.question || !(values as any).input.answer || !(values as any).input.categoryId || !(values as any).input.language}
        >
          {createHelpCenterIsLoading ? (
            <CircularProgress size="1rem" sx={{ mr: 2 }} />
          ) : (
            ""
          )}
          Create
        </Button>
      </Stack>
    </Grid>
  );
};

export default Create;
