const locale = {
  policy: "Policy",
  policies: "Policies",
  create: "Create Policy",
  update: "Update Policy",
  delete: "Delete Policy",
  view: "View Policy",
  list: "List Policies",
  createdSuccessfully: "Policy created successfully",
  updatedSuccessfully: "Policy updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Policy deleted successfully",
  title: "Başlık",
  content: "İçerik",
  domain: "Alan",
  type: "Tip",
  target: "Hedef",
  language: "Dil",
  versionCount: "Versiyon Sayısı",
  createdAt: "Oluşturulma Tarihi",
  updatedAt: "Güncellenme Tarihi",
  filters: "Filtreler",
  updateWithVersion: "Versiyon ile Güncelleme",
  updateWithoutVersion: "Versiyon olmadan Güncelleme",
};

export default locale;
