import { Card, CircularProgress, Grid, Stack, TextField, Typography } from "@mui/material";
import { usePaymentProductQuery } from "../context/paymentProduct.api";
import { useAppDispatch } from "context";
import { paymentProductActions } from "../../../context";
import { useEffect } from "react";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import { IBaseError } from "corede-common";
import { getCurrentLanguage } from "localization";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { listPaymentProductRoute } from "../../../routes/paymentProduct.base.route";

const View = () => {
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const { id } = useParams();
  if (!id) {
    navigate(listPaymentProductRoute());
  }
  // queries
  const {
    data: viewPaymentProductData,
    isLoading: viewPaymentProductIsLoading,
    error: viewPaymentProductError,
  } = usePaymentProductQuery({
    input: { _id: id! },
  });

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      viewPaymentProductError as IBaseError,
      currentLanguage
    );
  }, [viewPaymentProductError, currentLanguage]);

  useEffect(() => {
    dispatch(paymentProductActions.setTitle(t("main.paymentProduct.paymentProduct.view")));
    dispatch(paymentProductActions.setBackButton(true));
    dispatch(paymentProductActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2}>
        {viewPaymentProductIsLoading ? (
          <CircularProgress />
        ) : (
          <Stack direction="column" gap={2}>
            <Card sx={{ p: 4 }}>
              {/* setValues({
        input: {
          name: paymentProductData.name ?? "",
          description: paymentProductData.description ?? "",
          baseCurrency: paymentProductData.baseCurrency ?? Currency.USD,
          isRefundable: paymentProductData.isRefundable ?? false,
          maxCount: paymentProductData.maxCount ?? 0,
          paymentProductStatus: paymentProductData.paymentProductStatus ?? PaymentProductStatus.active,
          soldOut: paymentProductData.soldOut ?? false,
          isUpgradable: paymentProductData.isUpgradable ?? false,
          recurring: {
            level: paymentProductData.recurring?.level ?? 0,
            monthly: {
              packageName: paymentProductData.recurring?.monthly?.packageName ?? "",
              entitlements: {
                storageLimit: paymentProductData.recurring?.monthly?.entitlements?.storageLimit ?? 0,
                userLimit: paymentProductData.recurring?.monthly?.entitlements?.userLimit ?? 0
              },
              pricing: {
                basePrice: paymentProductData.recurring?.monthly?.pricing?.basePrice ?? 0,
                discountedPrice: paymentProductData.recurring?.monthly?.pricing?.discountedPrice ?? 0,
                discountRate: paymentProductData.recurring?.monthly?.pricing?.discountRate ?? 0,
                salesPrices: paymentProductData.recurring?.monthly?.pricing?.salesPrices ?? [],
              },
              interval: paymentProductData.recurring?.monthly?.interval ?? RecurringInterval.monthly,
              integrations: paymentProductData.recurring?.monthly?.integrations ?? undefined,
            },
            yearly: {
              packageName: paymentProductData.recurring?.yearly?.packageName ?? "",
              entitlements: {
                storageLimit: paymentProductData.recurring?.yearly?.entitlements?.storageLimit ?? 0,
                userLimit: paymentProductData.recurring?.yearly?.entitlements?.userLimit ?? 0
              },
              pricing: {
                basePrice: paymentProductData.recurring?.yearly?.pricing?.basePrice ?? 0,
                discountedPrice: paymentProductData.recurring?.yearly?.pricing?.discountedPrice ?? 0,
                discountRate: paymentProductData.recurring?.yearly?.pricing?.discountRate ?? 0,
                salesPrices: paymentProductData.recurring?.yearly?.pricing?.salesPrices ?? [],
              },
              interval: paymentProductData.recurring?.yearly?.interval ?? RecurringInterval.yearly,
              integrations: paymentProductData.recurring?.yearly?.integrations ?? undefined,
            },
            daily: paymentProductData.recurring?.daily,
            weekly: paymentProductData.recurring?.weekly,
            mainSubscription: MainSubscription.main
          },
          relatedFeatures: paymentProductData.relatedFeatures,
          restrictedFeatures: paymentProductData.restrictedFeatures,
          video: paymentProductData.video,
          image: paymentProductData.image,
          integrations: paymentProductData.integrations,
          pricing: paymentProductData.pricing,
          productType: paymentProductData.productType,
          hasShipping: paymentProductData.hasShipping,
          shippingPrice: paymentProductData.shippingPrice,
          quota: paymentProductData.quota
        },
      }); */}
              <Typography><b>{t("main.paymentProduct.paymentProduct.name")}</b> {viewPaymentProductData?.name}</Typography>
              <Typography><b>{t("main.paymentProduct.paymentProduct.description")}</b> {viewPaymentProductData?.description}</Typography>
              <Typography><b>{t("main.paymentProduct.paymentProduct.currency")}</b> {viewPaymentProductData?.baseCurrency}</Typography>
              <Typography><b>{t("main.paymentProduct.paymentProduct.isRefundable")}</b> {viewPaymentProductData?.isRefundable}</Typography>
              <Typography><b>{t("main.paymentProduct.paymentProduct.maxCount")}</b> {viewPaymentProductData?.maxCount}</Typography>
              <Typography><b>{t("main.paymentProduct.paymentProduct.status")}</b> {viewPaymentProductData?.paymentProductStatus}</Typography>
              <Typography><b>{t("main.paymentProduct.paymentProduct.soldOut")}</b> {viewPaymentProductData?.soldOut}</Typography>
              <Typography><b>{t("main.paymentProduct.paymentProduct.isUpgradable")}</b> {viewPaymentProductData?.isUpgradable}</Typography>
              <Typography><b>{t("main.paymentProduct.paymentProduct.recurringLevel")}</b> {viewPaymentProductData?.recurring?.level}</Typography>
              <Typography sx={{ borderBottom: "1px solid black" }}><b>{t("main.paymentProduct.paymentProduct.monthly")}</b></Typography>
              <Typography><b>{t("main.paymentProduct.paymentProduct.packageName")}</b> {viewPaymentProductData?.recurring?.monthly?.packageName}</Typography>
              <Typography><b>{t("main.paymentProduct.paymentProduct.storageLimit")}</b> {viewPaymentProductData?.recurring?.monthly?.entitlements?.storageLimit}</Typography>
              <Typography><b>{t("main.paymentProduct.paymentProduct.userLimit")}</b> {viewPaymentProductData?.recurring?.monthly?.entitlements?.userLimit}</Typography>
              <Typography><b>{t("main.paymentProduct.paymentProduct.basePrice")}</b> {viewPaymentProductData?.recurring?.monthly?.pricing?.basePrice}</Typography>
              <Typography><b>{t("main.paymentProduct.paymentProduct.discountedPrice")}</b> {viewPaymentProductData?.recurring?.monthly?.pricing?.discountedPrice}</Typography>
              <Typography><b>{t("main.paymentProduct.paymentProduct.discountRate")}</b> {viewPaymentProductData?.recurring?.monthly?.pricing?.discountRate}</Typography>
              <Typography sx={{ borderBottom: "1px solid black" }}><b>{t("main.paymentProduct.paymentProduct.yearly")}</b></Typography>
              <Typography><b>{t("main.paymentProduct.paymentProduct.packageName")}</b> {viewPaymentProductData?.recurring?.yearly?.packageName}</Typography>
              <Typography><b>{t("main.paymentProduct.paymentProduct.storageLimit")}</b> {viewPaymentProductData?.recurring?.yearly?.entitlements?.storageLimit}</Typography>
              <Typography><b>{t("main.paymentProduct.paymentProduct.userLimit")}</b> {viewPaymentProductData?.recurring?.yearly?.entitlements?.userLimit}</Typography>
              <Typography><b>{t("main.paymentProduct.paymentProduct.basePrice")}</b> {viewPaymentProductData?.recurring?.yearly?.pricing?.basePrice}</Typography>
              <Typography><b>{t("main.paymentProduct.paymentProduct.discountedPrice")}</b> {viewPaymentProductData?.recurring?.yearly?.pricing?.discountedPrice}</Typography>
              <Typography><b>{t("main.paymentProduct.paymentProduct.discountRate")}</b> {viewPaymentProductData?.recurring?.yearly?.pricing?.discountRate}</Typography>

            </Card>
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default View;


