import webinarLocale from "../subdomains/webinar/locales/en";

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////

  webinar: {
    ...webinarLocale,
  },
};

export default locale;
