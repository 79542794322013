


import { IMenuItem } from "interfaces/menu-item.interface";
import { getTranslatedText } from "localization";
import {
  baseSubscriptionRoute,
} from "../routes/subscription.base.route";

export const subscriptionDomainMenuItem: IMenuItem = {
  title: getTranslatedText("subscription"),
  path: baseSubscriptionRoute(),
  iconName: "fa6-brands:wpforms",
};
