

import { commonRoutes } from "routes/route.common";

export const subscriptionDomainRoutes = {
  base: "subscription",
  subdomains: {
    subscription: {
      base: "subscriptions",
    },
    // add subdomain route names here
  },
};

// Subscription routes
export const baseSubscriptionRoute = (): string => `/${subscriptionDomainRoutes.base}/`;
export const listSubscriptionRoute = (): string =>
  `/${subscriptionDomainRoutes.base}/${subscriptionDomainRoutes.subdomains.subscription.base}`;
export const viewSubscriptionRoute = (id: string): string =>
  `/${subscriptionDomainRoutes.base}/${subscriptionDomainRoutes.subdomains.subscription.base}/${id}`;
export const createSubscriptionRoute = (): string =>
  `/${subscriptionDomainRoutes.base}/${subscriptionDomainRoutes.subdomains.subscription.base}/${commonRoutes.create}`;
export const updateSubscriptionRoute = (id: string): string =>
  `/${subscriptionDomainRoutes.base}/${subscriptionDomainRoutes.subdomains.subscription.base}/${id}/${commonRoutes.update}`;

// add subdomain routes here
