import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  useUpdatePaymentProductMutation,
  usePaymentProductQuery,
} from "../context/paymentProduct.api";
import { useFormik } from "formik";
import { useAppDispatch } from "context";
import { paymentProductActions } from "../../../context";
import { IPaymentProductUpdateInput, MainSubscription, PaymentProductStatus, PaymentProductType, RecurringInterval } from "corede-common-cocrm";
import { Currency, IBaseError, IGraphqlVariables } from "corede-common";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { t } from "i18next";
import { useParams, useNavigate } from "react-router-dom";
import { validateUpdatePaymentProductInput } from "../validations/update.validation";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { listPaymentProductRoute } from "../../../routes/paymentProduct.base.route";

const Update = () => {

  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const { id } = useParams();
  if (!id) {
    navigate(listPaymentProductRoute());
  }

  // queries
  const {
    data: paymentProductData,
    isLoading: paymentProductIsLoading,
    error: paymentProductError,
  } = usePaymentProductQuery({ input: { _id: id! } });

  // mutations
  const [
    updatePaymentProduct,
    {
      data: updatePaymentProductData,
      isLoading: updatePaymentProductIsLoading,
      error: updatePaymentProductError,
    },
  ] = useUpdatePaymentProductMutation();

  // constants
  const {
    values,
    handleSubmit,
    handleBlur,
    setFieldValue,
    errors,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      input: {
        name: "",
        description: "",
        baseCurrency: Currency.USD,
        isRefundable: false,
        maxCount: 0,
        paymentProductStatus: PaymentProductStatus.active,
        soldOut: false,
        isUpgradable: false,
        recurring: {
          level: 0,
          monthly: {
            packageName: "",
            entitlements: {
              storageLimit: 0,
              userLimit: 0
            },
            pricing: {
              basePrice: 0,
              discountedPrice: 0,
              discountRate: 0,
              salesPrices: [],
            },
            interval: RecurringInterval.monthly,
            integrations: undefined,
          },
          yearly: {
            packageName: "",
            entitlements: {
              storageLimit: 0,
              userLimit: 0
            },
            pricing: {
              basePrice: 0,
              discountedPrice: 0,
              discountRate: 0,
              salesPrices: [],
            },
            interval: RecurringInterval.yearly,
            integrations: undefined,
          },

          daily: undefined,
          weekly: undefined,
          mainSubscription: MainSubscription.main,
        },


        relatedFeatures: [],
        restrictedFeatures: [],
        video: undefined,
        image: undefined,
        integrations: undefined,
        pricing: undefined,
        productType: PaymentProductType.subscription,
        hasShipping: false,
        shippingPrice: undefined,
        quota: -1

      },
    },
    enableReinitialize: true,
    validationSchema: validateUpdatePaymentProductInput,
    onSubmit: (values: IGraphqlVariables<IPaymentProductUpdateInput>) => {
      updatePaymentProduct({
        filter: { _id: id! },
        input: values.input,
      });
    },
  });

  // useEffects.success
  useEffect(() => {
    console.log(paymentProductData);
    if (paymentProductData) {
      setValues({
        input: {
          name: paymentProductData.name ?? "",
          description: paymentProductData.description ?? "",
          baseCurrency: paymentProductData.baseCurrency ?? Currency.USD,
          isRefundable: paymentProductData.isRefundable ?? false,
          maxCount: paymentProductData.maxCount ?? 0,
          paymentProductStatus: paymentProductData.paymentProductStatus ?? PaymentProductStatus.active,
          soldOut: paymentProductData.soldOut ?? false,
          isUpgradable: paymentProductData.isUpgradable ?? false,
          recurring: {
            level: paymentProductData.recurring?.level ?? 0,
            monthly: {
              packageName: paymentProductData.recurring?.monthly?.packageName ?? "",
              entitlements: {
                storageLimit: paymentProductData.recurring?.monthly?.entitlements?.storageLimit ?? 0,
                userLimit: paymentProductData.recurring?.monthly?.entitlements?.userLimit ?? 0
              },
              pricing: {
                basePrice: paymentProductData.recurring?.monthly?.pricing?.basePrice ?? 0,
                discountedPrice: paymentProductData.recurring?.monthly?.pricing?.discountedPrice ?? 0,
                discountRate: paymentProductData.recurring?.monthly?.pricing?.discountRate ?? 0,
                salesPrices: paymentProductData.recurring?.monthly?.pricing?.salesPrices ?? [],
              },
              interval: paymentProductData.recurring?.monthly?.interval ?? RecurringInterval.monthly,
              integrations: paymentProductData.recurring?.monthly?.integrations ?? undefined,
            },
            yearly: {
              packageName: paymentProductData.recurring?.yearly?.packageName ?? "",
              entitlements: {
                storageLimit: paymentProductData.recurring?.yearly?.entitlements?.storageLimit ?? 0,
                userLimit: paymentProductData.recurring?.yearly?.entitlements?.userLimit ?? 0
              },
              pricing: {
                basePrice: paymentProductData.recurring?.yearly?.pricing?.basePrice ?? 0,
                discountedPrice: paymentProductData.recurring?.yearly?.pricing?.discountedPrice ?? 0,
                discountRate: paymentProductData.recurring?.yearly?.pricing?.discountRate ?? 0,
                salesPrices: paymentProductData.recurring?.yearly?.pricing?.salesPrices ?? [],
              },
              interval: paymentProductData.recurring?.yearly?.interval ?? RecurringInterval.yearly,
              integrations: paymentProductData.recurring?.yearly?.integrations ?? undefined,
            },
            daily: paymentProductData.recurring?.daily,
            weekly: paymentProductData.recurring?.weekly,
            mainSubscription: MainSubscription.main
          },
          relatedFeatures: paymentProductData.relatedFeatures,
          restrictedFeatures: paymentProductData.restrictedFeatures,
          video: paymentProductData.video,
          image: paymentProductData.image,
          integrations: paymentProductData.integrations,
          pricing: paymentProductData.pricing,
          productType: paymentProductData.productType,
          hasShipping: paymentProductData.hasShipping,
          shippingPrice: paymentProductData.shippingPrice,
          quota: paymentProductData.quota
        },
      });
    }
  }, [paymentProductData, setValues]);

  useEffect(() => {
    if (updatePaymentProductData) {
      resetForm();
      enqueueSnackbar(t("main.paymentProduct.paymentProduct.updatedSuccessfully"), {
        variant: "success",
      });
    }
  }, [updatePaymentProductData, resetForm]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      updatePaymentProductError as IBaseError,
      currentLanguage
    );
  }, [updatePaymentProductError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      paymentProductError as IBaseError,
      currentLanguage
    );
  }, [paymentProductError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    dispatch(paymentProductActions.setTitle(t("main.paymentProduct.paymentProduct.update")));
    dispatch(paymentProductActions.setBackButton(true));
    dispatch(paymentProductActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2}>
        {paymentProductIsLoading ? (
          <CircularProgress />
        ) : (
          <Stack direction="column" gap={2}>
            <TextField
              fullWidth
              label={t("main.paymentProduct.paymentProduct.name")}
              value={(values as any).input.name}
              onChange={e => setFieldValue("input.name", e.target.value)}
            />

            <TextField
              fullWidth
              multiline
              rows={2}
              label={t("main.paymentProduct.paymentProduct.description")}
              value={(values as any).input.description}
              onChange={e => setFieldValue("input.description", e.target.value)}
            />

            <Autocomplete
              fullWidth
              options={Object.values(Currency)}
              value={(values as any).input.baseCurrency}
              onChange={(event, value) => setFieldValue("input.baseCurrency", value)}
              getOptionLabel={option => option}
              renderInput={params => <TextField {...params} label={t("main.paymentProduct.paymentProduct.currency")} />}
            />

            {/* <FormControlLabel
          control={
            <Switch
              checked={(values as any).input.hasShipping}
              onChange={e => setFieldValue("input.hasShipping", e.target.checked)}
            />
          }
          label={t("main.paymentProduct.paymentProduct.hasShipping")}
        /> */}

            <FormControlLabel
              control={
                <Switch
                  checked={(values as any).input.isRefundable}
                  onChange={e => setFieldValue("input.isRefundable", e.target.checked)}
                />
              }
              label={t("main.paymentProduct.paymentProduct.isRefundable")}
            />

            <TextField
              fullWidth
              label={t("main.paymentProduct.paymentProduct.maxCount")}
              type="number"
              value={(values as any).input.maxCount}
              onChange={e => setFieldValue("input.maxCount", Number(e.target.value))}
            />

            <Autocomplete
              fullWidth
              options={Object.values(PaymentProductStatus)}
              value={(values as any).input.paymentProductStatus}
              onChange={(event, value) => setFieldValue("input.paymentProductStatus", value)}
              getOptionLabel={option => option}
              renderInput={params => <TextField {...params} label={t("main.paymentProduct.paymentProduct.status")} />}
            />

            {/* <Autocomplete
          fullWidth
          options={Object.values(PaymentProductType)}
          value={(values as any).input.productType}
          onChange={(event, value) => setFieldValue("input.productType", value)}
          getOptionLabel={option => option}
          renderInput={params => <TextField {...params} label={t("main.paymentProduct.paymentProduct.type")} />}
        /> */}

            {/* <TextField
          fullWidth
          label={t("main.paymentProduct.paymentProduct.quota")}
          type="number"
          value={(values as any).input.quota}
          onChange={e => setFieldValue("input.quota", Number(e.target.value))}
        /> */}

            <FormControlLabel
              control={
                <Switch
                  checked={(values as any).input.soldOut}
                  onChange={e => setFieldValue("input.soldOut", e.target.checked)}
                />
              }
              label={t("main.paymentProduct.paymentProduct.soldOut")}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={(values as any).input.isUpgradable}
                  onChange={e => setFieldValue("input.isUpgradable", e.target.checked)}
                />
              }
              label={t("main.paymentProduct.paymentProduct.isUpgradable")}
            />

            <Autocomplete
              fullWidth
              options={[1, 2, 3, 4, 5]}
              value={(values as any).input.recurring.level}
              onChange={(event, value) => setFieldValue("input.recurring.level", value)}
              getOptionLabel={option => option}
              renderInput={params => <TextField {...params} label={t("main.paymentProduct.paymentProduct.recurringLevel")} />}
            />

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Stack direction="column" gap={2}>
                  <Typography sx={{ borderBottom: "1px solid #E0E0E0" }}>{t("main.paymentProduct.paymentProduct.monthly")} </Typography>

                  <TextField
                    fullWidth
                    label={t("main.paymentProduct.paymentProduct.packageName")}
                    value={(values as any).input.recurring.monthly.packageName}
                    onChange={e => setFieldValue("input.recurring.monthly.packageName", e.target.value)}
                  />

                  <TextField
                    fullWidth
                    label={t("main.paymentProduct.paymentProduct.storageLimit")}
                    value={(values as any).input.recurring.monthly.entitlements.storageLimit}
                    onChange={e => setFieldValue("input.recurring.monthly.entitlements.storageLimit", Number(e.target.value))}
                  />

                  <TextField
                    fullWidth
                    label={t("main.paymentProduct.paymentProduct.userLimit")}
                    value={(values as any).input.recurring.monthly.entitlements.userLimit}
                    onChange={e => setFieldValue("input.recurring.monthly.entitlements.userLimit", Number(e.target.value))}
                  />

                  <TextField
                    fullWidth
                    label={t("main.paymentProduct.paymentProduct.basePrice")}
                    value={(values as any).input.recurring.monthly?.pricing?.basePrice}
                    onChange={e => setFieldValue("input.recurring.monthly.pricing.basePrice", Number(e.target.value))}
                  />

                  <TextField
                    fullWidth
                    label={t("main.paymentProduct.paymentProduct.discountedPrice")}
                    value={(values as any).input.recurring.monthly?.pricing?.discountedPrice}
                    onChange={e => setFieldValue("input.recurring.monthly.pricing.discountedPrice", Number(e.target.value))}
                  />

                  <TextField
                    fullWidth
                    label={t("main.paymentProduct.paymentProduct.discountRate")}
                    value={(values as any).input.recurring.monthly?.pricing?.discountRate}
                    onChange={e => setFieldValue("input.recurring.monthly.pricing.discountRate", Number(e.target.value))}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} md={6}>
                <Stack direction="column" gap={2}>
                  <Typography sx={{ borderBottom: "1px solid #E0E0E0" }}>{t("main.paymentProduct.paymentProduct.yearly")} </Typography>

                  <TextField
                    fullWidth
                    label={t("main.paymentProduct.paymentProduct.packageName")}
                    value={(values as any).input.recurring.yearly.packageName}
                    onChange={e => setFieldValue("input.recurring.yearly.packageName", e.target.value)}
                  />

                  <TextField
                    fullWidth
                    label={t("main.paymentProduct.paymentProduct.storageLimit")}
                    value={(values as any).input.recurring.yearly.entitlements.storageLimit}
                    onChange={e => setFieldValue("input.recurring.yearly.entitlements.storageLimit", Number(e.target.value))}
                  />

                  <TextField
                    fullWidth
                    label={t("main.paymentProduct.paymentProduct.userLimit")}
                    value={(values as any).input.recurring.yearly.entitlements.userLimit}
                    onChange={e => setFieldValue("input.recurring.yearly.entitlements.userLimit", Number(e.target.value))}
                  />

                  <TextField
                    fullWidth
                    label={t("main.paymentProduct.paymentProduct.basePrice")}
                    value={(values as any).input.recurring.yearly?.pricing?.basePrice}
                    onChange={e => setFieldValue("input.recurring.yearly.pricing.basePrice", Number(e.target.value))}
                  />

                  <TextField
                    fullWidth
                    label={t("main.paymentProduct.paymentProduct.discountedPrice")}
                    value={(values as any).input.recurring.yearly?.pricing?.discountedPrice}
                    onChange={e => setFieldValue("input.recurring.yearly.pricing.discountedPrice", Number(e.target.value))}
                  />

                  <TextField
                    fullWidth
                    label={t("main.paymentProduct.paymentProduct.discountRate")}
                    value={(values as any).input.recurring.yearly?.pricing?.discountRate}
                    onChange={e => setFieldValue("input.recurring.yearly.pricing.discountRate", Number(e.target.value))}
                  />

                </Stack>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
              disabled={updatePaymentProductIsLoading}
            >
              {updatePaymentProductIsLoading ? (
                <CircularProgress size="1rem" sx={{ mr: 2 }} />
              ) : (
                ""
              )}
              {t("update")}
            </Button>
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default Update;
