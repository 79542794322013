import { Button, Card, Chip, CircularProgress, Grid, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Typography } from "@mui/material";
import { useWebinarQuery } from "../context/webinar.api";
import { useAppDispatch } from "context";
import { webinarActions } from "../../../context";
import { useEffect, useState } from "react";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import { IBaseError } from "corede-common";
import { getCurrentLanguage } from "localization";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { listWebinarRoute } from "../../../routes/webinar.base.route";
import moment from "moment";
import parse from "html-react-parser";
import { RemoveRedEye, Edit, DownloadOutlined } from "@mui/icons-material";
import { StatusPopper } from "components";
import { ContactFormStatus } from "corede-common-cocrm";
import pagination from "minimal/theme/overrides/components/pagination";
import { BASE_URL } from "utils";
import { getAccessToken } from "utils/getUserInfo";
import axios from "axios";

const View = () => {
  // general
  const dispatch = useAppDispatch();
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 0,
  });
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const { id } = useParams();
  if (!id) {
    navigate(listWebinarRoute());
  }
  // queries
  const {
    data: viewWebinarData,
    isLoading: viewWebinarIsLoading,
    error: viewWebinarError,
  } = useWebinarQuery({
    input: { _id: id! },
  });

  const handleDownload = () => {

    let data = JSON.stringify({
      "_id": id
    });

    let config = {
      method: 'post',
      url: `${BASE_URL}/webinar/participants/export`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`,
      },
      data: data,
      responseType: 'blob'
    };

    axios.request(config as any)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `webinar_participants_${id}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.log(error);
      });



  };


  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      viewWebinarError as IBaseError,
      currentLanguage
    );
  }, [viewWebinarError, currentLanguage]);

  useEffect(() => {
    dispatch(webinarActions.setTitle(t("main.webinar.webinar.view")));
    dispatch(webinarActions.setBackButton(true));
    dispatch(webinarActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2}>
        {viewWebinarIsLoading ? (
          <CircularProgress />
        ) : (
          <Stack direction="column" gap={2}>
            <Card sx={{ p: 4 }}>
              <Stack direction="column" gap={2}>
                <Typography>
                  <strong>{t("main.webinar.webinar.title")}:</strong> {viewWebinarData?.title}
                </Typography>
                <Typography>
                  <strong>{t("main.webinar.webinar.content")}:</strong> {viewWebinarData?.content}
                </Typography>
                <Typography>
                  <strong>{t("main.webinar.webinar.description")}:</strong> {parse(viewWebinarData?.description || "")}
                </Typography>
                <Typography>
                  <strong>{t("main.webinar.webinar.language")}:</strong> {viewWebinarData?.language}
                </Typography>
                <Typography>
                  <strong>{t("main.webinar.webinar.type")}:</strong> {viewWebinarData?.type}
                </Typography>
                <Typography>
                  <strong>{t("main.webinar.webinar.quota")}:</strong> {viewWebinarData?.quota}
                </Typography>
                <Typography>
                  <strong>{t("main.webinar.webinar.startDate")}:</strong> {moment(viewWebinarData?.startDate).format("DD/MM/YYYY HH:mm")}
                </Typography>
                <Typography>
                  <strong>{t("main.webinar.webinar.lastApplicationDate")}:</strong> {moment(viewWebinarData?.lastApplicationDate).format("DD/MM/YYYY HH:mm")}
                </Typography>
                <Typography>
                  <strong>{t("main.webinar.webinar.duration")}:</strong> {viewWebinarData?.duration}
                </Typography>
                <Typography>
                  <strong>{t("main.webinar.webinar.participationLink")}:</strong> {viewWebinarData?.participationLink}
                </Typography>
                <Typography>
                  <strong>{t("main.webinar.webinar.status")}:</strong> {viewWebinarData?.status}
                </Typography>

              </Stack>
            </Card>
          </Stack>
        )}
      </Stack>
      <Card sx={{ p: 2, mt: 2, mb: 2 }}>
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
          <Typography variant="h5">{t("main.webinar.webinar.participants")}</Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleDownload()}
          >
            <DownloadOutlined sx={{ mr: 1 }} />
            {t("download")}
          </Button>
        </Stack>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#
                </TableCell>
                <TableCell>{t("fullName")}</TableCell>
                <TableCell>{t("email")}   </TableCell>
                <TableCell>{t("country")}                </TableCell>
                <TableCell>{t("city")}</TableCell>
                <TableCell>{t("company")}</TableCell>
                <TableCell>{t("createdAt")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!viewWebinarIsLoading
                ? viewWebinarData?.participants?.map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{(pagination.page ?? 0) * pagination.pageSize + index + 1}</TableCell>
                    <TableCell>{row.name} + {row.surname}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.country}</TableCell>
                    <TableCell>{row.city}</TableCell>
                    <TableCell>{row.company}</TableCell>
                    <TableCell>{moment(row.createdAt).format("DD.MM.YYYY HH:mm")}</TableCell>
                  </TableRow>
                ))
                : [1, 2, 3].map((key) => (
                  <TableRow key={key}>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card >
    </Grid >
  );
};

export default View;
