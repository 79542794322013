import {
  IFaqsInput,
  IFaqsRequest,
  IFaqsResponse,
  IFaqsResult,
  faqQuery,
  faqsQuery,
  IFaqInput,
  IFaqRequest,
  IFaqResponse,
  IFaqResult,
  ICreateFaqInput,
  ICreateFaqRequest,
  ICreateFaqResponse,
  ICreateFaqResult,
  createFaqQuery,
  IUpdateFaqInput,
  IUpdateFaqRequest,
  IUpdateFaqResponse,
  IUpdateFaqResult,
  updateFaqQuery,
  IUpdateFaqFilterInput,
  deleteFaqQuery,
  IDeleteFaqInput,
  IDeleteFaqRequest,
  IDeleteFaqResponse,
  IDeleteFaqResult,
} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { faqTags } from "./faq.tags";
import { IGraphqlVariables } from "corede-common";

export const faqApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    faqs: builder.query<
      IFaqsResult,
      IGraphqlVariables<IFaqsInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IFaqsRequest,
        IFaqsResponse,
        IFaqsResult,
        IFaqsInput
      >({
        query: faqsQuery,
        providesTags: [faqTags.faqs],
      })
    ),

    faq: builder.query<
      IFaqResult,
      IGraphqlVariables<IFaqInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IFaqRequest,
        IFaqResponse,
        IFaqResult,
        IFaqInput
      >({
        query: faqQuery,
        providesTags: [faqTags.faq],
      })
    ),

    // mutation
    createFaq: builder.mutation<
      ICreateFaqResult,
      IGraphqlVariables<ICreateFaqInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICreateFaqRequest,
        ICreateFaqResponse,
        ICreateFaqResult,
        ICreateFaqInput
      >({
        query: createFaqQuery,
        invalidatesTags: [faqTags.faqs],
      })
    ),

    updateFaq: builder.mutation<
      IUpdateFaqResult,
      IGraphqlVariables<
        IUpdateFaqInput,
        IUpdateFaqFilterInput
      >
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUpdateFaqRequest,
        IUpdateFaqResponse,
        IUpdateFaqResult,
        IUpdateFaqInput,
        IUpdateFaqFilterInput
      >({
        query: updateFaqQuery,
        invalidatesTags: [
          faqTags.faqs,
          faqTags.faq,
        ],
      })
    ),

    deleteFaq: builder.mutation<
      IDeleteFaqResult,
      IGraphqlVariables<IDeleteFaqInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IDeleteFaqRequest,
        IDeleteFaqResponse,
        IDeleteFaqResult,
        IDeleteFaqInput
      >({
        query: deleteFaqQuery,
        invalidatesTags: [
          faqTags.faqs,
          faqTags.faq,
        ],
      })
    ),
  }),
});

export const {
  useFaqsQuery,
  useLazyFaqsQuery,

  useFaqQuery,
  useLazyFaqQuery,

  useCreateFaqMutation,
  useUpdateFaqMutation,
  useDeleteFaqMutation,
} = faqApi;
