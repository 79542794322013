import { createSlice } from "@reduxjs/toolkit";
import { commonInitialState, commonReducers } from "context/slices";

const { actions, reducer } = createSlice({
  name: "dashboard",
  initialState: {
    ...commonInitialState,
    leftButton: null,
    rightButton: null,
  },
  reducers: {
    ...commonReducers,
  },
});

export const dashboardActions = actions;
export const dashboardSlice = reducer;
