import {
  IWebNotificationListInput,
  IWebNotificationListRequest,
  IWebNotificationListResponse,
  IWebNotificationListResult,
  webNotificationDetailQuery,
  webNotificationListQuery,
  IWebNotificationDetailInput,
  IWebNotificationDetailRequest,
  IWebNotificationDetailResponse,
  IWebNotificationDetailResult,
  IWebNotificationCreateInput,
  IWebNotificationCreateRequest,
  IWebNotificationCreateResponse,
  IWebNotificationCreateResult,
  webNotificationCreateQuery,
  IWebNotificationUpdateInput,
  IWebNotificationUpdateRequest,
  IWebNotificationUpdateResponse,
  IWebNotificationUpdateResult,
  webNotificationUpdateQuery,
  IWebNotificationUpdateFilterInput,
  webNotificationDeleteQuery,
  IWebNotificationDeleteInput,
  IWebNotificationDeleteRequest,
  IWebNotificationDeleteResponse,
  IWebNotificationDeleteResult,
} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { webNotificationTags } from "./webNotification.tags";
import { IGraphqlVariables } from "corede-common";

export const webNotificationApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    webNotifications: builder.query<
      IWebNotificationListResult,
      IGraphqlVariables<IWebNotificationListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IWebNotificationListRequest,
        IWebNotificationListResponse,
        IWebNotificationListResult,
        IWebNotificationListInput
      >({
        query: webNotificationListQuery,
        providesTags: [webNotificationTags.webNotifications],
      })
    ),

    webNotification: builder.query<
      IWebNotificationDetailResult,
      IGraphqlVariables<IWebNotificationDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IWebNotificationDetailRequest,
        IWebNotificationDetailResponse,
        IWebNotificationDetailResult,
        IWebNotificationDetailInput
      >({
        query: webNotificationDetailQuery,
        providesTags: [webNotificationTags.webNotification],
      })
    ),

    // mutation
    webNotificationCreate: builder.mutation<
      IWebNotificationCreateResult,
      IGraphqlVariables<IWebNotificationCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IWebNotificationCreateRequest,
        IWebNotificationCreateResponse,
        IWebNotificationCreateResult,
        IWebNotificationCreateInput
      >({
        query: webNotificationCreateQuery,
        invalidatesTags: [webNotificationTags.webNotifications],
      })
    ),

    webNotificationUpdate: builder.mutation<
      IWebNotificationUpdateResult,
      IGraphqlVariables<
        IWebNotificationUpdateInput,
        IWebNotificationUpdateFilterInput
      >
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IWebNotificationUpdateRequest,
        IWebNotificationUpdateResponse,
        IWebNotificationUpdateResult,
        IWebNotificationUpdateInput,
        IWebNotificationUpdateFilterInput
      >({
        query: webNotificationUpdateQuery,
        invalidatesTags: [
          webNotificationTags.webNotifications,
          webNotificationTags.webNotification,
        ],
      })
    ),

    webNotificationDelete: builder.mutation<
      IWebNotificationDeleteResult,
      IGraphqlVariables<IWebNotificationDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IWebNotificationDeleteRequest,
        IWebNotificationDeleteResponse,
        IWebNotificationDeleteResult,
        IWebNotificationDeleteInput
      >({
        query: webNotificationDeleteQuery,
        invalidatesTags: [
          webNotificationTags.webNotifications,
          webNotificationTags.webNotification,
        ],
      })
    ),
  }),
});

export const {
  useWebNotificationsQuery,
  useLazyWebNotificationsQuery,

  useWebNotificationQuery,
  useLazyWebNotificationQuery,

  useWebNotificationCreateMutation,
  useWebNotificationUpdateMutation,
  useWebNotificationDeleteMutation,
} = webNotificationApi;
