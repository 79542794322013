import {
  IContactFormsInput,
  IContactFormsRequest,
  IContactFormsResponse,
  IContactFormsResult,
  contactFormQuery,
  contactFormsQuery,
  IContactFormInput,
  IContactFormRequest,
  IContactFormResponse,
  IContactFormResult,
  deleteContactFormQuery,
  IDeleteContactFormInput,
  IDeleteContactFormRequest,
  IDeleteContactFormResponse,
  IDeleteContactFormResult,
  IUpdateContactFormFilterInput,
  IUpdateContactFormInput,
  IUpdateContactFormRequest,
  IUpdateContactFormResponse,
  IUpdateContactFormResult,
  updateContactFormQuery,
} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { contactFormTags } from "./contactForm.tags";
import { IGraphqlVariables } from "corede-common";

export const contactFormApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
      

    contactForms: builder.query<
      IContactFormsResult,
      IGraphqlVariables<IContactFormsInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IContactFormsRequest,
        IContactFormsResponse,
        IContactFormsResult,
        IContactFormsInput
      >({
        query: contactFormsQuery,
        providesTags: [contactFormTags.contactForms],
      })
    ),

    contactForm: builder.query<
      IContactFormResult,
      IGraphqlVariables<IContactFormInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IContactFormRequest,
        IContactFormResponse,
        IContactFormResult,
        IContactFormInput
      >({
        query: contactFormQuery,
        providesTags: [contactFormTags.contactForm],
      })
    ),

    // mutation

    updateContactForm: builder.mutation<
      IUpdateContactFormResult,
      IGraphqlVariables<
        IUpdateContactFormInput,
        IUpdateContactFormFilterInput
      >
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUpdateContactFormRequest,
        IUpdateContactFormResponse,
        IUpdateContactFormResult,
        IUpdateContactFormInput,
        IUpdateContactFormFilterInput
      >({
        query: updateContactFormQuery,
        invalidatesTags: [
          contactFormTags.contactForms,
          contactFormTags.contactForm,
        ],
      })
    ),

    deleteContactForm: builder.mutation<
      IDeleteContactFormResult,
      IGraphqlVariables<IDeleteContactFormInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IDeleteContactFormRequest,
        IDeleteContactFormResponse,
        IDeleteContactFormResult,
        IDeleteContactFormInput
      >({
        query: deleteContactFormQuery,
        invalidatesTags: [
          contactFormTags.contactForms,
          contactFormTags.contactForm,
        ],
      })
    ),
  }),
});

export const {

  useContactFormsQuery,
  useLazyContactFormsQuery,

  useContactFormQuery,
  useLazyContactFormQuery,

  useUpdateContactFormMutation,
  useDeleteContactFormMutation,
} = contactFormApi;
