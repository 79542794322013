const locale = {
  subscriptionForm: "Subscription Form",
  subscriptionForms: "Subscription Forms",
  create: "Create Subscription Form",
  update: "Update Subscription Form",
  delete: "Delete Subscription Form",
  view: "View Subscription Form",
  list: "List Subscription Forms",
  createdSuccessfully: "Subscription Form created successfully",
  updatedSuccessfully: "Subscription Form updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Subscription Form deleted successfully",
  email: "Email",
  fullName: "Full Name",
  status: "Status",
  userType: "User Type",
  blog: "Blog",
  news: "News",
  offer: "Offer",
  product: "Product",
  language: "Language",
  page: "Page",
  createdAt: "Created At",
  updatedAt: "Updated At",
};

export default locale;
