

import { commonRoutes } from "routes/route.common";

export const webinarDomainRoutes = {
  base: "webinar",
  subdomains: {
    webinar: {
      base: "webinars",
    },
    // add subdomain route names here
  },
};

// Webinar routes
export const baseWebinarRoute = (): string => `/${webinarDomainRoutes.base}/`;
export const listWebinarRoute = (): string =>
  `/${webinarDomainRoutes.base}/${webinarDomainRoutes.subdomains.webinar.base}`;
export const viewWebinarRoute = (id: string): string =>
  `/${webinarDomainRoutes.base}/${webinarDomainRoutes.subdomains.webinar.base}/${id}`;
export const createWebinarRoute = (): string =>
  `/${webinarDomainRoutes.base}/${webinarDomainRoutes.subdomains.webinar.base}/${commonRoutes.create}`;
export const updateWebinarRoute = (id: string): string =>
  `/${webinarDomainRoutes.base}/${webinarDomainRoutes.subdomains.webinar.base}/${id}/${commonRoutes.update}`;

// add subdomain routes here
