const locale = {
  subscription: "Subscription",
  subscriptions: "Subscriptions",
  create: "Create Subscription",
  update: "Update Subscription",
  delete: "Delete Subscription",
  view: "View Subscription",
  list: "List Subscriptions",
  createdSuccessfully: "Subscription created successfully",
  updatedSuccessfully: "Subscription updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Subscription deleted successfully",
  filters: "Filters",
  email: "Email",
  userType: "User Type",
  statuses: "Statuses",
  language: "Language",
  user: "User",
  page: "Page",
  subscribedTopics: "Subscribed Topics",
  start: "Start",
  end: "End",
  downloadExampleForm: "Örnek İndir",
  downloadForm: "İndir",
  importForm: "Yükle",
  importedSuccessfully: "Abonelik başarıyla yüklendi",
  importedError: "Abonelik yükleme hatası",
  downloadExampleFormSuccessfully: "Örnek form başarıyla indirildi",
  downloadExampleFormError: "Örnek form indirme hatası",
  downloadFormSuccessfully: "Form başarıyla indirildi",
  downloadFormError: "Form indirme hatası",
};

export default locale;
