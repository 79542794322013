import {
  Grid,
  Stack,
  Button,
  Skeleton,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Typography,
  ClickAwayListener,
  TextField,
  Autocomplete,
  TableSortLabel
} from "@mui/material";
import { useAppDispatch } from "context";
import { webinarActions } from "../../../context";
import {
  createWebinarRoute,
  updateWebinarRoute,
  viewWebinarRoute,
} from "../../../routes/webinar.base.route";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useUpdateWebinarMutation, useWebinarsQuery } from "../context/webinar.api";
import { Delete as DeleteIcon, Download, Edit, RemoveRedEye } from "@mui/icons-material";
import { MyTablePagination, StatusPopper } from "components";
import { IBaseError, IGraphqlVariables, Language, TNullable } from "corede-common";
import {
  IBaseWebinarEntity,
  IWebinarsInput,
  WebinarStatus,
} from "corede-common-cocrm";
import { getCurrentLanguage } from "localization";
import { useNavigate } from "react-router-dom";
import Delete from "./Delete";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs from "dayjs";
import { BASE_URL } from "utils";
import { getAccessToken } from "utils/getUserInfo";
import axios from "axios";

const List = () => {
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();

  // useStates
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 0
  });
  const [selectedItemForDelete, setSelectedItemForDelete] =
    useState<TNullable<IBaseWebinarEntity>>(null);
  const [variables, setVariables] = useState<
    IGraphqlVariables<IWebinarsInput>
  >({
    input: {
      filter: {
        title: undefined,
        statuses: undefined,
        languages: undefined,
        lastParticipationDateFilter: undefined,
        startDateFilter: undefined,
        updatedAtDateFilter: undefined
      },
      pagination: {
        pageSize: pagination.pageSize,
        page: pagination.page + 1,
        sort: undefined
      },
    },
  });
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<TNullable<IBaseWebinarEntity>>(null);
  const [selectedStatus, setSelectedStatus] = useState<WebinarStatus | null>(null);

  // queries
  const {
    data: webinarsData,
    isLoading: webinarsIsLoading,
    error: webinarsError,
  } = useWebinarsQuery(variables);

  // mutations

  const [
    updateWebinar,
    {
      data: updateWebinarData,
      isLoading: updateWebinarIsLoading,
      error: updateWebinarError,
    },
  ] = useUpdateWebinarMutation();

  // constants

  const handleClickStatus = (event: any, item: IBaseWebinarEntity) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setSelectedRow(selectedRow?._id === item._id ? null : item);
    setSelectedStatus(null);
  };

  const handleClickAway = () => {
    setOpen(false);
    setAnchorEl(null);
    setSelectedRow(null);
    setSelectedStatus(null);
  };

  const handleSelectStatus = (status: WebinarStatus) => {
    setSelectedStatus(status);
  }

  const handleChangeStatus = (item: IBaseWebinarEntity) => {
    updateWebinar({
      filter: { _id: item._id },
      input: {
        status: selectedStatus as WebinarStatus,
      },
    });
  }

  const handleSortRequest = (sortName: string) => {

    sortName === "index" ?
      setVariables({
        ...variables,
        input: {
          ...variables.input,
          pagination: {
            ...variables.input?.pagination,
            sort: undefined
          }
        }
      })
      :
      setVariables({
        ...variables,
        input: {
          ...variables.input,
          pagination: {
            ...variables.input?.pagination,
            sort: {
              [sortName]: variables.input?.pagination?.sort?.[sortName] === 1 ? -1 : 1
            }
          }
        }
      })
  };


  // action handlers
  const handleEdit = (id: string) => {
    navigate(updateWebinarRoute(id));
  };

  const handleDelete = (item: IBaseWebinarEntity) => {
    setSelectedItemForDelete(item);
  };

  const handleView = (id: string) => {
    navigate(viewWebinarRoute(id));
  };

  const handleDownload = (id: string) => {

    let data = JSON.stringify({
      "_id": id
    });

    let config = {
      method: 'post',
      url: `${BASE_URL}/webinar/participants/export`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`,
      },
      data: data,
      responseType: 'blob'
    };

    axios.request(config as any)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `webinar_participants_${id}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.log(error);
      });



  };

  // useEffects.data
  useEffect(() => {
    if (updateWebinarData) {
      setAnchorEl(null);
      setOpen(false);
      setSelectedRow(null);
      setSelectedStatus(null);

      enqueueSnackbar(t("main.webinar.webinar.updatedSuccessfully"), {
        variant: "success",
      });

    }
  }, [updateWebinarData]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      webinarsError as IBaseError,
      currentLanguage
    );
  }, [webinarsError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      updateWebinarError as IBaseError,
      currentLanguage
    );
  }, [updateWebinarError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    setVariables({
      input: {
        pagination: {
          pageSize: pagination.pageSize,
          page: pagination.page + 1,
        },
      },
    });
  }, [pagination]);

  useEffect(() => {
    dispatch(webinarActions.setTitle(t("main.webinar.webinar.webinars")));
    dispatch(webinarActions.setBackButton(false));
    dispatch(
      webinarActions.setRightButton({
        title: t(`main.webinar.webinar.create`),
        path: createWebinarRoute(),
        icon: null,
      })
    );
  }, [dispatch]);


  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Grid item xs={12}>
        {/* Filters */}
        <Stack direction={"column"} gap={2} mb={2} border={"1px solid #CDCFD1"} borderRadius={2} p={2}>
          <Stack direction="row" gap={2}>
            <Typography variant="h5">{t("main.webinar.webinar.filters")}</Typography>
            <Stack direction="row" gap={1}>
              {
                Object.values(Language).map((language) => (
                  <Chip
                    key={language}
                    label={language}
                    variant={variables.input?.filter?.languages?.[0] === language ? "filled" : "outlined"}
                    onClick={() => {
                      setVariables({
                        ...variables,
                        input: {
                          ...variables.input,
                          filter: {
                            ...(variables.input?.filter || {}),
                            languages: variables.input?.filter?.languages?.[0] === language ? undefined : [language],
                          },
                        },
                      });
                    }}
                  />
                ))
              }
            </Stack>
          </Stack>
          <Stack direction={{ xs: "column", md: "row" }} gap={2}>
            <TextField
              fullWidth
              label={t("main.webinar.webinar.title")}
              value={variables.input?.filter?.title || ""}
              onChange={(e) => {
                setVariables({
                  ...variables,
                  input: {
                    ...variables.input,
                    filter: {
                      ...(variables.input?.filter || {}),
                      title: e.target.value,
                    },
                  },
                });
              }}
            />
            <Autocomplete
              id="statuses"
              fullWidth
              options={Object.values(WebinarStatus).map((status) => ({ name: status, _id: status }))}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label={t("main.webinar.webinar.statuses")} />}
              multiple
              value={
                variables.input?.filter?.statuses && variables.input.filter.statuses.length > 0
                  ? variables.input.filter.statuses.map((status) => ({ name: status, _id: status }))
                  : undefined
              }
              onChange={(e, value) => {
                setVariables({
                  ...variables,
                  input: {
                    ...variables.input,
                    filter: {
                      ...(variables.input?.filter || {}),
                      statuses: value?.length > 0 ? value.map((status) => status._id) : undefined,
                    },
                  },
                });
              }}
            />
          </Stack>
          <Stack direction={{ xs: "column", md: "row" }} gap={2}>
            <Stack direction={"column"} gap={1}>
              <Typography fontSize={"small"} textAlign="center" fontWeight={"bold"}>{t("main.webinar.webinar.lastParticipationDate")}</Typography>
              <DateRangePicker
                sx={{ width: "100%" }}
                label={t("main.webinar.webinar.lastParticipationDate")}
                value={variables.input?.filter?.lastParticipationDate ?
                  [dayjs(variables.input.filter.lastParticipationDate.from),
                  dayjs(variables.input.filter.lastParticipationDate.to)]
                  : undefined}
                onChange={(e) => {
                  setVariables({
                    ...variables,
                    input: {
                      ...variables.input,
                      filter: {
                        ...(variables.input?.filter || {}),
                        lastParticipationDateFilter: {
                          from: e?.[0]?.toDate() || undefined,
                          to: e?.[1]?.toDate() || undefined,
                        }
                      },
                    },
                  });
                }}
              />
            </Stack>
            <Stack direction={"column"} gap={1}>
              <Typography fontSize={"small"} textAlign="center" fontWeight={"bold"}>{t("main.webinar.webinar.startDate")}</Typography>
              <DateRangePicker
                sx={{ width: "100%" }}
                label={t("main.webinar.webinar.startDate")}
                value={variables.input?.filter?.startDate ?
                  [dayjs(variables.input.filter.startDate.from),
                  dayjs(variables.input.filter.startDate.to)]
                  : undefined}
                onChange={(e) => {
                  setVariables({
                    ...variables,
                    input: {
                      ...variables.input,
                      filter: {
                        ...(variables.input?.filter || {}),
                        startDateFilter: {
                          from: e?.[0]?.toDate() || undefined,
                          to: e?.[1]?.toDate() || undefined,
                        }
                      },
                    },
                  });
                }}
              />
            </Stack>
            <Stack direction={"column"} gap={1}>
              <Typography fontSize={"small"} textAlign="center" fontWeight={"bold"}>{t("main.webinar.webinar.updatedAtDate")}</Typography>
              <DateRangePicker
                sx={{ width: "100%" }}
                label={t("main.webinar.webinar.updatedAtDate")}
                value={variables.input?.filter?.updatedAtDate ?
                  [dayjs(variables.input.filter.updatedAtDate.from),
                  dayjs(variables.input.filter.updatedAtDate.to)]
                  : undefined}
                onChange={(e) => {
                  setVariables({
                    ...variables,
                    input: {
                      ...variables.input,
                      filter: {
                        ...(variables.input?.filter || {}),
                        updatedAtDateFilter: {
                          from: e?.[0]?.toDate() || undefined,
                          to: e?.[1]?.toDate() || undefined,
                        }
                      },
                    },
                  });
                }}
              />
            </Stack>
          </Stack>
        </Stack>
        <Card sx={{ p: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={variables.input?.pagination?.sort?.index !== undefined}
                      direction={variables.input?.pagination?.sort?.index === 1 ? "asc" : "desc"}
                      onClick={() => handleSortRequest("index")}
                    >#</TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={variables.input?.pagination?.sort?.title !== undefined}
                      direction={variables.input?.pagination?.sort?.title === 1 ? "asc" : "desc"}
                      onClick={() => handleSortRequest("title")}
                    >{t("title")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={variables.input?.pagination?.sort?.duration !== undefined}
                      direction={variables.input?.pagination?.sort?.duration === 1 ? "asc" : "desc"}
                      onClick={() => handleSortRequest("duration")}>
                      {t("duration")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={variables.input?.pagination?.sort?.startDate !== undefined}
                      direction={variables.input?.pagination?.sort?.startDate === 1 ? "asc" : "desc"}
                      onClick={() => handleSortRequest("startDate")}>
                      {t("startDate")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={variables.input?.pagination?.sort?.lastApplicationDate !== undefined}
                      direction={variables.input?.pagination?.sort?.lastApplicationDate === 1 ? "asc" : "desc"}
                      onClick={() => handleSortRequest("lastApplicationDate")}>
                      {t("lastApplicationDate")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={variables.input?.pagination?.sort?.participants !== undefined}
                      direction={variables.input?.pagination?.sort?.participants === 1 ? "asc" : "desc"}
                      onClick={() => handleSortRequest("participants")}>
                      {t("participants")}
                    </TableSortLabel>
                    {" / "}
                    <TableSortLabel
                      active={variables.input?.pagination?.sort?.quota !== undefined}
                      direction={variables.input?.pagination?.sort?.quota === 1 ? "asc" : "desc"}
                      onClick={() => handleSortRequest("quota")}>
                      {t("quota")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={variables.input?.pagination?.sort?.status !== undefined}
                      direction={variables.input?.pagination?.sort?.status === 1 ? "asc" : "desc"}
                      onClick={() => handleSortRequest("status")}>
                      {t("status")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={variables.input?.pagination?.sort?.type !== undefined}
                      direction={variables.input?.pagination?.sort?.type === 1 ? "asc" : "desc"}
                      onClick={() => handleSortRequest("type")}>
                      {t("type")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell >
                    <TableSortLabel
                      active={variables.input?.pagination?.sort?.createdAt !== undefined}
                      direction={variables.input?.pagination?.sort?.createdAt === 1 ? "asc" : "desc"}
                      onClick={() => handleSortRequest("createdAt")}>
                      {t("createdAt")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">{t("actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!webinarsIsLoading
                  ? webinarsData?.data?.map((row, index) => (
                    <TableRow
                      key={row._id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{(pagination.page ?? 0) * pagination.pageSize + index + 1}</TableCell>
                      <TableCell><Typography variant="body1" sx={{ fontWeight: 600 }}>
                        {row.title}
                      </Typography></TableCell>
                      <TableCell>{row.duration} {t("min")}</TableCell>
                      <TableCell>{moment(row.startDate).format("DD.MM.YYYY HH:mm")}</TableCell>
                      <TableCell>{moment(row.lastApplicationDate).format("DD.MM.YYYY HH:mm")}</TableCell>
                      <TableCell>{row.participants?.length} / {row.quota}</TableCell>
                      <TableCell>
                        <Chip label={row.status}
                          aria-describedby={row._id}
                          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                            handleClickStatus(event, row);
                          }}
                          style={{ cursor: "pointer" }} />
                        <StatusPopper
                          open={selectedRow?._id === row._id && open}
                          anchorEl={anchorEl}
                          dataList={Object.values(WebinarStatus)}
                          row={row}
                          handleEdit={handleEdit}
                          handleSelectStatus={handleSelectStatus}
                          handleChangeStatus={handleChangeStatus}
                          selectedStatus={selectedStatus as WebinarStatus}
                          loading={updateWebinarIsLoading}
                        />
                      </TableCell>
                      <TableCell><Chip label={row.type} /></TableCell>
                      <TableCell>{moment(row.createdAt).format("DD.MM.YYYY HH:mm")}</TableCell>

                      <TableCell align="right">
                        <Stack direction="row" justifyContent={"flex-end"} gap={1} flexWrap={"wrap"}>
                          <Button
                            onClick={() => handleDownload(row._id)}
                            variant="outlined"
                            size="small"
                            sx={{ height: 32, minWidth: 10 }}
                          >
                            <Download sx={{ fontSize: "16px" }} />
                          </Button>
                          <Button
                            onClick={() => handleView(row._id)}
                            variant="outlined"
                            size="small"
                            sx={{ height: 32, minWidth: 10 }}
                          >
                            <RemoveRedEye sx={{ fontSize: "16px" }} />
                          </Button>
                          <Button
                            onClick={() => handleEdit(row._id)}
                            variant="outlined"
                            size="small"
                            sx={{ height: 32, minWidth: 10 }}
                          >
                            <Edit sx={{ fontSize: "16px" }} />
                          </Button>
                          <Button
                            onClick={() => handleDelete(row)}
                            variant="outlined"
                            size="small"
                            color="error"
                            sx={{ height: 32, minWidth: 10 }}
                          >
                            <DeleteIcon sx={{ fontSize: "16px" }} />
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                  : [1, 2, 3].map((key) => (
                    <TableRow key={key}>
                      <TableCell>
                        <Skeleton variant="text" sx={{ width: '100%' }} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" sx={{ width: '100%' }} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" sx={{ width: '100%' }} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" sx={{ width: '100%' }} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" sx={{ width: '100%' }} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" sx={{ width: '100%' }} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>

        <Stack
          direction="row"
          justifyContent={{ xs: "center", md: "flex-start" }}
          mt={2}
          mb={2}
        >
          <MyTablePagination
            count={webinarsData?.count ?? 0}
            pageSize={pagination.pageSize}
            page={pagination.page ?? 0}
            onPageChange={(e, page) => {
              setPagination({
                ...pagination,
                page: page,
              });
            }}
            onRowsPerPageChange={(e) => {
              setPagination({
                page: 0,
                pageSize: parseInt(e.target.value),
              });
            }}
          />
        </Stack>

        <Delete
          open={!!selectedItemForDelete}
          item={selectedItemForDelete!}
          onClose={() => {
            setSelectedItemForDelete(null);
          }}
        />
      </Grid>
    </ClickAwayListener>
  );
};

export default List;
