const locale = {
  webinar: "Webinar",
  webinars: "Webinars",
  create: "Webinar Oluştur",
  update: "Webinar Güncelle",
  delete: "Webinar Sil",
  view: "Webinar Görüntüle",
  list: "Webinar Listesi",
  createdSuccessfully: "Webinar başarıyla oluşturuldu",
  updatedSuccessfully: "Webinar başarıyla güncellendi",
  deleteConfirm: "Silmek istediğinize emin misiniz: ",
  deletedSuccessfully: "Webinar başarıyla silindi",
  title: "Başlık",
  content: "İçerik",
  description: "Açıklama",
  language: "Dil",
  type: "Tip",
  quota: "Kota",
  startDate: "Başlangıç Tarihi",
  lastApplicationDate: "Son Başvuru Tarihi",
  duration: "Süre (dk)",
  participationLink: "Katılım Linki",
  status: "Durum",
  lastParticipationDate: "Son Katılım Tarihi",
  statuses: "Durumlar",
  updatedAtDate: "Güncellenme Tarihi",
  filters: "Filtreler",
  participants: "Katılımcılar",
  
};

export default locale;
