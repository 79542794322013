const locale = {
  requestForm: "Request Form",
  requestForms: "Request Forms",
  create: "Create Request Form",
  update: "Update Request Form",
  delete: "Delete Request Form",
  view: "View Request Form",
  list: "List Request Forms",
  createdSuccessfully: "Request Form created successfully",
  updatedSuccessfully: "Request Form updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Request Form deleted successfully",
};

export default locale;
