import { Card, CircularProgress, Grid, Stack, TextField, Typography } from "@mui/material";
import { useFaqQuery } from "../context/faq.api";
import { useAppDispatch } from "context";
import { faqActions } from "../../../context";
import { useEffect } from "react";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import { IBaseError } from "corede-common";
import { getCurrentLanguage } from "localization";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { listFaqRoute, updateFaqRoute } from "../../../routes/faq.base.route";
import { IFaqResult } from "corede-common-cocrm";


// question: string; // unique
// answer: string;
// readingTime: string;
// category: IBaseFaqCategoryEntity;
// language: Language;

const View = () => {
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const { id } = useParams();
  if (!id) {
    navigate(listFaqRoute());
  }
  // queries
  const {
    data: viewFaqData,
    isLoading: viewFaqIsLoading,
    error: viewFaqError,
  } = useFaqQuery({
    input: { _id: id! },
  });

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      viewFaqError as IBaseError,
      currentLanguage
    );
  }, [viewFaqError, currentLanguage]);

  useEffect(() => {
    dispatch(faqActions.setTitle(t("main.faq.faq.view")));
    dispatch(faqActions.setBackButton(true));
    dispatch(
      faqActions.setRightButton({
        title: t(`main.faq.faq.update`),
        path: updateFaqRoute(id!),
        icon: null,
      })
    );
  }, [dispatch, id]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2}>
        {viewFaqIsLoading ? (
          <CircularProgress />
        ) : (
          <Stack direction="column" gap={2}>
            <Card sx={{ p: 4 }}>
              <Typography><b>{t("main.faq.faq.question")}:</b> {viewFaqData?.question}</Typography>
              <Typography><b>{t("main.faq.faq.answer")}:</b> {viewFaqData?.answer}</Typography>
              <Typography><b>{t("main.faq.faq.readingTime")}:</b> {viewFaqData?.readingTime}</Typography>
              <Typography><b>{t("main.faq.faq.category")}:</b> {viewFaqData?.category.name}</Typography>
              <Typography><b>{t("main.faq.faq.language")}:</b> {viewFaqData?.language}</Typography>
            </Card>
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default View;
