import { Card, CircularProgress, Grid, Stack, TextField, Typography } from "@mui/material";
import { useFaqCategoryQuery } from "../context/faqCategory.api";
import { useAppDispatch } from "context";
import { faqActions } from "../../../context";
import { useEffect } from "react";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import { IBaseError } from "corede-common";
import { getCurrentLanguage } from "localization";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { listFaqCategoryRoute, updateFaqCategoryRoute } from "../../../routes/faq.base.route";

const View = () => {
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const { id } = useParams();
  if (!id) {
    navigate(listFaqCategoryRoute());
  }
  // queries
  const {
    data: viewFaqCategoryData,
    isLoading: viewFaqCategoryIsLoading,
    error: viewFaqCategoryError,
  } = useFaqCategoryQuery({
    input: { _id: id! },
  });

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      viewFaqCategoryError as IBaseError,
      currentLanguage
    );
  }, [viewFaqCategoryError, currentLanguage]);

  useEffect(() => {
    dispatch(faqActions.setTitle(t("main.faq.faqCategory.view")));
    dispatch(faqActions.setBackButton(true));
    dispatch(
      faqActions.setRightButton({
        title: t(`main.faq.faqCategory.update`),
        path: updateFaqCategoryRoute(id!),
        icon: null,
      })
    );
  }, [dispatch, id]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2} maxWidth={400}>
        {viewFaqCategoryIsLoading ? (
          <CircularProgress />
        ) : (
          <Stack direction="column" gap={2}>
            <Card sx={{ p: 4 }}>
              <Stack direction="column" gap={2}>
                <Typography><b>{t("main.faq.faqCategory.name")}</b>: {viewFaqCategoryData?.name}</Typography>
                <Typography><b>{t("main.faq.faqCategory.icon")}</b>: {viewFaqCategoryData?.icon}</Typography>
                <Typography><b>{t("main.faq.faqCategory.parentCategory")}</b>: {viewFaqCategoryData?.parentCategory?.name ?? "-"}</Typography>
                <Typography><b>{t("main.faq.faqCategory.name") + " En"}</b>: {viewFaqCategoryData?.nameTranslation?.en}</Typography>
                <Typography><b>{t("main.faq.faqCategory.name") + " Tr"}</b>: {viewFaqCategoryData?.nameTranslation?.tr}</Typography>
              </Stack>
            </Card>
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default View;
