import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Autocomplete,
} from "@mui/material";
import {
  useBlogCategoriesQuery,
  useUpdateBlogCategoryMutation,
  useBlogCategoryQuery,
} from "../context/blogCategory.api";
import { useFormik } from "formik";
import { useAppDispatch } from "context";
import { blogActions } from "../../../context";
import { IUpdateBlogCategoryInput } from "corede-common-cocrm";
import { IBaseError, IGraphqlVariables } from "corede-common";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { t } from "i18next";
import { useParams, useNavigate } from "react-router-dom";
import { validateUpdateBlogCategoryInput } from "../validations/update.validation";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { listBlogCategoriesRoute } from "../../../routes/blog.base.route";

const Update = () => {
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const { id } = useParams();
  if (!id) {
    navigate(listBlogCategoriesRoute());
  }

  // queries
  const {
    data: blogCategoriesData,
    isLoading: blogCategoriesIsLoading,
    error: blogCategoriesError,
  } = useBlogCategoriesQuery({});
  const {
    data: blogCategoryData,
    isLoading: blogCategoryIsLoading,
    error: blogCategoryError,
  } = useBlogCategoryQuery({ input: { _id: id! } });

  // mutations
  const [
    updateBlogCategory,
    {
      data: updateBlogCategoryData,
      isLoading: updateBlogCategoryIsLoading,
      error: updateBlogCategoryError,
    },
  ] = useUpdateBlogCategoryMutation();
  // constants
  const parentCategories = [
    {
      _id: undefined,
      name: "",
      icon: "",
      parentCategory: null,
      hasSubBlogCategory: true,
    },
    ...(blogCategoriesData?.data?.filter(
      (category) => category.parentCategory === null
    ) || []),
  ];

  const {
    values,
    handleSubmit,
    handleBlur,
    setFieldValue,
    errors,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      input: {
        name: "",
        icon: "",
        parentCategoryId: "",
        nameTranslation: undefined
      },
    },
    enableReinitialize: true,
    validationSchema: validateUpdateBlogCategoryInput,
    onSubmit: (values: IGraphqlVariables<IUpdateBlogCategoryInput>) => {
      updateBlogCategory({
        filter: { _id: id! },
        input: values.input,
      });
    },
  });

  // useEffects.success
  useEffect(() => {
    console.log(blogCategoryData);
    if (blogCategoryData) {
      setValues({
        input: {
          name: blogCategoryData?.name,
          icon: blogCategoryData?.icon ?? "",
          parentCategoryId: blogCategoryData?.parentCategory?._id ?? "",
          nameTranslation: blogCategoryData?.nameTranslation
        },
      });
    }
  }, [blogCategoryData, setValues]);

  useEffect(() => {
    if (updateBlogCategoryData) {
      resetForm();
      enqueueSnackbar(t("main.blog.blogCategory.updatedSuccessfully"), {
        variant: "success",
      });
    }
  }, [updateBlogCategoryData, resetForm]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      updateBlogCategoryError as IBaseError,
      currentLanguage
    );
  }, [updateBlogCategoryError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      blogCategoryError as IBaseError,
      currentLanguage
    );
  }, [blogCategoryError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      blogCategoriesError as IBaseError,
      currentLanguage
    );
  }, [blogCategoriesError, currentLanguage]);


  // useEffects.init
  useEffect(() => {
    dispatch(blogActions.setTitle(t("main.blog.blogCategory.update")));
    dispatch(blogActions.setBackButton(true));
    dispatch(blogActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2} maxWidth={400}>
        {blogCategoryIsLoading ? (
          <CircularProgress />
        ) : (
          <Stack direction="column" gap={2}>
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              value={(values as any).input.name}
              onChange={(e) => setFieldValue("input.name", e.target.value)}
              onBlur={() => handleBlur("input.name")}
              error={Boolean((errors as any).input?.name)}
              helperText={(errors as any).input?.name}
            />
            <TextField
              fullWidth
              label="Name - English"
              variant="outlined"
              value={(values as any).input.nameTranslation?.en}
              onChange={(e) => setFieldValue("input.nameTranslation.en", e.target.value)}
              onBlur={() => handleBlur("input.nameTranslation.en")}
              error={Boolean((errors as any).input?.nameTranslation?.en)}
              helperText={(errors as any).input?.nameTranslation?.en}
            />
            <TextField
              fullWidth
              label="Name - Turkish"
              variant="outlined"
              value={(values as any).input.nameTranslation?.tr}
              onChange={(e) => setFieldValue("input.nameTranslation.tr", e.target.value)}
              onBlur={() => handleBlur("input.nameTranslation.tr")}
              error={Boolean((errors as any).input?.nameTranslation?.tr)}
              helperText={(errors as any).input?.nameTranslation?.tr}
            />
            <TextField
              fullWidth
              label="Icon"
              variant="outlined"
              value={(values as any).input.icon}
              onChange={(e) => setFieldValue("input.icon", e.target.value)}
              onBlur={() => handleBlur("input.icon")}
              error={Boolean((errors as any).input?.icon)}
              helperText={(errors as any).input?.icon}
            />
            <Autocomplete
              id="parentCategoryId"
              isOptionEqualToValue={(option, value) => option._id === value._id}
              getOptionLabel={(option) => option.name}
              options={parentCategories}
              loading={blogCategoriesIsLoading}
              renderInput={(params) => <TextField {...params} label={t("main.blog.blogCategory.parentCategory")} />}
              value={parentCategories.find((category) => category._id === (values as any).input.parentCategoryId) || null}
              onChange={(e, value) => {
                setFieldValue("input.parentCategoryId", value?._id);
              }}
              onBlur={() => handleBlur("input.parentCategoryId")}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
              disabled={updateBlogCategoryIsLoading || !(values as any).input.name}
            >
              {updateBlogCategoryIsLoading ? (
                <CircularProgress size="1rem" sx={{ mr: 2 }} />
              ) : (
                ""
              )}
              {t("update")}
            </Button>
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default Update;
