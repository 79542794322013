import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import {
  useUpdateContactFormMutation,
  useContactFormQuery,
} from "../context/contactForm.api";
import { useUsersQuery, } from "apps/auth/context";
import { useFormik } from "formik";
import { useAppDispatch } from "context";
import { contactFormActions } from "../../../context";
import { ContactFormStatus, IUpdateContactFormInput } from "corede-common-cocrm";
import { IBaseError, IGraphqlVariables } from "corede-common";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { t } from "i18next";
import { useParams, useNavigate } from "react-router-dom";
import { validateUpdateContactFormInput } from "../validations/update.validation";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { listContactFormRoute } from "../../../routes/contactForm.base.route";

const Update = () => {

  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const { id } = useParams();
  if (!id) {
    navigate(listContactFormRoute());
  }

  // queries
  const {
    data: contactFormData,
    isLoading: contactFormIsLoading,
    error: contactFormError,
  } = useContactFormQuery({ input: { _id: id! } });

  const {
    data: usersData,
    isLoading: usersIsLoading,
    error: usersError,
  } = useUsersQuery({});

  console.log(usersData);

  // mutations
  const [
    updateContactForm,
    {
      data: updateContactFormData,
      isLoading: updateContactFormIsLoading,
      error: updateContactFormError,
    },
  ] = useUpdateContactFormMutation();

  // constants
  const {
    values,
    handleSubmit,
    handleBlur,
    setFieldValue,
    errors,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      input: {
        status: undefined,
        note: "",
        escalatedUserId: "",
        responsibleUserId: "",
      },
    },
    enableReinitialize: true,
    validationSchema: validateUpdateContactFormInput,
    onSubmit: (values: IGraphqlVariables<IUpdateContactFormInput>) => {
      updateContactForm({
        filter: { _id: id! },
        input: values.input,
      });
    },
  });



  useEffect(() => {

    if (contactFormData) {
      setValues({
        input: {
          status: contactFormData.status,
          // note: contactFormData.note, 
          escalatedUserId: contactFormData.escalatedUser?._id,
          responsibleUserId: contactFormData.responsibleUser?._id,
        },
      });
    }
  }, [contactFormData, setValues]);

  useEffect(() => {
    if (updateContactFormData) {
      resetForm();
      enqueueSnackbar(t("main.contactForm.contactForm.updatedSuccessfully"), {
        variant: "success",
      });
    }
  }, [updateContactFormData, resetForm]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      updateContactFormError as IBaseError,
      currentLanguage
    );
  }, [updateContactFormError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      contactFormError as IBaseError,
      currentLanguage
    );
  }, [contactFormError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(usersError as IBaseError, currentLanguage);
  }, [usersError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    dispatch(contactFormActions.setTitle(t("main.contactForm.contactForm.update")));
    dispatch(contactFormActions.setBackButton(true));
    dispatch(contactFormActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2}>
        {(contactFormIsLoading || usersIsLoading) ? (
          <CircularProgress />
        ) : (
          <Stack direction="column" gap={2} maxWidth={400}>

            <Autocomplete
              id="status"
              isOptionEqualToValue={(option, value) => option === value}
              getOptionLabel={(option) => option}
              options={Object.values(ContactFormStatus)}
              renderInput={(params) => <TextField {...params} label={t("main.contactForm.contactForm.status")} />}
              value={(values as any).input.status || ""}
              onChange={(e, value) => {
                setFieldValue("input.status", value);
              }}
              onBlur={() => handleBlur("input.status")}
            />

            <Autocomplete
              id="escalatedUserId"
              isOptionEqualToValue={(option, value) => option._id === value._id}
              getOptionLabel={(option) => option.name + " " + option.surname}
              options={usersData?.data || []}
              loading={usersIsLoading}
              renderInput={(params) => <TextField {...params} label={t("main.contactForm.contactForm.escalatedUser")} />}
              value={usersData?.data?.find((category) => category._id === (values as any).input.escalatedUserId) || null}
              onChange={(e, value) => {
                setFieldValue("input.escalatedUserId", value?._id);
              }}
              onBlur={() => handleBlur("input.escalatedUserId")}
            />

            <Autocomplete
              id="responsibleUserId"
              isOptionEqualToValue={(option, value) => option._id === value._id}
              getOptionLabel={(option) => option.name + " " + option.surname}
              options={usersData?.data || []}
              loading={usersIsLoading}
              renderInput={(params) => <TextField {...params} label={t("main.contactForm.contactForm.responsibleUser")} />}
              value={usersData?.data?.find((category) => category._id === (values as any).input.responsibleUserId) || null}
              onChange={(e, value) => {
                setFieldValue("input.responsibleUserId", value?._id);
              }}
              onBlur={() => handleBlur("input.responsibleUserId")}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
              disabled={updateContactFormIsLoading || Object.keys(errors).length > 0}
            >
              {updateContactFormIsLoading ? (
                <CircularProgress size="1rem" sx={{ mr: 2 }} />
              ) : (
                ""
              )}
              {t("update")}
            </Button>
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default Update;
