import { PaymentProductRouter } from "../subdomains/paymentProduct/routes/PaymentProductRouter";
import { PaymentProductDomainLayout } from "../layouts";
import { paymentProductDomainRoutes } from "./paymentProduct.base.route";
import { Route, Routes } from "react-router-dom";

export const PaymentProductDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<PaymentProductDomainLayout />}>
        <Route index element={<PaymentProductRouter />} />
        <Route
          path={`/${paymentProductDomainRoutes.subdomains.paymentProduct.base}/*`}
          element={<PaymentProductRouter />}
        />
        {/*
          // TODO: import subdomains
        */}
      </Route>
    </Routes>
  );
};
