import {
  IAuthorsInput,
  IAuthorsRequest,
  IAuthorsResponse,
  IAuthorsResult,
  authorQuery,
  authorsQuery,
  IAuthorInput,
  IAuthorRequest,
  IAuthorResponse,
  IAuthorResult,
  ICreateAuthorInput,
  ICreateAuthorRequest,
  ICreateAuthorResponse,
  ICreateAuthorResult,
  createAuthorQuery,
  IUpdateAuthorInput,
  IUpdateAuthorRequest,
  IUpdateAuthorResponse,
  IUpdateAuthorResult,
  updateAuthorQuery,
  IUpdateAuthorFilterInput,
  deleteAuthorQuery,
  IDeleteAuthorInput,
  IDeleteAuthorRequest,
  IDeleteAuthorResponse,
  IDeleteAuthorResult,
} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { authorTags } from "./author.tags";
import { IGraphqlVariables } from "corede-common";

export const authorApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    authors: builder.query<
      IAuthorsResult,
      IGraphqlVariables<IAuthorsInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IAuthorsRequest,
        IAuthorsResponse,
        IAuthorsResult,
        IAuthorsInput
      >({
        query: authorsQuery,
        providesTags: [authorTags.authors],
      })
    ),

    author: builder.query<
      IAuthorResult,
      IGraphqlVariables<IAuthorInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IAuthorRequest,
        IAuthorResponse,
        IAuthorResult,
        IAuthorInput
      >({
        query: authorQuery,
        providesTags: [authorTags.author],
      })
    ),

    // mutation
    createAuthor: builder.mutation<
      ICreateAuthorResult,
      IGraphqlVariables<ICreateAuthorInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICreateAuthorRequest,
        ICreateAuthorResponse,
        ICreateAuthorResult,
        ICreateAuthorInput
      >({
        query: createAuthorQuery,
        invalidatesTags: [authorTags.authors],
      })
    ),

    updateAuthor: builder.mutation<
      IUpdateAuthorResult,
      IGraphqlVariables<
        IUpdateAuthorInput,
        IUpdateAuthorFilterInput
      >
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUpdateAuthorRequest,
        IUpdateAuthorResponse,
        IUpdateAuthorResult,
        IUpdateAuthorInput,
        IUpdateAuthorFilterInput
      >({
        query: updateAuthorQuery,
        invalidatesTags: [
          authorTags.authors,
          authorTags.author,
        ],
      })
    ),

    deleteAuthor: builder.mutation<
      IDeleteAuthorResult,
      IGraphqlVariables<IDeleteAuthorInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IDeleteAuthorRequest,
        IDeleteAuthorResponse,
        IDeleteAuthorResult,
        IDeleteAuthorInput
      >({
        query: deleteAuthorQuery,
        invalidatesTags: [
          authorTags.authors,
          authorTags.author,
        ],
      })
    ),
  }),
});

export const {
  useAuthorsQuery,
  useLazyAuthorsQuery,

  useAuthorQuery,
  useLazyAuthorQuery,

  useCreateAuthorMutation,
  useUpdateAuthorMutation,
  useDeleteAuthorMutation,
} = authorApi;
