

import { commonRoutes } from "routes/route.common";

export const paymentProductDomainRoutes = {
  base: "paymentProduct",
  subdomains: {
    paymentProduct: {
      base: "paymentProducts",
    },
    // add subdomain route names here
  },
};

// PaymentProduct routes
export const basePaymentProductRoute = (): string => `/${paymentProductDomainRoutes.base}/`;
export const listPaymentProductRoute = (): string =>
  `/${paymentProductDomainRoutes.base}/${paymentProductDomainRoutes.subdomains.paymentProduct.base}`;
export const viewPaymentProductRoute = (id: string): string =>
  `/${paymentProductDomainRoutes.base}/${paymentProductDomainRoutes.subdomains.paymentProduct.base}/${id}`;
export const createPaymentProductRoute = (): string =>
  `/${paymentProductDomainRoutes.base}/${paymentProductDomainRoutes.subdomains.paymentProduct.base}/${commonRoutes.create}`;
export const updatePaymentProductRoute = (id: string): string =>
  `/${paymentProductDomainRoutes.base}/${paymentProductDomainRoutes.subdomains.paymentProduct.base}/${id}/${commonRoutes.update}`;

// PaymentProduct routes

// add subdomain routes here
