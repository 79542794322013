import {
  IHelpCenterCategoryListInput,
  IHelpCenterCategoryListRequest,
  IHelpCenterCategoryListResponse,
  IHelpCenterCategoryListResult,
  helpCenterCategoryDetailQuery,
  helpCenterCategoryListQuery,
  IHelpCenterCategoryDetailInput,
  IHelpCenterCategoryDetailRequest,
  IHelpCenterCategoryDetailResponse,
  IHelpCenterCategoryDetailResult,
  IHelpCenterCategoryCreateInput,
  IHelpCenterCategoryCreateRequest,
  IHelpCenterCategoryCreateResponse,
  IHelpCenterCategoryCreateResult,
  helpCenterCategoryCreateQuery,
  IHelpCenterCategoryUpdateInput,
  IHelpCenterCategoryUpdateRequest,
  IHelpCenterCategoryUpdateResponse,
  IHelpCenterCategoryUpdateResult,
  helpCenterCategoryUpdateQuery,
  IHelpCenterCategoryUpdateFilterInput,
  helpCenterCategoryDeleteQuery,
  IHelpCenterCategoryDeleteInput,
  IHelpCenterCategoryDeleteRequest,
  IHelpCenterCategoryDeleteResponse,
  IHelpCenterCategoryDeleteResult,

} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { helpCenterCategoryTags } from "./helpCenterCategory.tags";
import { IGraphqlVariables } from "corede-common";

export const helpCenterCategoryApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    helpCenterCategories: builder.query<
      IHelpCenterCategoryListResult,
      IGraphqlVariables<IHelpCenterCategoryListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IHelpCenterCategoryListRequest,
        IHelpCenterCategoryListResponse,
        IHelpCenterCategoryListResult,
        IHelpCenterCategoryListInput
      >({
        query: helpCenterCategoryListQuery,
        providesTags: [helpCenterCategoryTags.helpCenterCategories],
      })
    ),

    helpCenterCategory: builder.query<
      IHelpCenterCategoryDetailResult,
      IGraphqlVariables<IHelpCenterCategoryDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IHelpCenterCategoryDetailRequest,
        IHelpCenterCategoryDetailResponse,
        IHelpCenterCategoryDetailResult,
        IHelpCenterCategoryDetailInput
      >({
        query: helpCenterCategoryDetailQuery,
        providesTags: [helpCenterCategoryTags.helpCenterCategory],
      })
    ),

    // mutation
    createHelpCenterCategory: builder.mutation<
      IHelpCenterCategoryCreateResult,
      IGraphqlVariables<IHelpCenterCategoryCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IHelpCenterCategoryCreateRequest,
        IHelpCenterCategoryCreateResponse,
        IHelpCenterCategoryCreateResult,
        IHelpCenterCategoryCreateInput
      >({
        query: helpCenterCategoryCreateQuery,
        invalidatesTags: [helpCenterCategoryTags.helpCenterCategories],
      })
    ),

    updateHelpCenterCategory: builder.mutation<
      IHelpCenterCategoryUpdateResult,
      IGraphqlVariables<
        IHelpCenterCategoryUpdateInput,
        IHelpCenterCategoryUpdateFilterInput
      >
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IHelpCenterCategoryUpdateRequest,
        IHelpCenterCategoryUpdateResponse,
        IHelpCenterCategoryUpdateResult,
        IHelpCenterCategoryUpdateInput,
        IHelpCenterCategoryUpdateFilterInput
      >({
        query: helpCenterCategoryUpdateQuery,
        invalidatesTags: [
          helpCenterCategoryTags.helpCenterCategories,
          helpCenterCategoryTags.helpCenterCategory,
        ],
      })
    ),

    deleteHelpCenterCategory: builder.mutation<
      IHelpCenterCategoryDeleteResult,
      IGraphqlVariables<IHelpCenterCategoryDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IHelpCenterCategoryDeleteRequest,
        IHelpCenterCategoryDeleteResponse,
        IHelpCenterCategoryDeleteResult,
        IHelpCenterCategoryDeleteInput
      >({
        query: helpCenterCategoryDeleteQuery,
        invalidatesTags: [
          helpCenterCategoryTags.helpCenterCategories,
          helpCenterCategoryTags.helpCenterCategory,
        ],
      })
    ),
  }),
});

export const {
  useHelpCenterCategoriesQuery,
  useLazyHelpCenterCategoriesQuery,

  useHelpCenterCategoryQuery,
  useLazyHelpCenterCategoryQuery,

  useCreateHelpCenterCategoryMutation,
  useUpdateHelpCenterCategoryMutation,
  useDeleteHelpCenterCategoryMutation,
} = helpCenterCategoryApi;
