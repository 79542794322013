import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Autocomplete,
  Card,
  Chip,
  Box,
  Typography,
} from "@mui/material";
import {
  useUpdateBlogMutation,
  useBlogQuery,
  useBlogImageAssignMutation,
} from "../context/blog.api";
import { useFormik } from "formik";
import { useAppDispatch } from "context";
import { blogActions } from "../../../context";
import { IUpdateBlogInput } from "corede-common-cocrm";
import { FileContentType, IBaseError, IEntity, IFileMetadata, IGraphqlVariables, Language } from "corede-common";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { t } from "i18next";
import { useParams, useNavigate } from "react-router-dom";
import { validateUpdateBlogInput } from "../validations/update.validation";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { listBlogRoute } from "../../../routes/blog.base.route";
import { useAuthorsQuery } from "../../author";
import { useBlogCategoriesQuery } from "../../blogCategory";
import ReactQuill from "react-quill";
import { useBlogTargetCategoriesQuery } from "../../blogTargetCategory";
import { UploadBlogImage } from "components";

const Update = () => {

  // general
  const dispatch = useAppDispatch();
  const currentLanguage = getCurrentLanguage();
  const [imageFile, setImageFile] = useState<IFileMetadata | null>(null);

  const [tagValue, setTagValue] = useState('');
  const [referenceValue, setReferenceValue] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();
  if (!id) {
    navigate(listBlogRoute());
  }

  const [loading, setLoading] = useState(false);

  // queries
  const {
    data: blogData,
    isLoading: blogIsLoading,
    error: blogError,
  } = useBlogQuery({ input: { _id: id! } });

  const [blogImageAssignMutation] = useBlogImageAssignMutation();
  const {
    data: authorsData,
    isLoading: authorsIsLoading,
    error: authorsError,
  } = useAuthorsQuery({});
  const {
    data: blogCategoriesData,
    isLoading: blogCategoriesIsLoading,
    error: blogCategoriesError,
  } = useBlogCategoriesQuery({});
  const {
    data: blogTargetCategoriesData,
    isLoading: blogTargetCategoriesIsLoading,
    error: blogTargetCategoriesError,
  } = useBlogTargetCategoriesQuery({});


  // mutations
  const [
    updateBlog,
    {
      data: updateBlogData,
      isLoading: updateBlogIsLoading,
      error: updateBlogError,
    },
  ] = useUpdateBlogMutation();

  // constants
  const {
    values,
    handleSubmit,
    handleBlur,
    setFieldValue,
    errors,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      input: {
        title: "",
        content: "",
        description: "",
        readingTime: "",
        tags: [],
        references: [],
        relatedVideo: "",
        authorId: "",
        categoryId: "",
        targetCategoryId: "",
        language: currentLanguage,
      },
    },
    enableReinitialize: true,
    validationSchema: validateUpdateBlogInput,
    onSubmit: async (values: IGraphqlVariables<IUpdateBlogInput>) => {
      setLoading(true);

      if (imageFile) {
        await blogImageAssignMutation({
          input: {
            _id: id!,
            file: imageFile
          }
        });
      }


      updateBlog({
        filter: { _id: id! },
        input: {
          ...values.input,
        }
      }).then(() => {
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      })


    },
  });



  const handleKeyDownTag = (event: any) => {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault();
      const value = tagValue.trim();
      if (value && !(values as any).input.tags.includes(value)) {
        setFieldValue("input.tags", [...(values as any).input.tags, value]);
        setTagValue('');
      }
    }
  };

  const handleKeyDownReference = (event: any) => {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault();
      const value = referenceValue.trim();
      if (value && !(values as any).input.references.includes(value)) {
        setFieldValue("input.references", [...(values as any).input.references, value]);
        setReferenceValue('');
      }
    }
  }

  const handleDeleteTag = (tagToDelete: string) => {
    setFieldValue("input.tags", (values as any).input.tags.filter((tag: string) => tag !== tagToDelete));
  };

  const handleDeleteReference = (referenceToDelete: string) => {
    setFieldValue("input.references", (values as any).input.references.filter((reference: string) => reference !== referenceToDelete));
  };


  // useEffects.success
  useEffect(() => {
    console.log(blogData);
    if (blogData) {
      setValues({
        input: {
          title: blogData.title,
          content: blogData.content,
          description: blogData.description,
          readingTime: blogData.readingTime,
          tags: blogData.tags,
          references: blogData.references,
          relatedVideo: blogData.relatedVideo,
          authorId: blogData.author?._id,
          categoryId: blogData.category?._id,
          targetCategoryId: blogData.targetCategory?._id,
          language: blogData.language,
        },
      });
    }
  }, [blogData, setValues]);

  useEffect(() => {
    if (updateBlogData) {
      resetForm();
      enqueueSnackbar(t("main.blog.blog.updatedSuccessfully"), {
        variant: "success",
      });
    }
  }, [updateBlogData, resetForm]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      updateBlogError as IBaseError,
      currentLanguage
    );
  }, [updateBlogError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      blogError as IBaseError,
      currentLanguage
    );
  }, [blogError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      authorsError as IBaseError,
      currentLanguage
    );
  }, [authorsError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      blogCategoriesError as IBaseError,
      currentLanguage
    );
  }, [blogCategoriesError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    dispatch(blogActions.setTitle(t("main.blog.blog.update")));
    dispatch(blogActions.setBackButton(true));
    dispatch(blogActions.setRightButton(null));
  }, [dispatch]);



  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2}>
        {blogIsLoading ? (
          <CircularProgress />
        ) : (
          <Stack direction="column" gap={2}>
            <TextField
              fullWidth
              name="input.title"
              label={t("main.blog.blog.title")}
              value={(values as any).input.title}
              onChange={(e) => setFieldValue("input.title", e.target.value)}
              onBlur={() => handleBlur("input.title")}
              error={!!(errors as any).input?.title}
              helperText={(errors as any).input?.title}
            />
            <TextField
              fullWidth
              id="content"
              multiline
              rows={2}
              name="input.content"
              label={t("main.blog.blog.content")}
              value={(values as any).input.content}
              onChange={(e) => setFieldValue("input.content", e.target.value)}
              onBlur={() => handleBlur("input.content")}
              error={!!(errors as any).input?.content}
              helperText={(errors as any).input?.content}
            />
            <ReactQuill
              theme="snow"
              value={(values as any).input.description}
              onChange={(e) => setFieldValue("input.description", e)}
              onBlur={() => handleBlur("input.description")}
              placeholder={t("main.blog.blog.description")}
              scrollingContainer={'.ql-editor'}
              style={{ height: 300, marginBottom: 40, paddingBottom: 10 }}
            />
            <Box
              sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
            >
              <img src={imageFile ? imageFile?.publicUrl : blogData?.image?.publicUrl} alt="Blog" style={{ width: "100%", height: "300px", objectFit: "cover", borderRadius:"20px" }} />
            </Box>
            <UploadBlogImage
              setImageFile={setImageFile}
            />

            <TextField
              fullWidth
              id="readingTime"
              name="input.readingTime"
              label={t("main.blog.blog.readingTime")}
              value={(values as any).input.readingTime}
              onChange={(e) => setFieldValue("input.readingTime", e.target.value)}
              onBlur={() => handleBlur("input.content")}
              error={!!(errors as any).input?.content}
              helperText={(errors as any).input?.content}
            />
            <Card sx={{ padding: 2 }}>
              <Stack direction="row" gap={1} alignItems={"center"} flexWrap={"wrap"} mb={2}>
                {t("main.blog.blog.tags")}:
                {(values as any).input.tags.map((tag: any, index: any) => (
                  <Chip
                    key={index}
                    label={tag}
                    onDelete={() => handleDeleteTag(tag)}
                    style={{ margin: '4px' }}
                  />
                ))}
              </Stack>
              <TextField
                value={tagValue}
                onChange={(e) => setTagValue(e.target.value)}
                onKeyDown={handleKeyDownTag}
                label={t("main.blog.blog.addTags")}
                size="small"
                variant="outlined"
                fullWidth
                placeholder={t("main.blog.blog.pleaseEnterTags")}
              />
            </Card>
            <Card sx={{ padding: 2 }}>
              <Stack direction="row" gap={1} alignItems={"center"} flexWrap={"wrap"} mb={2}>
                {t("main.blog.blog.references")}:
                {(values as any).input.references.map((reference: any, index: any) => (
                  <Chip
                    key={index}
                    label={reference}
                    onDelete={() => handleDeleteReference(reference)}
                    style={{ margin: '4px' }}
                  />
                ))}
              </Stack>
              <TextField
                value={referenceValue}
                size="small"
                onChange={(e) => setReferenceValue(e.target.value)}
                onKeyDown={handleKeyDownReference}
                label={t("main.blog.blog.addReferences")}
                variant="outlined"
                fullWidth
                placeholder={t("main.blog.blog.pleaseEnterReferences")}
              />
            </Card>
            <TextField
              fullWidth
              name="input.relatedVideo"
              label={t("main.blog.blog.relatedVideo")}
              value={(values as any).input.relatedVideo}
              onChange={(e) => setFieldValue("input.relatedVideo", e.target.value)}
              onBlur={() => handleBlur("input.relatedVideo")}
              error={!!(errors as any).input?.relatedVideo}
              helperText={(errors as any).input?.relatedVideo}
            />
            <Autocomplete
              id="authorId"
              isOptionEqualToValue={(option, value) => option._id === value._id}
              getOptionLabel={(option) => option.name}
              options={authorsData?.data || []}
              loading={authorsIsLoading}
              renderInput={(params) => <TextField {...params} label={t("main.blog.blog.author")}
                error={!!(errors as any).input?.authorId}
                helperText={(errors as any).input?.authorId}
              />}
              value={authorsData?.data?.find((category) => category._id === (values as any).input.authorId) || null}
              onChange={(e, value) => {
                setFieldValue("input.authorId", value?._id);
              }}
              onBlur={() => handleBlur("input.authorId")}
            />
            <Autocomplete
              id="categoryId"
              isOptionEqualToValue={(option, value) => option._id === value._id}
              getOptionLabel={(option) => option.name}
              options={blogCategoriesData?.data || []}
              loading={blogCategoriesIsLoading}
              renderInput={(params) => <TextField {...params} label={t("main.blog.blog.category")}
                error={!!(errors as any).input?.categoryId}
                helperText={(errors as any).input?.categoryId}
              />}
              value={blogCategoriesData?.data?.find((category) => category._id === (values as any).input.categoryId) || null}
              onChange={(e, value) => {
                setFieldValue("input.categoryId", value?._id);
              }}
              onBlur={() => handleBlur("input.categoryId")}
            />
            <Autocomplete
              id="targetCategoryId"
              isOptionEqualToValue={(option, value) => option._id === value._id}
              getOptionLabel={(option) => option.name}
              options={blogTargetCategoriesData?.data || []}
              loading={blogTargetCategoriesIsLoading}
              renderInput={(params) => <TextField {...params} label={t("main.blog.blog.targetCategory")}
                error={!!(errors as any).input?.targetCategoryId}
                helperText={(errors as any).input?.targetCategoryId}
              />}
              value={blogTargetCategoriesData?.data?.find((category) => category._id === (values as any).input.targetCategoryId) || null}
              onChange={(e, value) => {
                setFieldValue("input.targetCategoryId", value?._id);
              }}
              onBlur={() => handleBlur("input.targetCategoryId")}
            />
            <Autocomplete
              id="language"
              isOptionEqualToValue={(option, value) => option === value}
              getOptionLabel={(option) => option}
              options={Object.values(Language)}
              renderInput={(params) => <TextField {...params} label={t("main.blog.blog.language")}
                error={!!(errors as any).input?.language}
                helperText={(errors as any).input?.language}
              />}
              value={(values as any).input.language}
              onChange={(e, value) => {
                setFieldValue("input.language", value);
              }}
              onBlur={() => handleBlur("input.language")}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
              disabled={updateBlogIsLoading || authorsIsLoading || loading || !(values as any).input.title || !(values as any).input.content || !(values as any).input.readingTime || !(values as any).input.tags || !(values as any).input.authorId || !(values as any).input.categoryId || !(values as any).input.targetCategoryId || !(values as any).input.language}
            >
              {(updateBlogIsLoading || loading) ? (
                <CircularProgress size="1rem" sx={{ mr: 2 }} />
              ) : (
                ""
              )}
              {t("update")}
            </Button>
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default Update;
