import notificationLocale from "../subdomains/notification/locales/en";
import webNotificationLocale from "../subdomains/webNotification/locales/en";

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////

  notification: {
    ...notificationLocale,
  },
  webNotification: {
    ...webNotificationLocale,
  },
};

export default locale;
