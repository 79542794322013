import {
  Grid,
  List as ListMui,
  Stack,
  ListItem,
  Button,
  Skeleton,
  Typography,
  Box,
  Autocomplete,
  TextField,
  Chip
} from "@mui/material";
import { useAppDispatch } from "context";
import { blogActions } from "../../../context";
import {
  createBlogRoute,
  updateBlogRoute,
  viewBlogRoute,
} from "../../../routes/blog.base.route";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useBlogsQuery } from "../context/blog.api";
import { Delete as DeleteIcon, Edit, RemoveRedEye } from "@mui/icons-material";
import { MyTablePagination } from "components";
import { IBaseError, IGraphqlVariables, Language, TNullable } from "corede-common";
import {
  IBaseBlogEntity,
  IBlogsInput,
} from "corede-common-cocrm";
import { getCurrentLanguage } from "localization";
import { useNavigate } from "react-router-dom";
import Delete from "./Delete";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { useAuthorsQuery, useLazyAuthorsQuery } from "../../author";
import { useBlogCategoriesQuery } from "../../blogCategory";
import { useBlogTargetCategoriesQuery } from "../../blogTargetCategory";
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from "dayjs";

const List = () => {
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();

  // useStates
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 0,
  });

  const [selectedItemForDelete, setSelectedItemForDelete] =
    useState<TNullable<IBaseBlogEntity>>(null);
  const [variables, setVariables] = useState<
    IGraphqlVariables<IBlogsInput>
  >({
    input: {
      filter: {
        authorIds: undefined,
        categoryIds: undefined,
        languages: undefined,
        targetCategoryIds: undefined,
        title: undefined,
        updatedAtDateFilter: undefined,
      },
      pagination: {
        pageSize: pagination.pageSize,
        page: pagination.page + 1,
      },
    },
  });

  // queries
  const {
    data: blogsData,
    isLoading: blogsIsLoading,
    error: blogsError,
    refetch: refetchBlogs,
  } = useBlogsQuery(variables);

  const [blogsLazyQuery] = useLazyAuthorsQuery();

  const {
    data: authorsData,
    isLoading: authorsIsLoading,
    error: authorsError,
  } = useAuthorsQuery({});

  const {
    data: categoriesData,
    isLoading: categoriesIsLoading,
    error: categoriesError,
  } = useBlogCategoriesQuery({});

  const {
    data: targetCategoriesData,
    isLoading: targetCategoriesIsLoading,
    error: targetCategoriesError,
  } = useBlogTargetCategoriesQuery({});



  // mutations

  // constants

  // action handlers
  const handleEdit = (id: string) => {
    navigate(updateBlogRoute(id));
  };

  const handleDelete = (item: IBaseBlogEntity) => {
    setSelectedItemForDelete(item);
  };

  const handleView = (id: string) => {
    navigate(viewBlogRoute(id));
  };

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      blogsError as IBaseError,
      currentLanguage
    );
  }, [blogsError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      authorsError as IBaseError,
      currentLanguage
    );
  }, [authorsError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      categoriesError as IBaseError,
      currentLanguage
    );
  }, [categoriesError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      targetCategoriesError as IBaseError,
      currentLanguage
    );
  }, [targetCategoriesError, currentLanguage]);


  // useEffects.init
  useEffect(() => {
    setVariables({
      input: {
        pagination: {
          pageSize: pagination.pageSize,
          page: pagination.page + 1,
        },
      },
    });
  }, [pagination]);

  useEffect(() => {
    dispatch(blogActions.setTitle(t("main.blog.blog.blogs")));
    dispatch(blogActions.setBackButton(false));
    dispatch(
      blogActions.setRightButton({
        title: t(`main.blog.blog.create`),
        path: createBlogRoute(),
        icon: null,
      })
    );
  }, [dispatch]);

  console.log(variables);

  return (
    <Grid item xs={12}>


      {/* Filters */}

      <Stack direction={"column"} gap={2} mb={2} border={"1px solid #CDCFD1"} borderRadius={2} p={2}>
        <Stack direction="row" gap={2}>
          <Typography variant="h5">{t("main.blog.blog.filters")}</Typography>
          <Stack direction="row" gap={1}>
            {
              Object.values(Language).map((language) => (
                <Chip
                  key={language}
                  label={language}
                  variant={variables.input?.filter?.languages?.[0] === language ? "filled" : "outlined"}
                  onClick={() => {
                    setVariables({
                      ...variables,
                      input: {
                        ...variables.input,
                        filter: {
                          ...(variables.input?.filter || {}),
                          languages: variables.input?.filter?.languages?.[0] === language ? undefined : [language],
                        },
                      },
                    });
                  }}
                />
              ))
            }
          </Stack>
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} gap={2}>
          <Autocomplete
            id="authorId"
            isOptionEqualToValue={(option, value) => option._id === value._id}
            fullWidth
            getOptionLabel={(option) => option.name}
            options={authorsData?.data || []}
            loading={authorsIsLoading}
            renderInput={(params) => <TextField {...params} label={t("main.blog.blog.author")} />}
            multiple
            value={
              variables.input?.filter?.authorIds && variables.input.filter.authorIds.length > 0
                ? authorsData?.data?.filter((author) => variables?.input?.filter?.authorIds?.includes(author._id))
                : undefined
            }
            onChange={(e, value) => {
              setVariables({
                ...variables,
                input: {
                  ...variables.input,
                  filter: {
                    ...(variables.input?.filter || {}),
                    authorIds: value?.length > 0 ? value.map((author) => author._id) : undefined,
                  },
                },
              });
            }}
          />


          <Autocomplete
            id="categoryId"
            isOptionEqualToValue={(option, value) => option._id === value._id}
            fullWidth
            getOptionLabel={(option) => option.name}
            options={categoriesData?.data || []}
            loading={categoriesIsLoading}
            renderInput={(params) => <TextField {...params} label={t("main.blog.blog.category")} />}
            multiple
            value={
              variables.input?.filter?.categoryIds && variables.input.filter.categoryIds.length > 0
                ? categoriesData?.data?.filter((category) => variables?.input?.filter?.categoryIds?.includes(category._id))
                : undefined
            }
            onChange={(e, value) => {
              setVariables({
                ...variables,
                input: {
                  ...variables.input,
                  filter: {
                    ...(variables.input?.filter || {}),
                    categoryIds: value?.length > 0 ? value.map((category) => category._id) : undefined,
                  },
                },
              });
            }}
          />

          <Autocomplete
            id="targetCategoryId"
            isOptionEqualToValue={(option, value) => option._id === value._id}
            fullWidth
            getOptionLabel={(option) => option.name}
            options={targetCategoriesData?.data || []}
            loading={targetCategoriesIsLoading}
            renderInput={(params) => <TextField {...params} label={t("main.blog.blog.targetCategory")} />}
            multiple
            value={
              variables.input?.filter?.targetCategoryIds && variables.input.filter.targetCategoryIds.length > 0
                ? targetCategoriesData?.data?.filter((targetCategory) => variables?.input?.filter?.targetCategoryIds?.includes(targetCategory._id))
                : undefined
            }
            onChange={(e, value) => {
              setVariables({
                ...variables,
                input: {
                  ...variables.input,
                  filter: {
                    ...(variables.input?.filter || {}),
                    targetCategoryIds: value?.length > 0 ? value.map((targetCategory) => targetCategory._id) : undefined,
                  },
                },
              });
            }}
          />

        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} gap={2}>



          <TextField
            fullWidth
            label={t("main.blog.blog.title")}
            value={variables.input?.filter?.title || ""}
            onChange={(e) => {
              setVariables({
                ...variables,
                input: {
                  ...variables.input,
                  filter: {
                    ...(variables.input?.filter || {}),
                    title: e.target.value,
                  },
                },
              });
            }}
          />


          <DateRangePicker
            sx={{ width: "100%" }}
            label={t("main.blog.blog.updatedAtDateFilter")}
            value={variables.input?.filter?.updatedAtDateFilter ?
              [dayjs(variables.input.filter.updatedAtDateFilter.from),
              dayjs(variables.input.filter.updatedAtDateFilter.to)]
              : undefined}
            onChange={(e) => {
              setVariables({
                ...variables,
                input: {
                  ...variables.input,
                  filter: {
                    ...(variables.input?.filter || {}),
                    updatedAtDateFilter: {
                      from: e?.[0]?.toDate() || undefined,
                      to: e?.[1]?.toDate() || undefined,
                    }
                  },
                },
              });
            }}
          />



        </Stack>
      </Stack>


      <Stack direction="row" justifyContent="space-between">
        <ListMui
          sx={{ width: "100%", bgcolor: "background.paper" }}
          component="nav"
          aria-label="main mailbox folders"
        >
          <Grid container spacing={2}>
            {!blogsIsLoading
              ? blogsData?.data?.map((blog, index) => (
                <Grid item xs={12} sm={6} lg={4} key={blog._id}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems={"flex-start"}
                    p={2}
                    sx={{
                      bgcolor: "#f1f1f1",
                      borderRadius: 2,
                    }}
                  >
                    <Stack direction="column" gap={1} alignItems={"center"}>
                      <Box width={1} height={170} sx={{ overflow: "hidden" }} borderRadius={2}>
                        <img
                          src={blog?.image?.thumbnailPublicUrl || blog?.image?.publicUrl}
                          alt={blog?.title}
                          style={{ width: '100%', height:"160px", objectFit:"cover", borderRadius: 20 }} />
                      </Box>
                      <Typography variant="h4">{blog?.title.substring(0, 35)}...</Typography>
                      <Typography height={50} variant="body2">{blog?.content.substring(0, 80)}...</Typography>
                      <Stack direction="row" gap={1} mt={1} justifyContent="flex-start" alignItems="flex-start">
                        <Button
                          onClick={() => handleView(blog._id)}
                          variant="outlined"
                          size="small"
                          sx={{ height: 32, minWidth: 10 }}
                        >
                          <RemoveRedEye sx={{ fontSize: "16px" }} />
                        </Button>
                        <Button
                          onClick={() => handleEdit(blog._id)}
                          variant="outlined"
                          size="small"
                          sx={{ height: 32, minWidth: 10 }}
                        >
                          <Edit sx={{ fontSize: "16px" }} />
                        </Button>
                        <Button
                          onClick={() => handleDelete(blog)}
                          variant="outlined"
                          size="small"
                          color="error"
                          sx={{ height: 32, minWidth: 10 }}
                        >
                          <DeleteIcon sx={{ fontSize: "16px" }} />
                        </Button>
                      </Stack>
                    </Stack>

                  </Stack>
                </Grid>
              ))
              : [1, 2, 3].map((key) => (
                <Grid item xs={12} sm={6} lg={4} key={key}>
                  <Skeleton
                    variant="rectangular"
                    width={'100%'}
                    height={180}
                    sx={{ borderRadius: 2 }}
                  />
                </Grid>
              ))}
          </Grid>
        </ListMui>
      </Stack>

      <Stack
        direction="row"
        justifyContent={{ xs: "center", md: "center" }}
        mt={2}
        mb={2}
      >
        <MyTablePagination
          count={blogsData?.count ?? 0}
          pageSize={pagination.pageSize}
          page={pagination.page ?? 0}
          onPageChange={(e, page) => {
            setPagination({
              ...pagination,
              page: page,
            });
          }}
          onRowsPerPageChange={(e) => {
            setPagination({
              page: 0,
              pageSize: parseInt(e.target.value)
            });
          }}
        />
      </Stack>

      <Delete
        open={!!selectedItemForDelete}
        item={selectedItemForDelete!}
        onClose={() => {
          setSelectedItemForDelete(null);
        }}
      />
    </Grid >
  );
};

export default List;
