import { createTheme, styled } from '@mui/material/styles';
import { trTR } from '@mui/material/locale';
import { alpha } from '@mui/material/styles';




const GREY = {
    0: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#637381',
    700: '#454F5B',
    800: '#212B36',
    900: '#161C24',
};

const PRIMARY = {
    lighter: '#E4F1F6',
    light: '#9FBACA',
    main: '#33424F',
    dark: '#192738',
    darker: '#091225',
    contrastText: '#FFFFFF',
};


const SECONDARY = {
    lighter: '#FFECCC',
    light: '#FFB566',
    main: '#FF6600',
    dark: '#B73300',
    darker: '#7A1400',
    contrastText: '#FFFFFF',
};

const INFO = {
    lighter: '#CFE4FB',
    light: '#70A1E9',
    main: '#194EB7',
    dark: '#0C2C83',
    darker: '#041557',
    contrastText: '#FFFFFF',
};

const MAIN = {
    lighter: '#CFE4FB',
    light: '#70A1E9',
    main: '#194EB7',
    dark: '#0C2C83',
    darker: '#041557',
    contrastText: '#FFFFFF',
};


const SUCCESS = {
    lighter: '#DDF8D1',
    light: '#80D771',
    main: '#1A7C1D',
    dark: '#0D591C',
    darker: '#043B19',
    contrastText: '#ffffff',
};

const WARNING = {
    lighter: '#FCEFC9',
    light: '#EDBE5F',
    main: '#C47301',
    dark: '#8D4600',
    darker: '#5E2700',
    contrastText: GREY[100],
};


const ERROR = {
    lighter: '#F9CFD1',
    light: '#DF6D8A',
    main: '#961650',
    dark: '#6C0B4A',
    darker: '#48043E',
    contrastText: '#FFFFFF',
};


const ALTERNATIVE1 = {
    lighter: '#bbb',
    light: '#aaa',
    main: '#999',
    dark: '#888',
    darker: '#777',
    contrastText: '#FFFFFF',
};

const ALTERNATIVE2 = {
    lighter: '#bbb',
    light: '#aaa',
    main: '#ccc',
    dark: '#888',
    darker: '#777',
    contrastText: '#FFFFFF',
};


const COMMON = {
    common: {
        black: '#000000',
        white: '#FFFFFF',
    },
    primary: PRIMARY,
    secondary: SECONDARY,
    info: INFO,
    main: MAIN,
    success: SUCCESS,
    warning: WARNING,
    error: ERROR,
    grey: GREY,
    alternative1: ALTERNATIVE1,
    alternative2: ALTERNATIVE2,
    divider: alpha(GREY[500], 0.2),
    action: {
        hover: alpha(GREY[500], 0.08),
        selected: alpha(GREY[500], 0.16),
        disabled: alpha(GREY[500], 0.8),
        disabledBackground: alpha(GREY[500], 0.24),
        focus: alpha(GREY[500], 0.24),
        hoverOpacity: 0.08,
        disabledOpacity: 0.48,
    },
};


export const theme = createTheme(
    {
        palette: {
            ...COMMON,
            mode: 'light',
            text: {
                primary: GREY[800],
                secondary: GREY[600],
                disabled: GREY[500],
            },
            background: {
                paper: '#FFFFFF',
                default: '#FFFFFF',
            },
            action: {
                ...COMMON.action,
                active: GREY[600],
            },
        },
        shape: {
            borderRadius: 8,
        },
        typography: {
            fontFamily: 'Lexend',
            h1: {
                fontSize: '2.5rem',
                fontWeight: 700,
            },
            h2: {
                fontSize: '2rem',
                fontWeight: 700,
            },
            h3: {
                fontSize: '1.75rem',
                fontWeight: 700,
            },
            h4: {
                fontSize: '1.5rem',
                fontWeight: 700,
            },
            h5: {
                fontSize: '1.25rem',
                fontWeight: 700,
            },
            h6: {
                fontSize: '1rem',
                fontWeight: 700,
            },
            subtitle1: {
                fontSize: '1rem',
                fontWeight: 400,
            },
            subtitle2: {
                fontSize: '0.875rem',
                fontWeight: 400,
            },
            body1: {
                fontSize: '1rem',
                fontWeight: 400,
            },
            body2: {
                fontSize: '0.875rem',
                fontWeight: 400,
            },
            button: {
                fontSize: '1rem',
                fontWeight: 700,
            },
            caption: {
                fontSize: '0.75rem',
                fontWeight: 400,
            },
            overline: {
                fontSize: '0.75rem',
                fontWeight: 700,
            },
        },
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        backgroundColor: GREY[200],
                        borderRadius: 18,
                        borderColor: 'red'
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        backgroundColor: GREY[200],
                        borderRadius: 18,
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        borderRadius: 18,
                        borderColor: GREY[200],
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: 18,
                        height: 56,
                        capitalize: false,
                        textTransform: 'none',
                        fontSize: '1rem',
                        boxShadow: 'none',
                    },
                },
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.05)',
                        padding: 10,
                        borderRadius: 18,
                        // maskImage: 'paint(smooth-corners)',
                        // WebkitMaskImage: 'paint(smooth-corners)',
                        // '--smooth-corners': 25.89,
                    },
                },
            },
            MuiSnackbarContent: {
                styleOverrides: {
                    root: {
                        borderRadius: 18,
                        boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.05)',
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                    },
                },
            },

        },
    },
    trTR,
);

