const locale = {
  helpCenter: "Help Center",
  helpCenters: "Help Centers",
  create: "Create Help Center",
  update: "Update Help Center",
  delete: "Delete Help Center",
  view: "View Help Center",
  list: "List Help Centers",
  createdSuccessfully: "Help Center created successfully",
  updatedSuccessfully: "Help Center updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Help Center deleted successfully",
  question: "Question",
  answer: "Answer",
  readingTime: "Reading Time",
  category: "Category",
  videoAttachment: "Video Attachment",
  language: "Language",
  filters: "Filters",
};

export default locale;
