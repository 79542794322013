
import { ThemeProvider, styled } from '@mui/material/styles';
import { Provider } from 'react-redux'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'


// import ThemeProvider from 'minimal/theme';
import Router from 'Router';
import { store } from 'context';
import { theme } from 'theme';
import { SnackbarProvider } from 'components';

const Wrapper = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
          <SnackbarProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Router />
            </LocalizationProvider>
          </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default Wrapper


