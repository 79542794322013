import { commonRoutes } from "routes/route.common";


export const formDomainRoutes = {
  base: "form",
  subdomains: {
    contactForm: {
      base: "contactForms",
    },
    subscriptionForm: {
      base: "subscriptionForms",
    },
    // add subdomain route names here
  },
};


// Form base routes
export const baseFormRoute = (): string => `/${formDomainRoutes.base}/`;

// ContactForm routes
export const baseContactFormRoute = (): string => `/${formDomainRoutes.base}/`;
export const listContactFormRoute = (): string =>
  `/${formDomainRoutes.base}/${formDomainRoutes.subdomains.contactForm.base}`;
export const viewContactFormRoute = (id: string): string =>
  `/${formDomainRoutes.base}/${formDomainRoutes.subdomains.contactForm.base}/${id}`;
export const updateContactFormRoute = (id: string): string =>
  `/${formDomainRoutes.base}/${formDomainRoutes.subdomains.contactForm.base}/${id}/${commonRoutes.update}`;

// SubscriptionForm routes
export const baseSubscriptionFormRoute = (): string => `/${formDomainRoutes.base}/`;
export const listSubscriptionFormRoute = (): string =>
  `/${formDomainRoutes.base}/${formDomainRoutes.subdomains.subscriptionForm.base}`;
export const viewSubscriptionFormRoute = (id: string): string =>
  `/${formDomainRoutes.base}/${formDomainRoutes.subdomains.subscriptionForm.base}/${id}`;

// add subdomain routes here
