


import { IMenuItem } from "interfaces/menu-item.interface";
import { getTranslatedText } from "localization";
import {
  baseContactFormRoute,
  listContactFormRoute,
  listRequestFormRoute,
} from "../routes/contactForm.base.route";

export const contactFormDomainMenuItem: IMenuItem = {
  title: getTranslatedText("contactForm"),
  path: baseContactFormRoute(),
  iconName: "fa6-brands:wpforms",
  children: [
    {
      title: getTranslatedText("contactForm"),
      path: listContactFormRoute(),
    },
    {
      title: getTranslatedText("requestForm"),
      path: listRequestFormRoute(),
    },
  ]
};
