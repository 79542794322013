import { commonRoutes } from "routes/route.common";

export const blogDomainRoutes = {
  base: "blog",
  subdomains: {
    blog: {
      base: "blogs",
    },
    blogCategory: {
      base: "categories",
    },
    author: {
      base: "author",
    },
    blogTargetCategory: {
      base: "blogTargetCategories",
    },
    // add subdomain route names here
  },
};


export const baseBlogRoute = (): string => `/${blogDomainRoutes.base}/`;
export const listBlogRoute = (): string =>
  `/${blogDomainRoutes.base}/${blogDomainRoutes.subdomains.blog.base}`;
export const viewBlogRoute = (id: string): string =>
  `/${blogDomainRoutes.base}/${blogDomainRoutes.subdomains.blog.base}/${id}`;
export const createBlogRoute = (): string =>
  `/${blogDomainRoutes.base}/${blogDomainRoutes.subdomains.blog.base}/${commonRoutes.create}`;
export const updateBlogRoute = (id: string): string =>
  `/${blogDomainRoutes.base}/${blogDomainRoutes.subdomains.blog.base}/${id}/${commonRoutes.update}`;

export const baseBlogCategoriesRoute = (): string => listBlogCategoriesRoute();
export const listBlogCategoriesRoute = (): string =>
  `/${blogDomainRoutes.base}/${blogDomainRoutes.subdomains.blogCategory.base}`;
export const viewBlogCategoryRoute = (id: string): string =>
  `/${blogDomainRoutes.base}/${blogDomainRoutes.subdomains.blogCategory.base}/${id}`;
export const createBlogCategoryRoute = (): string =>
  `/${blogDomainRoutes.base}/${blogDomainRoutes.subdomains.blogCategory.base}/${commonRoutes.create}`;
export const updateBlogCategoryRoute = (id: string): string =>
  `/${blogDomainRoutes.base}/${blogDomainRoutes.subdomains.blogCategory.base}/${id}/${commonRoutes.update}`;

export const baseAuthorRoute = (): string => listAuthorRoute();
export const listAuthorRoute = (): string =>
  `/${blogDomainRoutes.base}/${blogDomainRoutes.subdomains.author.base}`;
export const viewAuthorRoute = (id: string): string =>
  `/${blogDomainRoutes.base}/${blogDomainRoutes.subdomains.author.base}/${id}`;
export const createAuthorRoute = (): string =>
  `/${blogDomainRoutes.base}/${blogDomainRoutes.subdomains.author.base}/${commonRoutes.create}`;
export const updateAuthorRoute = (id: string): string =>
  `/${blogDomainRoutes.base}/${blogDomainRoutes.subdomains.author.base}/${id}/${commonRoutes.update}`;


// BlogTargetCategory routes
export const baseBlogTargetCategoryRoute = (): string => `/${blogDomainRoutes.base}/`;
export const listBlogTargetCategoryRoute = (): string =>
  `/${blogDomainRoutes.base}/${blogDomainRoutes.subdomains.blogTargetCategory.base}`;
export const viewBlogTargetCategoryRoute = (id: string): string =>
  `/${blogDomainRoutes.base}/${blogDomainRoutes.subdomains.blogTargetCategory.base}/${id}`;
export const createBlogTargetCategoryRoute = (): string =>
  `/${blogDomainRoutes.base}/${blogDomainRoutes.subdomains.blogTargetCategory.base}/${commonRoutes.create}`;
export const updateBlogTargetCategoryRoute = (id: string): string =>
  `/${blogDomainRoutes.base}/${blogDomainRoutes.subdomains.blogTargetCategory.base}/${id}/${commonRoutes.update}`;

// add subdomain routes here