

const locale = {
  faq: "Faq",
  faqs: "Faqs",
  create: "Create Faq",
  update: "Update Faq",
  delete: "Delete Faq",
  view: "View Faq",
  list: "List Faqs",
  createdSuccessfully: "Faq created successfully",
  updatedSuccessfully: "Faq updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Faq deleted successfully",
  category: "Category",
  targetCategory: "Target Category",
  language: "Language",
  pleaseEnterTags: "Please enter tags",
  title: "Title",
  content: "Content",
  description: "Description",
  tags: "Tags",
  question: "Question",
  answer: "Answer",
  readingTime: "Reading Time",
  filters: "Filters",
};

export default locale;

