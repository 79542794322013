import {
  IBaseGraphqlError,

  ILogoutInput,
  ILogoutResult,
  IRequestResetPasswordInput,
  IRequestResetPasswordResult,
  IConfirmRegistrationInput,
  IConfirmRegistrationResult,
  IRegisterByResult,
  IResendRegistrationByUserConfirmationInput,
  IResendRegistrationByUserConfirmationResult,
  IResetPasswordInput,
  IResetPasswordResult,
  IResetPasswordAfterRequestInput,
  IResetPasswordAfterRequestResult,
  IConfirmRegistrationByUserInput,
  IConfirmRegistrationByUserResult,
  IResendRegistrationByUserConfirmationRequest,
  IResendRegistrationConfirmationInput,
  logoutQuery,
  requestResetPasswordQuery,
  registerByQuery,
  confirmRegistrationQuery,
  resendRegistrationByUserConfirmationQuery,
  resetPasswordQuery,
  resetPasswordAfterRequestQuery,
  confirmRegistrationByUserQuery,
  resendRegistrationConfirmationQuery,
  ILogoutRequest,
  ILogoutResponse,
  IResetPasswordRequest,
  IResetPasswordResponse,
  IConfirmRegistrationRequest,
  IConfirmRegistrationResponse,
} from "corede-common";
import {
  IUserListInput,
  IUserListResponse,
  IUserListResult,
  userListQuery,
  IUserDetailOwnInput,
  IUserDetailOwnResponse,
  IUserDetailOwnResult,
  userDetailOwnQuery,
  
  IUserUpdateInput,
  IUserUpdateResponse,
  IUserUpdateResult,
  userUpdateQuery,
  
  ILoginInput,
  ILoginResponse,
  ILoginResult,
  ILoginRequest,
  loginQuery,
} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { print } from "graphql";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";

const authApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries

    users: builder.query<IUserListResult, IUserListInput>({
      query: (
        variables: IUserListInput & { input: { filter: { role?: string } } }
      ) => {
        return {
          url: "",
          body: JSON.stringify({
            query: print(userListQuery),
            variables: {
              input: {
                pagination: {
                  page: variables?.pagination?.page || 1,
                  pageSize: variables?.pagination?.pageSize || 10,
                  sort: variables?.pagination?.sort || undefined,
                },
                filter: {
                  role: "user",
                },
              },
            },
          }),
        };
      },
      transformResponse: (response: IUserListResponse): IUserListResult => {
        return response.data.userList;
      },
      transformErrorResponse: (response: IBaseGraphqlError) => {
        return response;
      },
      providesTags: ["users"],
    }),

    user: builder.query<IUserDetailOwnResult, IUserDetailOwnInput>({
      query: () => {
        return {
          url: "",
          body: JSON.stringify({
            query: print(userDetailOwnQuery),
          }),
        };
      },
      transformResponse: (response: IUserDetailOwnResponse): IUserDetailOwnResult => {
        return response.data.userDetailOwn;
      },
      transformErrorResponse: (
        response: IBaseGraphqlError
      ): IBaseGraphqlError => {
        return response;
      },
      providesTags: ["user"],
    }),

    // mutations

    login: builder.mutation(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILoginRequest,
        ILoginResponse,
        ILoginResult,
        ILoginInput
      >({
        query: loginQuery,
      })
    ),

    logout: builder.mutation(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILogoutRequest,
        ILogoutResponse,
        ILogoutResult,
        ILogoutInput
      >({
        query: logoutQuery,
      })
    ),

    requestResetPassword: builder.mutation(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IResetPasswordRequest,
        IResetPasswordResponse,
        IResetPasswordResult,
        IResetPasswordInput
      >({
        query: resetPasswordQuery,
      })
    ),

    confirmRegistration: builder.mutation(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IConfirmRegistrationRequest,
        IConfirmRegistrationResponse,
        IConfirmRegistrationResult,
        IConfirmRegistrationInput
      >({
        query: confirmRegistrationQuery,
      })
    ),

    resendRegistrationByUserConfirmation: builder.mutation<
      IResendRegistrationByUserConfirmationResult,
      IResendRegistrationByUserConfirmationInput
    >({
      query: (variables: IResendRegistrationByUserConfirmationInput) => {
        return {
          url: "",
          body: JSON.stringify({
            query: print(resendRegistrationByUserConfirmationQuery),
            variables: {
              input: {
                email: variables.email,
              },
            },
          }),
        };
      },
      transformResponse: (
        response: IResendRegistrationByUserConfirmationResult
      ) => {
        return response;
      },
      transformErrorResponse: (response: IBaseGraphqlError) => {
        return response;
      },
      invalidatesTags: [],
    }),

    resetPassword: builder.mutation<IResetPasswordResult, IResetPasswordInput>({
      query: (variables: IResetPasswordInput) => {
        return {
          url: "",
          body: JSON.stringify({
            query: print(resetPasswordQuery),
            variables: {
              input: {
                oldPassword: variables.oldPassword,
                password: variables.password,
              },
            },
          }),
        };
      },
      transformResponse: (response: IResetPasswordResult) => {
        return response;
      },
      transformErrorResponse: (response: IBaseGraphqlError) => {
        return response;
      },
      invalidatesTags: [],
    }),

    resetPasswordAfterRequest: builder.mutation<
      IResetPasswordAfterRequestResult,
      IResetPasswordAfterRequestInput
    >({
      query: (variables: IResetPasswordAfterRequestInput) => {
        return {
          url: "",
          body: JSON.stringify({
            query: print(resetPasswordAfterRequestQuery),
            variables: {
              input: {
                token: variables.token,
                password: variables.password,
              },
            },
          }),
        };
      },
      transformResponse: (response: IResetPasswordAfterRequestResult) => {
        return response;
      },
      transformErrorResponse: (
        response: IBaseGraphqlError
      ): IBaseGraphqlError => {
        return response;
      },
      invalidatesTags: [],
    }),

    confirmRegistrationByUser: builder.mutation<
      IConfirmRegistrationByUserResult,
      IConfirmRegistrationByUserInput
    >({
      query: (variables: IConfirmRegistrationByUserInput) => {
        return {
          url: "",
          body: JSON.stringify({
            query: print(confirmRegistrationByUserQuery),
            variables: {
              input: {
                token: variables.token,
                code: variables.code,
                resetPasswordToken: variables.resetPasswordToken,
                password: variables.password,
              },
            },
          }),
        };
      },
      transformResponse: (response: IConfirmRegistrationByUserResult) => {
        return response;
      },
      transformErrorResponse: (response: IBaseGraphqlError) => {
        return response;
      },
      invalidatesTags: [],
    }),

    resendRegistrationByUser: builder.mutation<
      IResendRegistrationByUserConfirmationRequest,
      IResendRegistrationConfirmationInput
    >({
      query: (variables: IResendRegistrationConfirmationInput) => {
        return {
          url: "",
          body: JSON.stringify({
            query: print(resendRegistrationConfirmationQuery),
            variables: {
              input: {
                email: variables.email,
              },
            },
          }),
        };
      },
      transformResponse: (
        response: IResendRegistrationByUserConfirmationRequest
      ) => {
        return response;
      },
      transformErrorResponse: (response: IBaseGraphqlError) => {
        return response;
      },
      invalidatesTags: [],
    }),

    updateUser: builder.mutation<IUserUpdateResult, IUserUpdateInput>({
      query: (values: IUserUpdateInput) => {
        return {
          url: "",
          body: JSON.stringify({
            query: print(userUpdateQuery),
            variables: {
              input: values,
            },
          }),
        };
      },
      transformResponse: (response: IUserUpdateResult) => {
        return response;
      },
      transformErrorResponse: (response: IBaseGraphqlError) => {
        return response;
      },
      invalidatesTags: ["users", "user"],
    }),
  }),
});

export const {
  useUsersQuery,
  useLazyUsersQuery,
  useUserQuery,
  useLazyUserQuery,

  useLoginMutation,
  useLogoutMutation,
  useRequestResetPasswordMutation,
  useConfirmRegistrationMutation,
  useResendRegistrationByUserConfirmationMutation,
  useResetPasswordMutation,
  useResetPasswordAfterRequestMutation,
  useConfirmRegistrationByUserMutation,
  useResendRegistrationByUserMutation,
  useUpdateUserMutation,
} = authApi;
