import { Route, Routes } from "react-router-dom";

import { List, View } from "../pages";

import { SubscriptionFormLayout } from "../layouts/SubscriptionFormLayout";


export const SubscriptionFormRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<SubscriptionFormLayout />}>
        <Route index element={<List />} />
        <Route path="/:id" element={<View />} />
      </Route>
    </Routes>
  );
};
