import { Route, Routes } from "react-router-dom";

import { DashboardLayout } from "../layouts";
import { Dashboard } from "../pages";

export const DashboardRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<DashboardLayout />}>
        <Route index element={<Dashboard />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="*" element={<Dashboard />} />
      </Route>
    </Routes>
  );
};
