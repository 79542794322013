

import { commonRoutes } from "routes/route.common";

export const helpCenterDomainRoutes = {
  base: "help-center",
  subdomains: {
    helpCenter: {
      base: "help-center",
    },
    helpCenterCategory: {
      base: "category",
    },
    // add subdomain route names here
  },
};

// Helpcenter routes
export const baseHelpCenterRoute = (): string => `/${helpCenterDomainRoutes.base}/`;
export const listHelpCenterRoute = (): string =>
  `/${helpCenterDomainRoutes.base}/${helpCenterDomainRoutes.subdomains.helpCenter.base}`;
export const viewHelpCenterRoute = (id: string): string =>
  `/${helpCenterDomainRoutes.base}/${helpCenterDomainRoutes.subdomains.helpCenter.base}/${id}`;
export const createHelpCenterRoute = (): string =>
  `/${helpCenterDomainRoutes.base}/${helpCenterDomainRoutes.subdomains.helpCenter.base}/${commonRoutes.create}`;
export const updateHelpCenterRoute = (id: string): string =>
  `/${helpCenterDomainRoutes.base}/${helpCenterDomainRoutes.subdomains.helpCenter.base}/${id}/${commonRoutes.update}`;


// HelpCenterCategory routes
export const baseHelpCenterCategoryRoute = (): string => listHelpCenterCategoryRoute();
export const listHelpCenterCategoryRoute = (): string =>
  `/${helpCenterDomainRoutes.base}/${helpCenterDomainRoutes.subdomains.helpCenterCategory.base}`;
export const viewHelpCenterCategoryRoute = (id: string): string =>
  `/${helpCenterDomainRoutes.base}/${helpCenterDomainRoutes.subdomains.helpCenterCategory.base}/${id}`;
export const createHelpCenterCategoryRoute = (): string =>
  `/${helpCenterDomainRoutes.base}/${helpCenterDomainRoutes.subdomains.helpCenterCategory.base}/${commonRoutes.create}`;
export const updateHelpCenterCategoryRoute = (id: string): string =>
  `/${helpCenterDomainRoutes.base}/${helpCenterDomainRoutes.subdomains.helpCenterCategory.base}/${id}/${commonRoutes.update}`;
// add subdomain routes here
