import { Card, Chip, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { useContactFormQuery } from "../context/contactForm.api";
import { useAppDispatch } from "context";
import { contactFormActions } from "../../../context";
import { useEffect } from "react";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import { IBaseError } from "corede-common";
import { getCurrentLanguage } from "localization";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { listContactFormRoute } from "../../../routes/contactForm.base.route";
import moment from "moment";

const View = () => {
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const { id } = useParams();
  if (!id) {
    navigate(listContactFormRoute());
  }
  // queries
  const {
    data: viewContactFormData,
    isLoading: viewContactFormIsLoading,
    error: viewContactFormError,
  } = useContactFormQuery({
    input: { _id: id! },
  });

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      viewContactFormError as IBaseError,
      currentLanguage
    );
  }, [viewContactFormError, currentLanguage]);

  useEffect(() => {
    dispatch(contactFormActions.setTitle(t("main.form.contactForm.view")));
    dispatch(contactFormActions.setBackButton(true));
    dispatch(contactFormActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2}>
        {viewContactFormIsLoading ? (
          <CircularProgress />
        ) : (<>
          <Card sx={{ p: 4 }}>
            <Stack direction="column" gap={2}>
              <Stack direction="row" gap={1} justifyContent={"space-between"}>
                <Stack direction="column" gap={1}>
                  <Typography variant="h6">
                    {viewContactFormData?.fullName}
                  </Typography>
                  <Typography variant="body1">
                    {viewContactFormData?.email}
                  </Typography>
                </Stack>
                <Stack direction="column" gap={1} alignItems="flex-end">
                  <Typography variant="body1">
                    {moment(viewContactFormData?.createdAt).format("DD.MM.YYYY HH:mm")}
                  </Typography>
                  <Stack direction="row" gap={2} alignItems="center" >
                    <Typography variant="body1">
                      <Chip label={viewContactFormData?.language} color="primary" />
                    </Typography>
                    <Typography variant="body1">
                      <Chip label={viewContactFormData?.status} />
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Card>
          <Card sx={{ p: 4 }}>
            <Stack direction="column" gap={2}>
              <Typography variant="h4">
                {viewContactFormData?.subject}
              </Typography>
              <Typography variant="body1">
                {viewContactFormData?.message}
              </Typography>
            </Stack>
          </Card>
        </>
        )}
      </Stack>
    </Grid>
  );
};

export default View;
