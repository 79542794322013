const locale = {
  helpCenterCategory: "Helpcentercategory",
  helpCenterCategories: "Helpcentercategories",
  create: "Create Helpcentercategory",
  update: "Update Helpcentercategory",
  delete: "Delete Helpcentercategory",
  view: "View Helpcentercategory",
  list: "List Helpcentercategories",
  createdSuccessfully: "Helpcentercategory created successfully",
  updatedSuccessfully: "Helpcentercategory updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Helpcentercategory deleted successfully",
};

export default locale;
