export function pageCount(params: {
  listResultCount?: number;
  pageSize?: number;
  defaultPageSize?: number;
}): number {
  const defaultPageSize = 10;

  const result = Math.ceil(
    (params.listResultCount ?? 1) /
      (params.pageSize ?? params.defaultPageSize ?? defaultPageSize)
  );

  return result;
}
