const locale = {
  subscription: "Subscription",
  subscriptions: "Subscriptions",
  create: "Create Subscription",
  update: "Update Subscription",
  delete: "Delete Subscription",
  view: "View Subscription",
  list: "List Subscriptions",
  createdSuccessfully: "Subscription created successfully",
  updatedSuccessfully: "Subscription updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Subscription deleted successfully",
  filters: "Filters",
  email: "Email",
  userType: "User Type",
  statuses: "Statuses",
  language: "Language",
  user: "User",
  page: "Page",
  subscribedTopics: "Subscribed Topics",
  start: "Start",
  end: "End",
  downloadExampleForm: "Download Example",
  downloadForm: "Download ",
  importForm: "Import",
  importedSuccessfully: "Subscription imported successfully",
  importedError: "Subscription import error",
  downloadExampleFormSuccessfully: "Example form downloaded successfully",
  downloadExampleFormError: "Example form download error",
  downloadFormSuccessfully: "Form downloaded successfully",
  downloadFormError: "Form download error",

};

export default locale;
