const locale = {
  contactForm: "Contactform",
  contactForms: "Contactforms",
  create: "Create Contactform",
  update: "Update Contactform",
  delete: "Delete Contactform",
  view: "View Contactform",
  list: "List Contactforms",
  createdSuccessfully: "Contactform created successfully",
  updatedSuccessfully: "Contactform updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Contactform deleted successfully",
  status: "Durum",
  escalatedUser: "Yükseltilmiş Kullanıcı",
  responsibleUser: "Sorumlu Kullanıcı",
};

export default locale;
