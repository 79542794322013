import { IMenuItem } from "interfaces/menu-item.interface";
import { getTranslatedText } from "localization";
import {
  baseBlogRoute,
  listAuthorRoute,
  listBlogCategoriesRoute,
  listBlogRoute,
  listBlogTargetCategoryRoute
} from "../routes/blog.base.route";

export const blogDomainMenuItem: IMenuItem = {
  title: getTranslatedText("blog"),
  path: baseBlogRoute(),
  iconName: "brandico:blogger",
  children: [
    {
      title: getTranslatedText("blogs"),
      path: listBlogRoute(),
    },
    {
      title: getTranslatedText("categories"),
      path: listBlogCategoriesRoute(),
    },
    {
      title: getTranslatedText("blogTargetCategories"),
      path: listBlogTargetCategoryRoute(),
    },
    {
      title: getTranslatedText("authors"),
      path: listAuthorRoute(),
    }
  ],
};
