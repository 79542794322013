import { HelpCenterCategoryRouter } from "../subdomains/helpCenterCategory/routes/HelpCenterCategoryRouter";

import { HelpCenterRouter } from "../subdomains/helpCenter/routes/HelpCenterRouter";
import { HelpCenterDomainLayout } from "../layouts";
import { helpCenterDomainRoutes } from "./helpCenter.base.route";
import { Route, Routes } from "react-router-dom";

export const HelpCenterDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<HelpCenterDomainLayout />}>
        <Route index element={<HelpCenterRouter />} />
        <Route
          path={`/${helpCenterDomainRoutes.subdomains.helpCenter.base}/*`}
          element={<HelpCenterRouter />}
        />
        <Route
          path={`/${helpCenterDomainRoutes.subdomains.helpCenterCategory.base}/*`}
          element={<HelpCenterCategoryRouter />}
        />
        {/*
          // TODO: import subdomains
        */}
      </Route>
    </Routes>
  );
};
