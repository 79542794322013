const locale = {
  requestForm: "Requestform",
  requestForms: "Requestforms",
  create: "Create Requestform",
  update: "Update Requestform",
  delete: "Delete Requestform",
  view: "View Requestform",
  list: "List Requestforms",
  createdSuccessfully: "Requestform created successfully",
  updatedSuccessfully: "Requestform updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Requestform deleted successfully",
};

export default locale;
