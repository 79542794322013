
import * as Yup from 'yup';

export const emailYup = Yup.string().email('Invalid email address').required('Required');
export const passwordYup = Yup.string().required('Required');
export const passwordAgainYup = Yup.string().oneOf([Yup.ref('password'), ""], 'Passwords must match').required('Required');
export const titleYup = Yup.string().required('Title is required');
export const categoryYup = Yup.string().nullable();
export const letsTitleYup = Yup.string().required('Category is required');
export const quotaYup = Yup.string().required('Quota is required');
export const dateYup = Yup.string().required('Date is required');
export const reservationCloseDateYup = Yup.string().required('Reservation Close Date is required');
export const statusYup = Yup.string().required('Status is required');
export const locationYup = Yup.string().required('Location is required');
export const departmentsYup = Yup.array().required('Departments is required');
export const departmentYup = Yup.string().required('Department is required');
export const department2Yup = Yup.string().nullable();
export const reserveQuotaYup = Yup.string().required('Reserve Quota is required');
export const detailsYup = Yup.string().required('Details is required');
export const nameYup = Yup.string().nullable();
export const surnameYup = Yup.string().nullable();
export const codeYup = Yup.string().required('Code is required');
export const sessionsYup = Yup.array().nullable();
export const birthDateYup = Yup.string().nullable();
export const genderYup = Yup.string().nullable();
export const countryYup = Yup.string().nullable();
export const cityYup = Yup.string().nullable();
export const addressYup = Yup.string().nullable();
export const profileImageYup = Yup.object().nullable();
export const descriptionYup = Yup.string().nullable();
export const phoneNumberYup = Yup.string().nullable();



export const loginValidationSchema = Yup.object({
    email: emailYup,
    password: passwordYup,
});

export const resetPasswordValidationSchema = Yup.object({
    password: passwordYup,
    passwordAgain: passwordAgainYup,
});

export const forgotPasswordValidationSchema = Yup.object({
    email: emailYup,
});

export const createActivityValidationSchema = Yup.object({
    title: titleYup,
    category: categoryYup,
    quota: quotaYup,
    date: dateYup,
    reservationCloseDate: reservationCloseDateYup,
    location: locationYup,
    departments: departmentsYup,
    reserveQuota: reserveQuotaYup,
    details: detailsYup,
    letsTitle: letsTitleYup,
    sessions: sessionsYup,
});

export const updateUserValidationSchema = Yup.object({
    name: nameYup,
    surname: surnameYup,
    birthDate: birthDateYup,
    gender: genderYup,
    country: countryYup,
    city: cityYup,
    address: addressYup,
    profileImage: profileImageYup,
    description: descriptionYup,
    // email: emailYup,
    phoneNumber: phoneNumberYup,
    department: department2Yup,
});

export const updateActivityValidationSchema = Yup.object({
    title: titleYup,
    category: categoryYup,
    quota: quotaYup,
    date: dateYup,
    reservationCloseDate: reservationCloseDateYup,
    location: locationYup,
    reserveQuota: reserveQuotaYup,
    details: detailsYup,
    letsTitle: letsTitleYup,
});

export const createUserValidationSchema = Yup.object({
    name: nameYup,
    surname: surnameYup,
    email: emailYup,
    department: departmentYup,
});

export const createPasswordValidationSchema = Yup.object({
    code: codeYup,
    password: passwordYup,
    passwordAgain: passwordAgainYup,
});