import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Autocomplete,
} from "@mui/material";
import {
  useUpdateHelpCenterCategoryMutation,
  useHelpCenterCategoryQuery,
  useHelpCenterCategoriesQuery,
} from "../context/helpCenterCategory.api";
import { useFormik } from "formik";
import { useAppDispatch } from "context";
import { helpCenterActions } from "../../../context";
import { IHelpCenterCategoryUpdateInput } from "corede-common-cocrm";
import { IBaseError, IGraphqlVariables } from "corede-common";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { t } from "i18next";
import { useParams, useNavigate } from "react-router-dom";
import { validateUpdateHelpCenterCategoryInput } from "../validations/update.validation";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { listHelpCenterCategoryRoute } from "../../../routes/helpCenter.base.route";

const Update = () => {

  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const { id } = useParams();
  if (!id) {
    navigate(listHelpCenterCategoryRoute());
  }

  // queries
  const {
    data: helpCenterCategoryData,
    isLoading: helpCenterCategoryIsLoading,
    error: helpCenterCategoryError,
  } = useHelpCenterCategoryQuery({ input: { _id: id! } });

  const {
    data: helpCenterCategoriesData,
    isLoading: helpCenterCategoriesIsLoading,
    error: helpCenterCategoriesError,
  } = useHelpCenterCategoriesQuery({});

  // mutations
  const [
    updateHelpCenterCategory,
    {
      data: updateHelpCenterCategoryData,
      isLoading: updateHelpCenterCategoryIsLoading,
      error: updateHelpCenterCategoryError,
    },
  ] = useUpdateHelpCenterCategoryMutation();

  // constants
  const {
    values,
    handleSubmit,
    handleBlur,
    setFieldValue,
    errors,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      input: {
        name: "",
        icon: "",
        parentCategoryId: "",
        nameTranslation: undefined,
      },
    },
    enableReinitialize: true,
    validationSchema: validateUpdateHelpCenterCategoryInput,
    onSubmit: (values: IGraphqlVariables<IHelpCenterCategoryUpdateInput>) => {
      updateHelpCenterCategory({
        filter: { _id: id! },
        input: values.input,
      });
    },
  });

  // useEffects.success
  useEffect(() => {
    console.log(helpCenterCategoryData);
    if (helpCenterCategoryData) {
      setValues({
        input: {
          name: helpCenterCategoryData.name,
          icon: helpCenterCategoryData.icon,
          parentCategoryId: helpCenterCategoryData.parentCategory?._id,
          nameTranslation: helpCenterCategoryData.nameTranslation,
        },
      });
    }
  }, [helpCenterCategoryData, setValues]);

  useEffect(() => {
    if (updateHelpCenterCategoryData) {
      resetForm();
      enqueueSnackbar(t("main.helpCenter.helpCenterCategory.updatedSuccessfully"), {
        variant: "success",
      });
    }
  }, [updateHelpCenterCategoryData, resetForm]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      updateHelpCenterCategoryError as IBaseError,
      currentLanguage
    );
  }, [updateHelpCenterCategoryError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      helpCenterCategoryError as IBaseError,
      currentLanguage
    );
  }, [helpCenterCategoryError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      helpCenterCategoriesError as IBaseError,
      currentLanguage
    );
  }, [helpCenterCategoriesError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    dispatch(helpCenterActions.setTitle(t("main.helpCenter.helpCenterCategory.update")));
    dispatch(helpCenterActions.setBackButton(true));
    dispatch(helpCenterActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2} maxWidth={400}>
        {helpCenterCategoryIsLoading ? (
          <CircularProgress />
        ) : (
          <Stack direction="column" gap={2}>
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              value={(values as any).input.name}
              onChange={(e) => setFieldValue("input.name", e.target.value)}
              onBlur={() => handleBlur("input.name")}
              error={Boolean((errors as any).input?.name)}
              helperText={(errors as any).input?.name}
            />
            <TextField
              fullWidth
              label="Name - English"
              variant="outlined"
              value={(values as any).input.nameTranslation?.en}
              onChange={(e) => setFieldValue("input.nameTranslation.en", e.target.value)}
              onBlur={() => handleBlur("input.nameTranslation.en")}
              error={Boolean((errors as any).input?.nameTranslation?.en)}
              helperText={(errors as any).input?.nameTranslation?.en}
            />
            <TextField
              fullWidth
              label="Name - Turkish"
              variant="outlined"
              value={(values as any).input.nameTranslation?.tr}
              onChange={(e) => setFieldValue("input.nameTranslation.tr", e.target.value)}
              onBlur={() => handleBlur("input.nameTranslation.tr")}
              error={Boolean((errors as any).input?.nameTranslation?.tr)}
              helperText={(errors as any).input?.nameTranslation?.tr}
            />
            <TextField
              fullWidth
              label="Icon"
              variant="outlined"
              value={(values as any).input.icon}
              onChange={(e) => setFieldValue("input.icon", e.target.value)}
              onBlur={() => handleBlur("input.icon")}
              error={Boolean((errors as any).input?.icon)}
              helperText={(errors as any).input?.icon}
            />
            <Autocomplete
              id="parentCategoryId"
              isOptionEqualToValue={(option, value) => option._id === value._id}
              getOptionLabel={(option) => option.name}
              options={helpCenterCategoriesData?.data || []}
              loading={helpCenterCategoriesIsLoading}
              renderInput={(params) => <TextField {...params} label={t("main.blog.blogCategory.parentCategory")} />}
              value={helpCenterCategoriesData?.data?.find((category) => category._id === (values as any).input.parentCategoryId) || null}
              onChange={(e, value) => {
                setFieldValue("input.parentCategoryId", value?._id);
              }}
              onBlur={() => handleBlur("input.parentCategoryId")}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
              disabled={updateHelpCenterCategoryIsLoading || !(values as any).input.name}
            >
              {updateHelpCenterCategoryIsLoading ? (
                <CircularProgress size="1rem" sx={{ mr: 2 }} />
              ) : (
                ""
              )}
              {t("update")}
            </Button>
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default Update;
