import {
  Grid,
  Stack,
  Button,
  Skeleton,
  Card,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useAppDispatch } from "context";
import { formActions } from "../../../context";
import { viewSubscriptionFormRoute } from "../../../routes/form.base.route";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSubscriptionFormsQuery } from "../context/subscriptionForm.api";
import { Delete as DeleteIcon, RemoveRedEye } from "@mui/icons-material";
import { MyTablePagination } from "components";
import { IBaseError, IGraphqlVariables, TNullable } from "corede-common";
import {
  IBaseSubscriptionFormEntity,
  ISubscriptionFormsInput,
} from "corede-common-cocrm";
import { getCurrentLanguage } from "localization";
import { useNavigate } from "react-router-dom";
import Delete from "./Delete";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import moment from "moment";

const List = () => {
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();

  // useStates
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 0,
  });
  const [selectedItemForDelete, setSelectedItemForDelete] =
    useState<TNullable<IBaseSubscriptionFormEntity>>(null);
  const [variables, setVariables] = useState<
    IGraphqlVariables<ISubscriptionFormsInput>
  >({
    input: {
      pagination: {
        pageSize: pagination.pageSize,
        page: pagination.page + 1,
      },
    },
  });

  // queries
  const {
    data: subscriptionFormsData,
    isLoading: subscriptionFormsIsLoading,
    error: subscriptionFormsError,
  } = useSubscriptionFormsQuery(variables);

  // mutations

  // constants

  // action handlers

  const handleDelete = (item: IBaseSubscriptionFormEntity) => {
    setSelectedItemForDelete(item);
  };

  const handleView = (id: string) => {
    navigate(viewSubscriptionFormRoute(id));
  };

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      subscriptionFormsError as IBaseError,
      currentLanguage
    );
  }, [subscriptionFormsError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    setVariables({
      input: {
        pagination: {
          pageSize: pagination.pageSize,
          page: pagination.page + 1,
        },
      },
    });
  }, [pagination]);

  useEffect(() => {
    dispatch(formActions.setTitle(t("main.form.subscriptionForm.subscriptionForms")));
    dispatch(formActions.setBackButton(false));
    dispatch(formActions.setRightButton(null));
  }, [dispatch]);



  return (
    <Grid item xs={12}>
      <Card sx={{ p: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>{t("email")} / {t("userType")}</TableCell>
                <TableCell>{t("page")}</TableCell>
                <TableCell>{t("blog")}</TableCell>
                <TableCell>{t("news")}</TableCell>
                <TableCell>{t("offer")}</TableCell>
                <TableCell>{t("products")}</TableCell>
                <TableCell>{t("status")}</TableCell>
                <TableCell>{t("createdAt")}</TableCell>
                <TableCell align="right">{t("actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!subscriptionFormsIsLoading
                ? subscriptionFormsData?.data?.map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{(pagination.page ?? 0) * pagination.pageSize + index + 1}</TableCell>
                    <TableCell>
                      <Typography variant="body2" fontWeight={"bold"}>{row.email}</Typography>
                      <Typography variant="body2">{row.userType}</Typography>
                    </TableCell>
                    <TableCell>{row.page}</TableCell>
                    <TableCell><Chip variant="outlined" label={row.blogTopicSubscribed ? "Yes" : "No"} color={row.blogTopicSubscribed ? "success" : "error"} /></TableCell>
                    <TableCell><Chip variant="outlined" label={row.newsTopicSubscribed ? "Yes" : "No"} color={row.newsTopicSubscribed ? "success" : "error"} /></TableCell>
                    <TableCell><Chip variant="outlined" label={row.offerTopicSubscribed ? "Yes" : "No"} color={row.offerTopicSubscribed ? "success" : "error"} /></TableCell>
                    <TableCell><Chip variant="outlined" label={row.productTopicSubscribed ? "Yes" : "No"} color={row.productTopicSubscribed ? "success" : "error"} /></TableCell>
                    <TableCell><Chip label={row.status} /></TableCell>
                    <TableCell>{moment(row.createdAt).format("DD.MM.YYYY HH:mm")}</TableCell>
                    <TableCell align="right">
                      <Stack direction="row" justifyContent={"flex-end"} gap={1} flexWrap={"wrap"}>
                        <Button
                          onClick={() => handleView(row._id)}
                          variant="outlined"
                          size="small"
                          sx={{ height: 32, minWidth: 10 }}
                        >
                          <RemoveRedEye sx={{ fontSize: "16px" }} />
                        </Button>
                        <Button
                          onClick={() => handleDelete(row)}
                          variant="outlined"
                          size="small"
                          color="error"
                          sx={{ height: 32, minWidth: 10 }}
                        >
                          <DeleteIcon sx={{ fontSize: "16px" }} />
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
                : [1, 2, 3].map((key) => (
                  <TableRow key={key}>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <Stack
        direction="row"
        justifyContent={{ xs: "center", md: "flex-start" }}
        mt={2}
        mb={2}
      >
        <MyTablePagination
          count={subscriptionFormsData?.count ?? 0}
          pageSize={pagination.pageSize}
          page={pagination.page ?? 0}
          onPageChange={(e, page) => {
            setPagination({
              ...pagination,
              page: page,
            });
          }}
          onRowsPerPageChange={(e) => {
            setPagination({
              page: 0,
              pageSize: parseInt(e.target.value)
            });
          }}
        />
      </Stack>

      <Delete
        open={!!selectedItemForDelete}
        item={selectedItemForDelete!}
        onClose={() => {
          setSelectedItemForDelete(null);
        }}
      />
    </Grid>
  );
};

export default List;
