import helpCenterLocale from "../subdomains/helpCenter/locales/en";
import helpCenterCategoryLocale from "../subdomains/helpCenterCategory/locales/en";

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////

  helpCenter: {
    ...helpCenterLocale,
  },
  helpCenterCategory: {
    ...helpCenterCategoryLocale,
  },
};

export default locale;
