import {

  IHelpCenterListInput,
  IHelpCenterListRequest,
  IHelpCenterListResponse,
  IHelpCenterListResult,
  helpCenterListQuery,

  IHelpCenterDetailInput,
  IHelpCenterDetailRequest,
  IHelpCenterDetailResponse,
  IHelpCenterDetailResult,
  helpCenterDetailQuery,



  IHelpCenterCreateInput,
  IHelpCenterCreateRequest,
  IHelpCenterCreateResponse,
  IHelpCenterCreateResult,
  helpCenterCreateQuery,

  IHelpCenterUpdateInput,
  IHelpCenterUpdateFilterInput,
  IHelpCenterUpdateRequest,
  IHelpCenterUpdateResponse,
  IHelpCenterUpdateResult,

  IHelpCenterDeleteInput,
  IHelpCenterDeleteRequest,
  IHelpCenterDeleteResponse,
  IHelpCenterDeleteResult,
  helpCenterUpdateQuery,
  helpCenterDeleteQuery,

  helpCenterVideoCreateQuery,
  IHelpCenterVideoCreateRequest,
  IHelpCenterVideoCreateResponse,

  helpCenterVideoAssignQuery,
  IHelpCenterVideoAssignRequest,
  IHelpCenterVideoAssignResponse,

} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { helpCenterTags } from "./helpCenter.tags";
import {
  IGraphqlVariables,
  IDomainFileCreateOwnInputWithMimeType,
  IDomainFileCreateResult,
  IEntityFileAssignOwnInput,
  IEntityFileAssignResult,

} from "corede-common";

export const helpCenterApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    helpCenterList: builder.query<
      IHelpCenterListResult,
      IGraphqlVariables<IHelpCenterListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IHelpCenterListRequest,
        IHelpCenterListResponse,
        IHelpCenterListResult,
        IHelpCenterListInput
      >({
        query: helpCenterListQuery,
        providesTags: [helpCenterTags.helpCenterList],
      })
    ),

    helpCenter: builder.query<
      IHelpCenterDetailResult,
      IGraphqlVariables<IHelpCenterDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IHelpCenterDetailRequest,
        IHelpCenterDetailResponse,
        IHelpCenterDetailResult,
        IHelpCenterDetailInput
      >({
        query: helpCenterDetailQuery,
        providesTags: [helpCenterTags.helpCenterDetail],
      })
    ),

    // mutation
    helpCenterCreate: builder.mutation<
      IHelpCenterCreateResult,
      IGraphqlVariables<IHelpCenterCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IHelpCenterCreateRequest,
        IHelpCenterCreateResponse,
        IHelpCenterCreateResult,
        IHelpCenterCreateInput
      >({
        query: helpCenterCreateQuery,
        invalidatesTags: [helpCenterTags.helpCenterList],
      })
    ),

    helpCenterUpdate: builder.mutation<
      IHelpCenterUpdateResult,
      IGraphqlVariables<
        IHelpCenterUpdateInput,
        IHelpCenterUpdateFilterInput
      >
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IHelpCenterUpdateRequest,
        IHelpCenterUpdateResponse,
        IHelpCenterUpdateResult,
        IHelpCenterUpdateInput,
        IHelpCenterUpdateFilterInput
      >({
        query: helpCenterUpdateQuery,
        invalidatesTags: [
          helpCenterTags.helpCenterList,
          helpCenterTags.helpCenterDetail,
        ],
      })
    ),

    helpCenterDelete: builder.mutation<
      IHelpCenterDeleteResult,
      IGraphqlVariables<IHelpCenterDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IHelpCenterDeleteRequest,
        IHelpCenterDeleteResponse,
        IHelpCenterDeleteResult,
        IHelpCenterDeleteInput
      >({
        query: helpCenterDeleteQuery,
        invalidatesTags: [
          helpCenterTags.helpCenterList,
          helpCenterTags.helpCenterDetail,
        ],
      })
    ),

    helpCenterVideoCreate: builder.mutation<
      IDomainFileCreateResult,
      IGraphqlVariables<IDomainFileCreateOwnInputWithMimeType>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IHelpCenterVideoCreateRequest,
        IHelpCenterVideoCreateResponse,
        IDomainFileCreateResult,
        IDomainFileCreateOwnInputWithMimeType
      >({
        query: helpCenterVideoCreateQuery,
        invalidatesTags: [
          helpCenterTags.helpCenterList,
          helpCenterTags.helpCenterDetail,
        ],
      })
    ),

    helpCenterVideoAssign: builder.mutation<
      IEntityFileAssignResult,
      IGraphqlVariables<IEntityFileAssignOwnInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IHelpCenterVideoAssignRequest,
        IHelpCenterVideoAssignResponse,
        IEntityFileAssignResult,
        IEntityFileAssignOwnInput
      >({
        query: helpCenterVideoAssignQuery,
        invalidatesTags: [
          helpCenterTags.helpCenterList,
          helpCenterTags.helpCenterDetail,
        ],
      })
    ),

  }),
});

export const {
  useHelpCenterListQuery,
  useLazyHelpCenterListQuery,

  useHelpCenterQuery,
  useLazyHelpCenterQuery,

  useHelpCenterCreateMutation,
  useHelpCenterUpdateMutation,
  useHelpCenterDeleteMutation,

  useHelpCenterVideoCreateMutation,
  useHelpCenterVideoAssignMutation,
} = helpCenterApi;
