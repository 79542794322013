
export const getAccessToken = () => {
    const localAuthInfo = JSON.parse(localStorage.getItem('auth') ?? "{}")
    const accessToken = localAuthInfo?.accessToken ?? ""

    return accessToken
}

export const getUserId = () => {
    const localAuthInfo = JSON.parse(localStorage.getItem('auth') ?? "{}")
    const userId = localAuthInfo?.user?.userId ?? ""

    return userId
}

export const getRole = () => {
    const localAuthInfo = JSON.parse(localStorage.getItem('auth') ?? "{}")
    const role = localAuthInfo?.user?.role ?? ""

    return role
}

export const getEmail = () => {
    const localAuthInfo = JSON.parse(localStorage.getItem('auth') ?? "{}")
    const email = localAuthInfo?.user?.email ?? ""

    return email
}

export const getName = () => {
    const localAuthInfo = JSON.parse(localStorage.getItem('auth') ?? "{}")
    const name = localAuthInfo?.user?.name ?? ""

    return name
}

export const getSurname = () => {
    const localAuthInfo = JSON.parse(localStorage.getItem('auth') ?? "{}")
    const surname = localAuthInfo?.user?.surname ?? ""

    return surname
}
