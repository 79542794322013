import { Route, Routes } from "react-router-dom";

import { List, View, Update } from "../pages";

import { ContactFormLayout } from "../layouts/ContactFormLayout";

export const ContactFormRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ContactFormLayout />}>
        <Route index element={<List />} />
        <Route path="/:id" element={<View />} />
        <Route path="/:id/update" element={<Update />} />
      </Route>
    </Routes>
  );
};
