import { Card, Chip, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { useSubscriptionQuery } from "../context/subscription.api";
import { useAppDispatch } from "context";
import { subscriptionActions } from "../../../context";
import { useEffect } from "react";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import { IBaseError } from "corede-common";
import { getCurrentLanguage } from "localization";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { listSubscriptionRoute } from "../../../routes/subscription.base.route";
import moment from "moment";

const View = () => {
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const { id } = useParams();
  if (!id) {
    navigate(listSubscriptionRoute());
  }
  // queries
  const {
    data: viewSubscriptionFormData,
    isLoading: viewSubscriptionFormIsLoading,
    error: viewSubscriptionFormError,
  } = useSubscriptionQuery({
    input: { _id: id! },
  });

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      viewSubscriptionFormError as IBaseError,
      currentLanguage
    );
  }, [viewSubscriptionFormError, currentLanguage]);

  useEffect(() => {
    dispatch(subscriptionActions.setTitle(t("main.form.subscriptionForm.view")));
    dispatch(subscriptionActions.setBackButton(true));
    dispatch(subscriptionActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2}>
        {viewSubscriptionFormIsLoading ? (
          <CircularProgress />
        ) : (
          <Card sx={{ p: 4 }}>
            <Stack direction="column" gap={2}>
              <Typography>
                <strong>{t("main.form.subscriptionForm.email")}: </strong>
                {viewSubscriptionFormData?.email}
              </Typography>
              <Typography>
                <strong>{t("main.form.subscriptionForm.fullName")}: </strong>
                {viewSubscriptionFormData?.user?.name} {viewSubscriptionFormData?.user?.surname}
              </Typography>
              <Typography>
                <strong>{t("main.form.subscriptionForm.status")}: </strong>
                <Chip label={viewSubscriptionFormData?.status} />
              </Typography>
              <Typography>
                <strong>{t("main.form.subscriptionForm.userType")}: </strong>
                {viewSubscriptionFormData?.userType}
              </Typography>
              <Typography>
                <strong>{t("main.form.subscriptionForm.blog")}: </strong>
                <Chip variant="outlined" label={viewSubscriptionFormData?.blogTopicSubscribed ? "Yes" : "No"} color={viewSubscriptionFormData?.blogTopicSubscribed ? "success" : "error"} />
              </Typography>
              <Typography>
                <strong>{t("main.form.subscriptionForm.news")}: </strong>
                <Chip variant="outlined" label={viewSubscriptionFormData?.newsTopicSubscribed ? "Yes" : "No"} color={viewSubscriptionFormData?.newsTopicSubscribed ? "success" : "error"} />
              </Typography>
              <Typography>
                <strong>{t("main.form.subscriptionForm.offer")}: </strong>
                <Chip variant="outlined" label={viewSubscriptionFormData?.offerTopicSubscribed ? "Yes" : "No"} color={viewSubscriptionFormData?.offerTopicSubscribed ? "success" : "error"} />
              </Typography>
              <Typography>
                <strong>{t("main.form.subscriptionForm.product")}: </strong>
                <Chip variant="outlined" label={viewSubscriptionFormData?.productTopicSubscribed ? "Yes" : "No"} color={viewSubscriptionFormData?.productTopicSubscribed ? "success" : "error"} />
              </Typography>
              <Typography>
                <strong>{t("main.form.subscriptionForm.language")}: </strong>
                {viewSubscriptionFormData?.language}
              </Typography>
              <Typography>
                <strong>{t("main.form.subscriptionForm.page")}: </strong>
                {viewSubscriptionFormData?.page}
              </Typography>
              <Typography>
                <strong>{t("main.form.subscriptionForm.createdAt")}: </strong>
                {moment(viewSubscriptionFormData?.createdAt).format("DD.MM.YYYY HH:mm")}
              </Typography>
              <Typography>
                <strong>{t("main.form.subscriptionForm.updatedAt")}: </strong>
                {moment(viewSubscriptionFormData?.updatedAt).format("DD.MM.YYYY HH:mm")}
              </Typography>

            </Stack>
          </Card>
        )}
      </Stack>
    </Grid>
  );
};

export default View;
