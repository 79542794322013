import { CircularProgress, Grid, Stack, TextField } from "@mui/material";
import { useBlogCategoryQuery } from "../context/blogCategory.api";
import { useAppDispatch } from "context";
import { blogActions } from "../../../context";
import { useEffect } from "react";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import { IBaseError } from "corede-common";
import { getCurrentLanguage } from "localization";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { listBlogCategoriesRoute, updateBlogCategoryRoute } from "../../../routes/blog.base.route";

const View = () => {
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const { id } = useParams();
  if (!id) {
    navigate(listBlogCategoriesRoute());
  }
  // queries
  const {
    data: viewBlogCategoryData,
    isLoading: viewBlogCategoryIsLoading,
    error: viewBlogCategoryError,
  } = useBlogCategoryQuery({
    input: { _id: id! },
  });

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      viewBlogCategoryError as IBaseError,
      currentLanguage
    );
  }, [viewBlogCategoryError, currentLanguage]);

  useEffect(() => {
    dispatch(blogActions.setTitle(t("main.blog.blogCategory.view")));
    dispatch(blogActions.setBackButton(true));
    dispatch(
      blogActions.setRightButton({
        title: t(`main.blog.blogCategory.update`),
        path: updateBlogCategoryRoute(id!),
        icon: null,
      })
    );
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2} maxWidth={400}>
        {viewBlogCategoryIsLoading ? (
          <CircularProgress />
        ) : (
          <Stack direction="column" gap={2}>
            <TextField
              label={t("main.blog.blogCategory.name")}
              value={viewBlogCategoryData?.name}
              fullWidth
              disabled
            />
            <TextField
              label={t("main.blog.blogCategory.name") + " - English"}
              value={viewBlogCategoryData?.nameTranslation?.en}
              fullWidth
              disabled
            />
            <TextField
              label={t("main.blog.blogCategory.name") + " - Turkish"}
              value={viewBlogCategoryData?.nameTranslation?.tr}
              fullWidth
              disabled
            />
            <TextField
              label={t("main.blog.blogCategory.icon")}
              value={viewBlogCategoryData?.icon}
              fullWidth
              disabled
            />
            <TextField
              label={t("main.blog.blogCategory.parentCategory")}
              value={viewBlogCategoryData?.parentCategory?.name}
              fullWidth
              disabled
            />
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default View;
