


import { IMenuItem } from "interfaces/menu-item.interface";
import { getTranslatedText } from "localization";
import {
  baseHelpCenterRoute,
  listHelpCenterCategoryRoute,
  listHelpCenterRoute,
} from "../routes/helpCenter.base.route";

export const helpCenterDomainMenuItem: IMenuItem = {
  title: getTranslatedText("helpCenter"),
  path: baseHelpCenterRoute(),
  iconName: "mdi:help-circle",
  children: [
    {
      title: getTranslatedText("helpCenter"),
      path: listHelpCenterRoute(),
    },
    {
      title: getTranslatedText("helpCenterCategory"),
      path: listHelpCenterCategoryRoute(),
    }
    // TODO: import subdomain menu items
  ],
};
