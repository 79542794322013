import { Route, Routes } from "react-router-dom";

import { PaymentProductLayout } from "../layouts/PaymentProductLayout";
import { commonRoutes } from "routes/route.common";
import { Create, List, Update, View } from "../pages";

export const PaymentProductRouter = () => {
  return (
    <Routes>
      <Route path="/"
        element={<PaymentProductLayout />}
      >
        <Route index element={<List />} />
        <Route path="/:id" element={<View />} />
        <Route path={`/${commonRoutes.create}`} element={<Create />} />
        <Route path={`/:id/${commonRoutes.update}`} element={<Update />} />
      </Route>
    </Routes>
  );
};
