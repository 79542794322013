


import { IMenuItem } from "interfaces/menu-item.interface";
import { getTranslatedText } from "localization";
import {
  basePaymentProductRoute,
  listPaymentProductRoute,
} from "../routes/paymentProduct.base.route";

export const paymentProductDomainMenuItem: IMenuItem = {
  title: getTranslatedText("paymentProduct"),
  path: basePaymentProductRoute(),
  iconName: "fa6-brands:wpforms",
  children: [
    {
      title: getTranslatedText("paymentProduct"),
      path: listPaymentProductRoute(),
    },
    // TODO: import subdomain menu items
  ],
};
