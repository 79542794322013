import {
  IBlogsInput,
  IBlogsRequest,
  IBlogsResponse,
  IBlogsResult,
  blogQuery,
  blogsQuery,
  IBlogInput,
  IBlogRequest,
  IBlogResponse,
  IBlogResult,
  ICreateBlogInput,
  ICreateBlogRequest,
  ICreateBlogResponse,
  ICreateBlogResult,
  createBlogQuery,
  IUpdateBlogInput,
  IUpdateBlogRequest,
  IUpdateBlogResponse,
  IUpdateBlogResult,
  updateBlogQuery,
  IUpdateBlogFilterInput,
  deleteBlogQuery,
  IDeleteBlogInput,
  IDeleteBlogRequest,
  IDeleteBlogResponse,
  IDeleteBlogResult,

  IBlogImageCreateRequest,
  IBlogImageCreateResponse,
  blogImageCreateQuery,
  IBlogImageAssignRequest,
  IBlogImageAssignResponse,
  blogImageAssignQuery,



} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { blogTags } from "./blog.tags";
import { IGraphqlVariables, IDomainFileCreateResult, IDomainFileCreateOwnInputWithMimeType, IEntityFileAssignResult, IEntityFileAssignInput } from "corede-common";

export const blogApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    blogs: builder.query<
      IBlogsResult,
      IGraphqlVariables<IBlogsInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IBlogsRequest,
        IBlogsResponse,
        IBlogsResult,
        IBlogsInput
      >({
        query: blogsQuery,
        providesTags: [blogTags.blogs],
      })
    ),

    blog: builder.query<
      IBlogResult,
      IGraphqlVariables<IBlogInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IBlogRequest,
        IBlogResponse,
        IBlogResult,
        IBlogInput
      >({
        query: blogQuery,
        providesTags: [blogTags.blog],
      })
    ),


    // mutation
    createBlog: builder.mutation<
      ICreateBlogResult,
      IGraphqlVariables<ICreateBlogInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICreateBlogRequest,
        ICreateBlogResponse,
        ICreateBlogResult,
        ICreateBlogInput
      >({
        query: createBlogQuery,
        invalidatesTags: [blogTags.blogs],
      })
    ),

    updateBlog: builder.mutation<
      IUpdateBlogResult,
      IGraphqlVariables<
        IUpdateBlogInput,
        IUpdateBlogFilterInput
      >
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUpdateBlogRequest,
        IUpdateBlogResponse,
        IUpdateBlogResult,
        IUpdateBlogInput,
        IUpdateBlogFilterInput
      >({
        query: updateBlogQuery,
        invalidatesTags: [
          blogTags.blogs,
          blogTags.blog,
        ],
      })
    ),

    deleteBlog: builder.mutation<
      IDeleteBlogResult,
      IGraphqlVariables<IDeleteBlogInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IDeleteBlogRequest,
        IDeleteBlogResponse,
        IDeleteBlogResult,
        IDeleteBlogInput
      >({
        query: deleteBlogQuery,
        invalidatesTags: [
          blogTags.blogs,
          blogTags.blog,
        ],
      })
    ),

    blogImageCreate: builder.mutation<
      IDomainFileCreateResult,
      IGraphqlVariables<IDomainFileCreateOwnInputWithMimeType>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IBlogImageCreateRequest,
        IBlogImageCreateResponse,
        IDomainFileCreateResult,
        IDomainFileCreateOwnInputWithMimeType
      >({
        query: blogImageCreateQuery,
        invalidatesTags: [
          blogTags.blogs,
          blogTags.blog,
        ],
      })
    ),

    blogImageAssign: builder.mutation<
      IEntityFileAssignResult,
      IGraphqlVariables<IEntityFileAssignInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IBlogImageAssignRequest,
        IBlogImageAssignResponse,
        IEntityFileAssignResult,
        IEntityFileAssignInput
      >({
        query: blogImageAssignQuery,
        invalidatesTags: [
          blogTags.blogs,
          blogTags.blog,
        ],
      })

    ),

  }),
});

export const {
  useBlogsQuery,
  useLazyBlogsQuery,

  useBlogQuery,
  useLazyBlogQuery,

  useCreateBlogMutation,
  useUpdateBlogMutation,
  useDeleteBlogMutation,
  useBlogImageAssignMutation,
  useBlogImageCreateMutation
} = blogApi;
