const locale = {
  faqCategory: "SSS Kategorisi",
  faqCategories: "SSS Kategorileri",
  create: "SSS Kategorisi Oluştur",
  update: "SSS Kategorisi Güncelle",
  delete: "SSS Kategorisi Sil",
  view: "SSS Kategorisi Görüntüle",
  list: "SSS Kategorilerini Listele",
  createdSuccessfully: "SSS kategorisi başarıyla oluşturuldu",
  updatedSuccessfully: "SSS kategorisi başarıyla güncellendi",
  deleteConfirm: "Silmek istediğinizden emin misiniz: ",
  deletedSuccessfully: "SSS kategorisi başarıyla silindi",
  name: "Ad",
  icon: "Simge",
  parentCategory: "Üst Kategori"
};

export default locale;
