

const locale = {
  blog: "Blog",
  blogs: "Blogs",
  create: "Create Blog",
  update: "Update Blog",
  delete: "Delete Blog",
  view: "View Blog",
  list: "List Blogs",
  createdSuccessfully: "Blog created successfully",
  updatedSuccessfully: "Blog updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Blog deleted successfully",
  title: "Title",
  content: "Content",
  description: "Description",
  readingTime: "Reading Time",
  tags: "Tags",
  addTags: "Add Tags",
  references: "References",
  addReferences: "Add References",
  relatedVideo: "Related Video",
  author: "Author",
  category: "Category",
  targetCategory: "Target Category",
  language: "Language",
  filters: "Filters",
  pleaseEnterTags: "Please enter tags",
  pleaseEnterReferences: "Please enter references",
};

export default locale;

