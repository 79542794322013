import {
  Grid,
  List as ListMui,
  Stack,
  ListItem,
  Button,
  Skeleton,
} from "@mui/material";
import { useAppDispatch } from "context";
import { notificationActions } from "../../../context";
import {
  createNotificationRoute,
  updateNotificationRoute,
  viewNotificationRoute,
} from "../../../routes/notification.base.route";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNotificationsQuery } from "../context/notification.api";
import { Delete as DeleteIcon, Edit, RemoveRedEye } from "@mui/icons-material";
import { Iconify, MyTablePagination } from "components";
import { IBaseError, IGraphqlVariables, TNullable } from "corede-common";
import {
  IBaseNotificationEntity,
  INotificationListInput,
} from "corede-common-cocrm";
import { getCurrentLanguage } from "localization";
import { useNavigate } from "react-router-dom";
import Delete from "./Delete";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";

const List = () => {
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();

  // useStates
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 0,
  });
  const [selectedItemForDelete, setSelectedItemForDelete] =
    useState<TNullable<IBaseNotificationEntity>>(null);
  const [variables, setVariables] = useState<
    IGraphqlVariables<INotificationListInput>
  >({
    input: {
      pagination: {
        pageSize: pagination.pageSize,
        page: pagination.page + 1,
      },
    },
  });

  // queries
  const {
    data: notificationsData,
    isLoading: notificationsIsLoading,
    error: notificationsError,
  } = useNotificationsQuery(variables);

  // mutations

  // constants

  // action handlers
  const handleEdit = (id: string) => {
    navigate(updateNotificationRoute(id));
  };

  const handleDelete = (item: IBaseNotificationEntity) => {
    setSelectedItemForDelete(item);
  };

  const handleView = (id: string) => {
    navigate(viewNotificationRoute(id));
  };

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      notificationsError as IBaseError,
      currentLanguage
    );
  }, [notificationsError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    setVariables({
      input: {
        pagination: {
          pageSize: pagination.pageSize,
          page: pagination.page + 1,
        },
      },
    });
  }, [pagination]);

  useEffect(() => {
    dispatch(notificationActions.setTitle(t("main.notification.notification.notifications")));
    dispatch(notificationActions.setBackButton(false));
    dispatch(
      notificationActions.setRightButton({
        title: t(`main.notification.notification.create`),
        path: createNotificationRoute(),
        icon: null,
      })
    );
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="row" justifyContent="space-between">
        <ListMui
          sx={{ width: "100%", bgcolor: "background.paper" }}
          component="nav"
          aria-label="main mailbox folders"
        >
          {!notificationsIsLoading
            ? notificationsData?.data?.map((notification, index) => (
              <ListItem
                key={notification?._id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: { xs: "center", md: "flex-start" },
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  width={340}
                  alignItems={"center"}
                  p={2}
                  sx={{
                    bgcolor: index % 2 === 0 ? "#e2e2e2" : "#f1f1f1",
                    borderRadius: 2,
                  }}
                >
                  <Stack direction="row" gap={1}>
                    {notification?.name}
                  </Stack>
                  <Stack direction="row" gap={1}>
                    <Button
                      onClick={() => handleView(notification._id)}
                      variant="outlined"
                      size="small"
                      sx={{ height: 32, minWidth: 10 }}
                    >
                      <RemoveRedEye sx={{ fontSize: "16px" }} />
                    </Button>
                    <Button
                      onClick={() => handleEdit(notification._id)}
                      variant="outlined"
                      size="small"
                      sx={{ height: 32, minWidth: 10 }}
                    >
                      <Edit sx={{ fontSize: "16px" }} />
                    </Button>
                    <Button
                      onClick={() => handleDelete(notification)}
                      variant="outlined"
                      size="small"
                      color="error"
                      sx={{ height: 32, minWidth: 10 }}
                    >
                      <DeleteIcon sx={{ fontSize: "16px" }} />
                    </Button>
                  </Stack>
                </Stack>
              </ListItem>
            ))
            : [1, 2, 3].map((key) => (
              <Stack
                key={key}
                direction="row"
                justifyContent="space-between"
                width={340}
                alignItems={"center"}
                p={2}
              >
                <Skeleton
                  variant="rectangular"
                  width={340}
                  height={60}
                  sx={{ borderRadius: 2 }}
                />
              </Stack>
            ))}
        </ListMui>
      </Stack>

      <Stack
        direction="row"
        justifyContent={{ xs: "center", md: "flex-start" }}
        mt={2}
        mb={2}
      >
        <MyTablePagination
          count={notificationsData?.count ?? 0}
          pageSize={pagination.pageSize}
          page={pagination.page ?? 0}
          onPageChange={(e, page) => {
            setPagination({
              ...pagination,
              page: page,
            });
          }}
          onRowsPerPageChange={(e) => {
            setPagination({
              page: 0,
              pageSize: parseInt(e.target.value)
            });
          }}
        />
      </Stack>

      <Delete
        open={!!selectedItemForDelete}
        item={selectedItemForDelete!}
        onClose={() => {
          setSelectedItemForDelete(null);
        }}
      />
    </Grid>
  );
};

export default List;
