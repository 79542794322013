import { getTranslatedText } from "localization";
import { ValidationHelper } from "validations/validation.helper";

export const validateCreatePolicyInput =
  ValidationHelper.BuildObjectValidationSchema({
    input: ValidationHelper.BuildObjectValidationSchema({
      title: ValidationHelper.RequiredString(getTranslatedText("titleIsRequired")),
      content: ValidationHelper.RequiredString(getTranslatedText("contentIsRequired")),
      domain: ValidationHelper.RequiredString(getTranslatedText("domainIsRequired")),
      type: ValidationHelper.RequiredString(getTranslatedText("typeIsRequired")),
      target: ValidationHelper.RequiredString(getTranslatedText("targetIsRequired")),
      language: ValidationHelper.RequiredString(getTranslatedText("languageIsRequired")),
    }),
  });