import contactFormLocale from "../subdomains/contactForm/locales/en";
import subscriptionFormLocale from "../subdomains/subscriptionForm/locales/en";

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////

  contactForm: {
    ...contactFormLocale,
  },
  subscriptionForm: {
    ...subscriptionFormLocale,
  },
};

export default locale;
