import { getTranslatedText } from "localization";
import { ValidationHelper } from "validations/validation.helper";

export const validateCreateAuthorInput =
  ValidationHelper.BuildObjectValidationSchema({
    input: ValidationHelper.BuildObjectValidationSchema({
      name: ValidationHelper.RequiredString(
        getTranslatedText("nameIsRequired")
      ),
      bio: ValidationHelper.NotRequiredString(),
      facebook: ValidationHelper.NotRequiredString(),
      instagram: ValidationHelper.NotRequiredString(),
      linkedIn: ValidationHelper.NotRequiredString(),
      x: ValidationHelper.NotRequiredString(),
    }),
  });