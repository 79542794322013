import { PayloadAction } from "@reduxjs/toolkit";
import { TNullable } from "corede-common";

export interface ICommonButtonState {
  title: string;
  path: string;
  icon: TNullable<string>;
}

// types.ts
export interface ICommonState {
  title: string;
  backButton: TNullable<boolean>;
  rightButton: TNullable<ICommonButtonState>;
}

export const commonInitialState: ICommonState = {
  title: "",
  backButton: true,
  rightButton: {
    title: "",
    path: "",
    icon: null,
  },
};

export const commonReducers = {
  setTitle: (state: ICommonState, action: PayloadAction<string>) => {
    state.title = action.payload;
  },
  setBackButton: (
    state: ICommonState,
    action: PayloadAction<TNullable<boolean>>
  ) => {
    state.backButton = action.payload;
  },
  setRightButton: (
    state: ICommonState,
    action: PayloadAction<TNullable<ICommonButtonState>>
  ) => {
    state.rightButton = action.payload;
  },
};
