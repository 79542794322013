import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Autocomplete,
} from "@mui/material";
import {
  useCreateWebinarMutation,
} from "../context/webinar.api";
import { useFormik } from "formik";
import { useAppDispatch } from "context";
import { webinarActions } from "../../../context";
import { ICreateWebinarInput, WebinarType, WebinarStatus } from "corede-common-cocrm";
import { IBaseError, IGraphqlVariables, Language } from "corede-common";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { validateCreateWebinarInput } from "../validations/create.validation";
import { t } from "i18next";
import { RequestTransformerHelper } from "validations/request.transformer.helper";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import ReactQuill from "react-quill-new";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from "dayjs";

const Create = () => {
  // general
  const dispatch = useAppDispatch();
  const currentLanguage = getCurrentLanguage();

  // queries

  // mutations
  const [
    createWebinar,
    {
      data: createWebinarData,
      isLoading: createWebinarIsLoading,
      error: createWebinarError,
    },
  ] = useCreateWebinarMutation();



  // constants
  const { values, handleSubmit, handleBlur, setFieldValue, errors, resetForm } =
    useFormik({
      initialValues: {
        input: {
          status: WebinarStatus.inactive,
          title: "",
          description: "",
          content: "",
          language: currentLanguage,
          type: WebinarType.b2b,
          quota: 0,
          lastApplicationDate: dayjs().minute(59).toDate(),
          startDate: new Date(),
          duration: 0,
          participationLink: "",
        },
      },
      validationSchema: validateCreateWebinarInput,
      onSubmit: (values: IGraphqlVariables<ICreateWebinarInput>) => {
        const transformedValues =
          RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
            input: values.input,
          });
        createWebinar({
          ...values,
          input: transformedValues,
        });
      },
    });

  // useEffects.success
  useEffect(() => {
    if (createWebinarData) {
      console.log(createWebinarData, "data");
      resetForm();
      enqueueSnackbar(t("main.webinar.webinar.createdSuccessfully"), {
        variant: "success",
      });
    }
  }, [createWebinarData, resetForm]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      createWebinarError as IBaseError,
      currentLanguage
    );
  }, [createWebinarError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    dispatch(webinarActions.setTitle(t("main.webinar.webinar.create")));
    dispatch(webinarActions.setBackButton(true));
    dispatch(webinarActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2}>

        <Autocomplete
          id="status"
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => option}
          options={Object.values(WebinarStatus)}
          renderInput={(params) => <TextField {...params} label={t("main.webinar.webinar.status")} />}
          value={(values as any).input.status || ""}
          onChange={(e, value) => {
            setFieldValue("input.status", value);
          }}
          onBlur={() => handleBlur("input.status")}
        />

        <TextField
          fullWidth
          name="input.title"
          label={t("main.webinar.webinar.title")}
          value={(values as any).input.title}
          onChange={(e) => setFieldValue("input.title", e.target.value)}
          onBlur={() => handleBlur("input.title")}
          error={!!(errors as any).input?.title}
          helperText={(errors as any).input?.title}
        />
        <TextField
          fullWidth
          name="input.content"
          multiline
          rows={2}
          label={t("main.webinar.webinar.content")}
          value={(values as any).input.content}
          onChange={(e) => setFieldValue("input.content", e.target.value)}
          onBlur={() => handleBlur("input.content")}
          error={!!(errors as any).input?.content}
          helperText={(errors as any).input?.content}
        />
        <ReactQuill
          theme="snow"
          value={(values as any).input.description}
          onChange={(content, delta, source, editor) => setFieldValue("input.description", editor.getHTML())}
          onBlur={() => handleBlur("input.description")}
          placeholder={t("main.webinar.webinar.description")}
          style={{ height: 300, marginBottom: 40, paddingBottom: 10 }}
        />
        {
          !!(errors as any).input?.description && (
            <div style={{ color: "#961650", fontSize: "12px", marginTop: -45, zIndex: 10 }}>{(errors as any).input?.description}</div>
          )
        }
        <Autocomplete
          id="language"
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => option}
          options={Object.values(Language)}
          renderInput={(params) => <TextField {...params} label={t("main.webinar.webinar.language")} />}
          value={(values as any).input.language || ""}
          onChange={(e, value) => {
            setFieldValue("input.language", value);
          }}
          onBlur={() => handleBlur("input.language")}
        />
        <Autocomplete
          id="type"
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => option}
          options={Object.values(WebinarType)}
          renderInput={(params) => <TextField {...params} label={t("main.webinar.webinar.type")} />}
          value={(values as any).input.type || ""}
          onChange={(e, value) => {
            setFieldValue("input.type", value);
          }}
          onBlur={() => handleBlur("input.type")}
        />
        <TextField
          fullWidth
          name="input.quota"
          type="number"
          label="Quota"
          value={(values as any).input.quota === 0 ? "" : (values as any).input.quota}
          onChange={(e) => setFieldValue("input.quota", Number(e.target.value))}
          onBlur={() => handleBlur("input.quota")}
          error={!!(errors as any).input?.quota}
          helperText={(errors as any).input?.quota}
        />

        <DateTimePicker
          label={t("main.webinar.webinar.startDate")}
          views={["day", "hours", "minutes"]}
          value={dayjs((values as any).input.startDate)}
          format="DD/MM/YYYY HH:mm"
          minDate={(values as any).input.lastApplicationDate ? dayjs((values as any).input.lastApplicationDate) : dayjs(new Date())}
          onChange={(date) => {
            if (date) {
              setFieldValue("input.startDate", date.toDate());
            }
          }}
        />

        <DateTimePicker
          label={t("main.webinar.webinar.lastApplicationDate")}
          value={dayjs((values as any).input.lastApplicationDate)}
          format="DD/MM/YYYY HH:59"
          minDate={dayjs(new Date())}
          maxDate={(values as any).input.startDate ? dayjs((values as any).input.startDate) : dayjs(new Date().getDate() + 24 * 60 * 60 * 1000)}
          onChange={(date) => {
            if (date) {
              const updatedDate = date.minute(59).second(0).millisecond(0);
              setFieldValue("input.lastApplicationDate", updatedDate.toDate());
            }
          }}
          minutesStep={1}
          views={["day", "hours",]}
        />


        <TextField
          fullWidth
          name="input.duration"
          type="number"
          label={t("main.webinar.webinar.duration")}
          value={(values as any).input.duration === 0 ? "" : (values as any).input.duration}
          onChange={(e) => setFieldValue("input.duration", Number(e.target.value))}
          onBlur={() => handleBlur("input.duration")}
          error={!!(errors as any).input?.duration}
          helperText={(errors as any).input?.duration}
        />

        <TextField
          fullWidth
          name="input.participationLink"
          label={t("main.webinar.webinar.participationLink")}
          value={(values as any).input.participationLink}
          onChange={(e) => setFieldValue("input.participationLink", e.target.value)}
          onBlur={() => handleBlur("input.participationLink")}
          error={!!(errors as any).input?.participationLink}
          helperText={(errors as any).input?.participationLink}
        />


        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
          disabled={createWebinarIsLoading}
        >
          {createWebinarIsLoading ? (
            <CircularProgress size="1rem" sx={{ mr: 2 }} />
          ) : (
            ""
          )}
          Create
        </Button>
      </Stack>
    </Grid>
  );
};

export default Create;
