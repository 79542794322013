import { Route } from 'react-router-dom'

import { Login,  } from '../pages';
import { routes } from './routes';



export const AuthRouter = () => {
    return (
        <>
            <Route path="/" element={<Login />} />

            <Route path="*" element={<Login />} />
        </>
    )
}
