const locale = {
  webNotification: "Webnotification",
  webNotifications: "Webnotifications",
  create: "Create Webnotification",
  update: "Update Webnotification",
  delete: "Delete Webnotification",
  view: "View Webnotification",
  list: "List Webnotifications",
  createdSuccessfully: "Webnotification created successfully",
  updatedSuccessfully: "Webnotification updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Webnotification deleted successfully",
};

export default locale;
