import {
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  useCreatePaymentProductMutation,
} from "../context/paymentProduct.api";
import { useFormik } from "formik";
import { useAppDispatch } from "context";
import { paymentProductActions } from "../../../context";
import { IPaymentProductCreateInput, MainSubscription, PaymentProductStatus, PaymentProductType, RecurringInterval } from "corede-common-cocrm";
import { Currency, IBaseError, IGraphqlVariables } from "corede-common";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { validateCreatePaymentProductInput } from "../validations/create.validation";
import { t } from "i18next";
import { RequestTransformerHelper } from "validations/request.transformer.helper";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";



const Create = () => {
  // general
  const dispatch = useAppDispatch();
  const currentLanguage = getCurrentLanguage();
  const [relatedFeature, setRelatedFeature] = useState("");

  // queries

  // mutations
  const [
    createPaymentProduct,
    {
      data: createPaymentProductData,
      isLoading: createPaymentProductIsLoading,
      error: createPaymentProductError,
    },
  ] = useCreatePaymentProductMutation();

  // constants
  const { values, handleSubmit, handleBlur, setFieldValue, errors, resetForm } =
    useFormik({
      initialValues: {
        input: {
          name: "",
          description: "",
          baseCurrency: Currency.USD,
          isRefundable: false,
          maxCount: 0,
          paymentProductStatus: PaymentProductStatus.active,
          soldOut: false,
          isUpgradable: false,
          recurring: {
            level: 0,
            monthly: {
              packageName: "",
              entitlements: {
                storageLimit: 0,
                userLimit: 0
              },
              pricing: {
                basePrice: 0,
                discountedPrice: 0,
                discountRate: 0,
                salesPrices: [],
              },
              interval: RecurringInterval.monthly,
              integrations: undefined,
            },
            yearly: {
              packageName: "",
              entitlements: {
                storageLimit: 0,
                userLimit: 0
              },
              pricing: {
                basePrice: 0,
                discountedPrice: 0,
                discountRate: 0,
                salesPrices: [],
              },
              interval: RecurringInterval.yearly,
              integrations: undefined,
            },

            daily: undefined,
            weekly: undefined,
            mainSubscription: MainSubscription.main,
          },


          relatedFeatures: [],
          restrictedFeatures: [],
          video: undefined,
          image: undefined,
          integrations: undefined,
          pricing: undefined,
          productType: PaymentProductType.subscription,
          hasShipping: false,
          shippingPrice: undefined,
          quota: -1

        },
      },
      enableReinitialize: true,
      validationSchema: validateCreatePaymentProductInput,
      onSubmit: (values: IGraphqlVariables<IPaymentProductCreateInput>) => {
        const transformedValues =
          RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
            input: values.input,
          });
        createPaymentProduct({
          ...values,
          input: transformedValues,
        });
      },
    });

  // useEffects.success
  useEffect(() => {
    if (createPaymentProductData) {
      console.log(createPaymentProductData, "data");
      resetForm();
      enqueueSnackbar(t("main.paymentProduct.paymentProduct.createdSuccessfully"), {
        variant: "success",
      });
    }
  }, [createPaymentProductData, resetForm]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      createPaymentProductError as IBaseError,
      currentLanguage
    );
  }, [createPaymentProductError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    dispatch(paymentProductActions.setTitle(t("main.paymentProduct.paymentProduct.create")));
    dispatch(paymentProductActions.setBackButton(true));
    dispatch(paymentProductActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2}>

        <TextField
          fullWidth
          label={t("main.paymentProduct.paymentProduct.name")}
          value={(values as any).input.name}
          onChange={e => setFieldValue("input.name", e.target.value)}
        />

        <TextField
          fullWidth
          multiline
          rows={2}
          label={t("main.paymentProduct.paymentProduct.description")}
          value={(values as any).input.description}
          onChange={e => setFieldValue("input.description", e.target.value)}
        />

        <Autocomplete
          fullWidth
          options={Object.values(Currency)}
          value={(values as any).input.baseCurrency}
          onChange={(event, value) => setFieldValue("input.baseCurrency", value)}
          getOptionLabel={option => option}
          renderInput={params => <TextField {...params} label={t("main.paymentProduct.paymentProduct.currency")} />}
        />

        {/* <FormControlLabel
          control={
            <Switch
              checked={(values as any).input.hasShipping}
              onChange={e => setFieldValue("input.hasShipping", e.target.checked)}
            />
          }
          label={t("main.paymentProduct.paymentProduct.hasShipping")}
        /> */}

        <FormControlLabel
          control={
            <Switch
              checked={(values as any).input.isRefundable}
              onChange={e => setFieldValue("input.isRefundable", e.target.checked)}
            />
          }
          label={t("main.paymentProduct.paymentProduct.isRefundable")}
        />

        <TextField
          fullWidth
          label={t("main.paymentProduct.paymentProduct.maxCount")}
          type="number"
          value={(values as any).input.maxCount}
          onChange={e => setFieldValue("input.maxCount", Number(e.target.value))}
        />

        <Autocomplete
          fullWidth
          options={Object.values(PaymentProductStatus)}
          value={(values as any).input.paymentProductStatus}
          onChange={(event, value) => setFieldValue("input.paymentProductStatus", value)}
          getOptionLabel={option => option}
          renderInput={params => <TextField {...params} label={t("main.paymentProduct.paymentProduct.status")} />}
        />

        {/* <Autocomplete
          fullWidth
          options={Object.values(PaymentProductType)}
          value={(values as any).input.productType}
          onChange={(event, value) => setFieldValue("input.productType", value)}
          getOptionLabel={option => option}
          renderInput={params => <TextField {...params} label={t("main.paymentProduct.paymentProduct.type")} />}
        /> */}

        {/* <TextField
          fullWidth
          label={t("main.paymentProduct.paymentProduct.quota")}
          type="number"
          value={(values as any).input.quota}
          onChange={e => setFieldValue("input.quota", Number(e.target.value))}
        /> */}

        <FormControlLabel
          control={
            <Switch
              checked={(values as any).input.soldOut}
              onChange={e => setFieldValue("input.soldOut", e.target.checked)}
            />
          }
          label={t("main.paymentProduct.paymentProduct.soldOut")}
        />

        <FormControlLabel
          control={
            <Switch
              checked={(values as any).input.isUpgradable}
              onChange={e => setFieldValue("input.isUpgradable", e.target.checked)}
            />
          }
          label={t("main.paymentProduct.paymentProduct.isUpgradable")}
        />

        <Autocomplete
          fullWidth
          options={[1, 2, 3, 4, 5]}
          value={(values as any).input.recurring.level}
          onChange={(event, value) => setFieldValue("input.recurring.level", value)}
          getOptionLabel={option => option}
          renderInput={params => <TextField {...params} label={t("main.paymentProduct.paymentProduct.recurringLevel")} />}
        />

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Stack direction="column" gap={2}>
              <Typography sx={{ borderBottom: "1px solid #E0E0E0" }}>{t("main.paymentProduct.paymentProduct.monthly")} </Typography>

              <TextField
                fullWidth
                label={t("main.paymentProduct.paymentProduct.packageName")}
                value={(values as any).input.recurring.monthly.packageName}
                onChange={e => setFieldValue("input.recurring.monthly.packageName", e.target.value)}
              />

              <TextField
                fullWidth
                label={t("main.paymentProduct.paymentProduct.storageLimit")}
                value={(values as any).input.recurring.monthly.entitlements.storageLimit}
                onChange={e => setFieldValue("input.recurring.monthly.entitlements.storageLimit", Number(e.target.value))}
              />

              <TextField
                fullWidth
                label={t("main.paymentProduct.paymentProduct.userLimit")}
                value={(values as any).input.recurring.monthly.entitlements.userLimit}
                onChange={e => setFieldValue("input.recurring.monthly.entitlements.userLimit", Number(e.target.value))}
              />

              <TextField
                fullWidth
                label={t("main.paymentProduct.paymentProduct.basePrice")}
                value={(values as any).input.recurring.monthly?.pricing?.basePrice}
                onChange={e => setFieldValue("input.recurring.monthly.pricing.basePrice", Number(e.target.value))}
              />

              <TextField
                fullWidth
                label={t("main.paymentProduct.paymentProduct.discountedPrice")}
                value={(values as any).input.recurring.monthly?.pricing?.discountedPrice}
                onChange={e => setFieldValue("input.recurring.monthly.pricing.discountedPrice", Number(e.target.value))}
              />

              <TextField
                fullWidth
                label={t("main.paymentProduct.paymentProduct.discountRate")}
                value={(values as any).input.recurring.monthly?.pricing?.discountRate}
                onChange={e => setFieldValue("input.recurring.monthly.pricing.discountRate", Number(e.target.value))}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack direction="column" gap={2}>
              <Typography sx={{ borderBottom: "1px solid #E0E0E0" }}>{t("main.paymentProduct.paymentProduct.yearly")} </Typography>

              <TextField
                fullWidth
                label={t("main.paymentProduct.paymentProduct.packageName")}
                value={(values as any).input.recurring.yearly.packageName}
                onChange={e => setFieldValue("input.recurring.yearly.packageName", e.target.value)}
              />

              <TextField
                fullWidth
                label={t("main.paymentProduct.paymentProduct.storageLimit")}
                value={(values as any).input.recurring.yearly.entitlements.storageLimit}
                onChange={e => setFieldValue("input.recurring.yearly.entitlements.storageLimit", Number(e.target.value))}
              />

              <TextField
                fullWidth
                label={t("main.paymentProduct.paymentProduct.userLimit")}
                value={(values as any).input.recurring.yearly.entitlements.userLimit}
                onChange={e => setFieldValue("input.recurring.yearly.entitlements.userLimit", Number(e.target.value))}
              />

              <TextField
                fullWidth
                label={t("main.paymentProduct.paymentProduct.basePrice")}
                value={(values as any).input.recurring.yearly?.pricing?.basePrice}
                onChange={e => setFieldValue("input.recurring.yearly.pricing.basePrice", Number(e.target.value))}
              />

              <TextField
                fullWidth
                label={t("main.paymentProduct.paymentProduct.discountedPrice")}
                value={(values as any).input.recurring.yearly?.pricing?.discountedPrice}
                onChange={e => setFieldValue("input.recurring.yearly.pricing.discountedPrice", Number(e.target.value))}
              />

              <TextField
                fullWidth
                label={t("main.paymentProduct.paymentProduct.discountRate")}
                value={(values as any).input.recurring.yearly?.pricing?.discountRate}
                onChange={e => setFieldValue("input.recurring.yearly.pricing.discountRate", Number(e.target.value))}
              />

            </Stack>
          </Grid>
        </Grid>

        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
          disabled={createPaymentProductIsLoading}
        >
          {createPaymentProductIsLoading ? (
            <CircularProgress size="1rem" sx={{ mr: 2 }} />
          ) : (
            ""
          )}
          Create
        </Button>
      </Stack>
    </Grid>
  );
};

export default Create;
