import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { t } from "i18next";
import { useEffect } from "react";
import { useDeleteSubscriptionMutation } from "../context/subscription.api";

import { IBaseError } from "corede-common";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { IBaseSubscriptionFormEntity } from "corede-common-cocrm";

const Delete = (prop: {
  open: boolean;
  item: IBaseSubscriptionFormEntity;
  onClose: () => void;
}) => {
  // general
  const currentLanguage = getCurrentLanguage();
  
  // queries
  
  // mutations
  const [
    deleteSubscription,
    {
      data: deleteSubscriptionData,
      isLoading: deleteSubscriptionIsLoading,
      error: deleteSubscriptionError,
    },
  ] = useDeleteSubscriptionMutation();
  
  // constants
  
  // action handlers
  const handleDelete = (_id: string) => {
    deleteSubscription({ input: { _id: _id } });
  };

  // useEffects.success
  useEffect(() => {
    if (deleteSubscriptionData) {
      prop?.onClose();
      enqueueSnackbar(t("main.subscription.subscription.deletedSuccessfully"), {
        variant: "success",
      });
    }
  }, [deleteSubscriptionData]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      deleteSubscriptionError as IBaseError,
      currentLanguage
    );
  }, [deleteSubscriptionError, currentLanguage]);

  // useEffects.init

  return (
    <Dialog
      open={prop?.open}
      onClose={prop?.onClose}
      sx={{
        "& .MuiDialog-paper": {
          padding: 1,
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle>{t("main.subscription.subscription.delete")}</DialogTitle>
      <DialogContent>
        {t("main.subscription.subscription.deleteConfirm")} {prop?.item?.email}?
      </DialogContent>
      <DialogActions>
        <Button onClick={prop?.onClose} color="primary">
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="error"
          disabled={deleteSubscriptionIsLoading}
          onClick={() => {
            handleDelete(prop?.item?._id);
          }}
        >
          {t("delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Delete;
