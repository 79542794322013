

import { SubscriptionRouter } from "../subdomains/subscription/routes/SubscriptionRouter";
import { SubscriptionDomainLayout } from "../layouts";
import { subscriptionDomainRoutes } from "./subscription.base.route";
import { Route, Routes } from "react-router-dom";

export const SubscriptionDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<SubscriptionDomainLayout />}>
        <Route index element={<SubscriptionRouter />} />
        <Route
          path={`/${subscriptionDomainRoutes.subdomains.subscription.base}/*`}
          element={<SubscriptionRouter />}
        />
        {/*
          // TODO: import subdomains
        */}
      </Route>
    </Routes>
  );
};
