import faqLocale from "../subdomains/faq/locales/en";
import faqCategoryLocale from "../subdomains/faqCategory/locales/en";

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////

  faq: {
    ...faqLocale,
  },
  faqCategory: {
    ...faqCategoryLocale,
  },
};

export default locale;
