import { Card, CircularProgress, Grid, Stack, TextField, Typography } from "@mui/material";
import { useHelpCenterCategoryQuery } from "../context/helpCenterCategory.api";
import { useAppDispatch } from "context";
import { helpCenterActions } from "../../../context";
import { useEffect } from "react";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import { IBaseError } from "corede-common";
import { getCurrentLanguage } from "localization";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { listHelpCenterCategoryRoute, updateHelpCenterCategoryRoute } from "../../../routes/helpCenter.base.route";

const View = () => {
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const { id } = useParams();
  if (!id) {
    navigate(listHelpCenterCategoryRoute());
  }
  // queries
  const {
    data: viewHelpCenterCategoryData,
    isLoading: viewHelpCenterCategoryIsLoading,
    error: viewHelpCenterCategoryError,
  } = useHelpCenterCategoryQuery({
    input: { _id: id! },
  });

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      viewHelpCenterCategoryError as IBaseError,
      currentLanguage
    );
  }, [viewHelpCenterCategoryError, currentLanguage]);

  useEffect(() => {
    dispatch(helpCenterActions.setTitle(t("main.helpCenter.helpCenterCategory.view")));
    dispatch(helpCenterActions.setBackButton(true));
    dispatch(
      helpCenterActions.setRightButton({
        title: t(`main.helpCenter.helpCenterCategory.update`),
        path: updateHelpCenterCategoryRoute(id!),
        icon: null,
      })
    );
  }, [dispatch, id]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2} maxWidth={400}>
        {viewHelpCenterCategoryIsLoading ? (
          <CircularProgress />
        ) : (
          <Stack direction="column" gap={2}>
            <Card sx={{ p: 4 }}>
              <Stack direction="column" gap={2}>
                <Typography><b>{t("main.helpCenter.helpCenterCategory.name")}</b>: {viewHelpCenterCategoryData?.name}</Typography>
                <Typography><b>{t("main.helpCenter.helpCenterCategory.icon")}</b>: {viewHelpCenterCategoryData?.icon}</Typography>
                <Typography><b>{t("main.helpCenter.helpCenterCategory.parentCategory")}</b>: {viewHelpCenterCategoryData?.parentCategory?.name ?? "-"}</Typography>
                <Typography><b>{t("main.helpCenter.helpCenterCategory.name") + " En"}</b>: {viewHelpCenterCategoryData?.nameTranslation?.en}</Typography>
                <Typography><b>{t("main.helpCenter.helpCenterCategory.name") + " Tr"}</b>: {viewHelpCenterCategoryData?.nameTranslation?.tr}</Typography>
              </Stack>
            </Card>
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default View;
