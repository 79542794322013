const locale = {
  faqCategory: "Faq Category",
  faqCategories: "Faq Categories",
  create: "Create Faq Category",
  update: "Update Faq Category",
  delete: "Delete Faq Category",
  view: "View Faq Category",
  list: "List Faq Categories",
  createdSuccessfully: "Faq Category created successfully",
  updatedSuccessfully: "Faq Category updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Faq Category deleted successfully",
  name: "Name",
  icon: "Icon",
  parentCategory: "Parent Category",
};

export default locale;
