import {
  IFaqCategoriesInput,
  IFaqCategoriesRequest,
  IFaqCategoriesResponse,
  IFaqCategoriesResult,
  faqCategoryQuery,
  faqCategoriesQuery,
  IFaqCategoryInput,
  IFaqCategoryRequest,
  IFaqCategoryResponse,
  IFaqCategoryResult,
  ICreateFaqCategoryInput,
  ICreateFaqCategoryRequest,
  ICreateFaqCategoryResponse,
  ICreateFaqCategoryResult,
  createFaqCategoryQuery,
  IUpdateFaqCategoryInput,
  IUpdateFaqCategoryRequest,
  IUpdateFaqCategoryResponse,
  IUpdateFaqCategoryResult,
  updateFaqCategoryQuery,
  IUpdateFaqCategoryFilterInput,
  deleteFaqCategoryQuery,
  IDeleteFaqCategoryInput,
  IDeleteFaqCategoryRequest,
  IDeleteFaqCategoryResponse,
  IDeleteFaqCategoryResult,
} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { faqCategoryTags } from "./faqCategory.tags";
import { IGraphqlVariables } from "corede-common";

export const faqCategoryApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    faqCategories: builder.query<
      IFaqCategoriesResult,
      IGraphqlVariables<IFaqCategoriesInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IFaqCategoriesRequest,
        IFaqCategoriesResponse,
        IFaqCategoriesResult,
        IFaqCategoriesInput
      >({
        query: faqCategoriesQuery,
        providesTags: [faqCategoryTags.faqCategories],
      })
    ),

    faqCategory: builder.query<
      IFaqCategoryResult,
      IGraphqlVariables<IFaqCategoryInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IFaqCategoryRequest,
        IFaqCategoryResponse,
        IFaqCategoryResult,
        IFaqCategoryInput
      >({
        query: faqCategoryQuery,
        providesTags: [faqCategoryTags.faqCategory],
      })
    ),

    // mutation
    createFaqCategory: builder.mutation<
      ICreateFaqCategoryResult,
      IGraphqlVariables<ICreateFaqCategoryInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICreateFaqCategoryRequest,
        ICreateFaqCategoryResponse,
        ICreateFaqCategoryResult,
        ICreateFaqCategoryInput
      >({
        query: createFaqCategoryQuery,
        invalidatesTags: [faqCategoryTags.faqCategories],
      })
    ),

    updateFaqCategory: builder.mutation<
      IUpdateFaqCategoryResult,
      IGraphqlVariables<
        IUpdateFaqCategoryInput,
        IUpdateFaqCategoryFilterInput
      >
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUpdateFaqCategoryRequest,
        IUpdateFaqCategoryResponse,
        IUpdateFaqCategoryResult,
        IUpdateFaqCategoryInput,
        IUpdateFaqCategoryFilterInput
      >({
        query: updateFaqCategoryQuery,
        invalidatesTags: [
          faqCategoryTags.faqCategories,
          faqCategoryTags.faqCategory,
        ],
      })
    ),

    deleteFaqCategory: builder.mutation<
      IDeleteFaqCategoryResult,
      IGraphqlVariables<IDeleteFaqCategoryInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IDeleteFaqCategoryRequest,
        IDeleteFaqCategoryResponse,
        IDeleteFaqCategoryResult,
        IDeleteFaqCategoryInput
      >({
        query: deleteFaqCategoryQuery,
        invalidatesTags: [
          faqCategoryTags.faqCategories,
          faqCategoryTags.faqCategory,
        ],
      })
    ),
  }),
});

export const {
  useFaqCategoriesQuery,
  useLazyFaqCategoriesQuery,

  useFaqCategoryQuery,
  useLazyFaqCategoryQuery,

  useCreateFaqCategoryMutation,
  useUpdateFaqCategoryMutation,
  useDeleteFaqCategoryMutation,
} = faqCategoryApi;
