import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  MenuItem,
} from "@mui/material";
import {
  useCreateAuthorMutation,
} from "../context/author.api";
import { useFormik } from "formik";
import { useAppDispatch } from "context";
import { blogActions } from "../../../context";
import { IAuthorInput, ICreateAuthorInput } from "corede-common-cocrm";
import { IBaseError, IGraphqlVariables } from "corede-common";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { validateCreateAuthorInput } from "../validations/create.validation";
import { t } from "i18next";
import { RequestTransformerHelper } from "validations/request.transformer.helper";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";

const Create = () => {
  // general
  const dispatch = useAppDispatch();
  const currentLanguage = getCurrentLanguage();

  // queries

  // mutations
  const [
    createAuthor,
    {
      data: createAuthorData,
      isLoading: createAuthorIsLoading,
      error: createAuthorError,
    },
  ] = useCreateAuthorMutation();

  // constants
  const { values, handleSubmit, handleBlur, setFieldValue, errors, resetForm } =
    useFormik({
      initialValues: {
        input: {
          name: "",
          bio: "",
          facebook: "",
          instagram: "",
          linkedIn: "",
          x: ""
        },
      },
      enableReinitialize: true,
      validationSchema: validateCreateAuthorInput,
      onSubmit: (values: IGraphqlVariables<ICreateAuthorInput>) => {
        const transformedValues =
          RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
            input: values.input,
          });
        createAuthor({
          ...values,
          input: transformedValues,
        });
      },
    });

  // useEffects.success
  useEffect(() => {
    if (createAuthorData) {
      console.log(createAuthorData, "data");
      resetForm();
      enqueueSnackbar(t("main.blog.author.createdSuccessfully"), {
        variant: "success",
      });
    }
  }, [createAuthorData, resetForm]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      createAuthorError as IBaseError,
      currentLanguage
    );
  }, [createAuthorError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    dispatch(blogActions.setTitle(t("main.blog.author.create")));
    dispatch(blogActions.setBackButton(true));
    dispatch(blogActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2} maxWidth={400}>

        <TextField
          fullWidth
          name="name"
          label={t("main.blog.author.name")}
          variant="outlined"
          value={(values as any).input.name}
          onChange={(e => setFieldValue("input.name", e.target.value))}
          onBlur={() => handleBlur("input.name")}
          error={Boolean((errors as any).input?.name)}
          helperText={(errors as any).input?.name}
        />

        <TextField
          fullWidth
          name="bio"
          label={t("main.blog.author.bio")}
          multiline
          rows={3}
          value={(values as any).input.bio}
          onChange={(e => setFieldValue("input.bio", e.target.value))}
          onBlur={() => handleBlur("input.bio")}
          error={Boolean((errors as any).input?.bio)}
          helperText={(errors as any).input?.bio}
        />

        <TextField
          fullWidth
          name="facebook"
          label="Facebook"
          value={(values as any).input.facebook}
          onChange={(e => setFieldValue("input.facebook", e.target.value))}
          onBlur={() => handleBlur("input.facebook")}
          error={Boolean((errors as any).input?.facebook)}
          helperText={(errors as any).input?.facebook}
        />

        <TextField
          fullWidth
          id="instagram"
          name="instagram"
          label="Instagram"
          value={(values as any).input.instagram}
          onChange={(e => setFieldValue("input.instagram", e.target.value))}
          onBlur={() => handleBlur("input.instagram")}
          error={Boolean((errors as any).input?.instagram)}
          helperText={(errors as any).input?.instagram}
        />

        <TextField
          fullWidth
          id="linkedIn"
          name="linkedIn"
          label="LinkedIn"
          value={(values as any).input.linkedIn}
          onChange={(e => setFieldValue("input.linkedIn", e.target.value))}
          onBlur={() => handleBlur("input.linkedIn")}
          error={Boolean((errors as any).input?.linkedIn)}
          helperText={(errors as any).input?.linkedIn}
        />

        <TextField
          fullWidth
          id="x"
          name="x"
          label="X"
          value={(values as any).input.x}
          onChange={(e => setFieldValue("input.x", e.target.value))}
          onBlur={() => handleBlur("input.x")}
          error={Boolean((errors as any).input?.x)}
          helperText={(errors as any).input?.x}
        />


        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
          disabled={createAuthorIsLoading || !(values as any).input.name}
        >
          {createAuthorIsLoading ? (
            <CircularProgress size="1rem" sx={{ mr: 2 }} />
          ) : (
            ""
          )}
          Create
        </Button>
      </Stack>
    </Grid>
  );
};

export default Create;
