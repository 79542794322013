import {
  Grid,
  Stack,
  Button,
  Skeleton,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
} from "@mui/material";
import { useAppDispatch } from "context";
import { formActions } from "../../../context";
import { updateContactFormRoute, viewContactFormRoute, } from "../../../routes/form.base.route";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useContactFormsQuery } from "../context/contactForm.api";
import { Delete as DeleteIcon, Edit, RemoveRedEye } from "@mui/icons-material";
import { MyTablePagination } from "components";
import { IBaseError, IGraphqlVariables, TNullable } from "corede-common";
import {
  IBaseContactFormEntity,
  IContactFormsInput,
} from "corede-common-cocrm";
import { getCurrentLanguage } from "localization";
import { useNavigate } from "react-router-dom";
import Delete from "./Delete";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import moment from "moment";

const List = () => {
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();

  // useStates
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 0,
  });
  const [selectedItemForDelete, setSelectedItemForDelete] =
    useState<TNullable<IBaseContactFormEntity>>(null);
  const [variables, setVariables] = useState<
    IGraphqlVariables<IContactFormsInput>
  >({
    input: {
      pagination: {
        pageSize: pagination.pageSize,
        page: pagination.page + 1,
      },
    },
  });

  // queries
  const {
    data: contactFormsData,
    isLoading: contactFormsIsLoading,
    error: contactFormsError,
  } = useContactFormsQuery(variables);

  // mutations

  // constants

  // action handlers
  const handleEdit = (id: string) => {
    navigate(updateContactFormRoute(id));
  };

  const handleDelete = (item: IBaseContactFormEntity) => {
    setSelectedItemForDelete(item);
  };

  const handleView = (id: string) => {
    navigate(viewContactFormRoute(id));
  };

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      contactFormsError as IBaseError,
      currentLanguage
    );
  }, [contactFormsError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    setVariables({
      input: {
        pagination: {
          pageSize: pagination.pageSize,
          page: pagination.page + 1,
        },
      },
    });
  }, [pagination]);

  useEffect(() => {
    dispatch(formActions.setTitle(t("main.form.contactForm.contactForms")));
    dispatch(formActions.setBackButton(false));
    dispatch(formActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Card sx={{ p: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>{t("fullName")}</TableCell>
                <TableCell>{t("email")}</TableCell>
                <TableCell>{t("subject")}</TableCell>
                <TableCell>{t("status")}</TableCell>
                <TableCell>{t("createdAt")}</TableCell>
                <TableCell align="right">{t("actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!contactFormsIsLoading
                ? contactFormsData?.data?.map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{(pagination.page ?? 0) * pagination.pageSize + index + 1}</TableCell>
                    <TableCell>{row.fullName}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.subject}</TableCell>
                    <TableCell><Chip label={row.status} /></TableCell>
                    <TableCell>{moment(row.createdAt).format("DD.MM.YYYY HH:mm")}</TableCell>
                    <TableCell align="right">
                      <Stack direction="row" justifyContent={"flex-end"} gap={1} flexWrap={"wrap"}>
                        <Button
                          onClick={() => handleView(row._id)}
                          variant="outlined"
                          size="small"
                          sx={{ height: 32, minWidth: 10 }}
                        >
                          <RemoveRedEye sx={{ fontSize: "16px" }} />
                        </Button>
                        <Button
                          onClick={() => handleEdit(row._id)}
                          variant="outlined"
                          size="small"
                          sx={{ height: 32, minWidth: 10 }}
                        >
                          <Edit sx={{ fontSize: "16px" }} />
                        </Button>
                        <Button
                          onClick={() => handleDelete(row)}
                          variant="outlined"
                          size="small"
                          color="error"
                          sx={{ height: 32, minWidth: 10 }}
                        >
                          <DeleteIcon sx={{ fontSize: "16px" }} />
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
                : [1, 2, 3].map((key) => (
                  <TableRow key={key}>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <Stack
        direction="row"
        justifyContent={{ xs: "center", md: "flex-start" }}
        mt={2}
        mb={2}
      >
        <MyTablePagination
          count={contactFormsData?.count ?? 0}
          pageSize={pagination.pageSize}
          page={pagination.page ?? 0}
          onPageChange={(e, page) => {
            setPagination({
              ...pagination,
              page: page,
            });
          }}
          onRowsPerPageChange={(e) => {
            setPagination({
              page: 0,
              pageSize: parseInt(e.target.value)
            });
          }}
        />
      </Stack>

      <Delete
        open={!!selectedItemForDelete}
        item={selectedItemForDelete!}
        onClose={() => {
          setSelectedItemForDelete(null);
        }}
      />
    </Grid>
  );
};

export default List;
