import { Card, CircularProgress, Grid, Stack, TextField, Typography } from "@mui/material";
import { useHelpCenterQuery } from "../context/helpCenter.api";
import { useAppDispatch } from "context";
import { helpCenterActions } from "../../../context";
import { useEffect } from "react";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import { IBaseError } from "corede-common";
import { getCurrentLanguage } from "localization";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { listHelpCenterRoute, updateHelpCenterRoute } from "../../../routes/helpCenter.base.route";


// question: string; // unique
// answer: string;
// readingTime: string;
// category: IBaseHelpCenterCategoryEntity;
// language: Language;

const View = () => {
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const { id } = useParams();
  if (!id) {
    navigate(listHelpCenterRoute());
  }
  // queries
  const {
    data: viewHelpCenterData,
    isLoading: viewHelpCenterIsLoading,
    error: viewHelpCenterError,
  } = useHelpCenterQuery({
    input: { _id: id! },
  });

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      viewHelpCenterError as IBaseError,
      currentLanguage
    );
  }, [viewHelpCenterError, currentLanguage]);

  useEffect(() => {
    dispatch(helpCenterActions.setTitle(t("main.helpCenter.helpCenter.view")));
    dispatch(helpCenterActions.setBackButton(true));
    dispatch(
      helpCenterActions.setRightButton({
        title: t(`main.helpCenter.helpCenter.update`),
        path: updateHelpCenterRoute(id!),
        icon: null,
      })
    );
  }, [dispatch, id]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2}>
        {viewHelpCenterIsLoading ? (
          <CircularProgress />
        ) : (
          <Stack direction="column" gap={2}>
            <Card sx={{ p: 4 }}>
              <Typography><b>{t("main.helpCenter.helpCenter.question")}:</b> {viewHelpCenterData?.question}</Typography>
              <Typography><b>{t("main.helpCenter.helpCenter.answer")}:</b> {viewHelpCenterData?.answer}</Typography>
              <Typography><b>{t("main.helpCenter.helpCenter.readingTime")}:</b> {viewHelpCenterData?.readingTime}</Typography>
              <Typography><b>{t("main.helpCenter.helpCenter.category")}:</b> {viewHelpCenterData?.category.name}</Typography>
              <Typography><b>{t("main.helpCenter.helpCenter.language")}:</b> {viewHelpCenterData?.language}</Typography>
            </Card>
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default View;
