import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  useCreateNotificationMutation,
} from "../context/notification.api";
import { useFormik } from "formik";
import { useAppDispatch } from "context";
import { notificationActions } from "../../../context";
import { INotificationCreateInput, NotificationChannelType, NotificationType } from "corede-common-cocrm";
import { IBaseError, IGraphqlVariables } from "corede-common";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { validateCreateNotificationInput } from "../validations/create.validation";
import { t } from "i18next";
import { RequestTransformerHelper } from "validations/request.transformer.helper";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { ExpandMore } from "@mui/icons-material";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import parse from "html-react-parser";

const Create = () => {
  // general
  const dispatch = useAppDispatch();
  const currentLanguage = getCurrentLanguage();
  const [variable, setVariable] = useState<string>("");
  // queries

  // mutations
  const [
    createNotification,
    {
      data: createNotificationData,
      isLoading: createNotificationIsLoading,
      error: createNotificationError,
    },
  ] = useCreateNotificationMutation();

  // constants
  const { values, handleSubmit, handleBlur, setFieldValue, errors, resetForm } =
    useFormik({
      initialValues: {
        input: {
          domain: "",
          name: "",
          type: NotificationType.user,
          channels: {
            email: {
              content: "",
              type: NotificationChannelType.email,
              subject: "",
              variables: [],
            },
            web: {
              content: "",
              type: NotificationChannelType.web,
              variables: [],
              subject: "",
            },
            push: {
              content: "",
              type: NotificationChannelType.push,
              variables: [],
              subject: "",
            },
            sms: {
              content: "",
              type: NotificationChannelType.sms,
              variables: [],
              subject: "",
            }
          },
        },
      },
      enableReinitialize: true,
      validationSchema: validateCreateNotificationInput,
      onSubmit: (values: IGraphqlVariables<INotificationCreateInput>) => {
        const transformedValues =
          RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
            input: values.input,
          });
        createNotification({
          ...values,
          input: transformedValues,
        });
      },
    });

  // useEffects.success
  useEffect(() => {
    if (createNotificationData) {
      console.log(createNotificationData, "data");
      resetForm();
      enqueueSnackbar(t("main.notification.notification.createdSuccessfully"), {
        variant: "success",
      });
    }
  }, [createNotificationData, resetForm]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      createNotificationError as IBaseError,
      currentLanguage
    );
  }, [createNotificationError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    dispatch(notificationActions.setTitle(t("main.notification.notification.create")));
    dispatch(notificationActions.setBackButton(true));
    dispatch(notificationActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2}>

        <TextField
          fullWidth
          name="input.domain"

          label={t("main.notification.notification.domain")}
          value={(values as any).input.domain}
          onChange={(e) => setFieldValue("input.domain", e.target.value)}
          onBlur={() => handleBlur("input.domain")}
          error={!!(errors as any).input?.domain}
          helperText={(errors as any).input?.domain}
        />

        <TextField
          fullWidth
          name="input.name"
          label={t("main.notification.notification.name")}
          value={(values as any).input.name}
          onChange={(e) => setFieldValue("input.name", e.target.value)}
          onBlur={() => handleBlur("input.name")}
          error={!!(errors as any).input?.name}
          helperText={(errors as any).input?.name}
        />

        <Autocomplete
          id="type"
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => option}
          options={Object.values(NotificationType)}
          renderInput={(params) => <TextField {...params} label={t("main.notification.notification.type")} />}
          value={(values as any).input.type}
          onChange={(e, value) => {
            setFieldValue("input.type", value);
          }}
          onBlur={() => handleBlur("input.type")}
        />

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
          >
            Email
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              fullWidth
              name="input.channels.email.subject"
              label={t("main.notification.notification.subject")}
              value={(values as any)?.input?.channels?.email?.subject || ""} // Boşsa boş string döndür
              onChange={(e) => {
                setFieldValue("input.channels.email.subject", e.target.value);
              }}
              onBlur={() => handleBlur("input.channels.email.subject")}
              error={!!(errors as any)?.input?.channels?.email?.subject}
              helperText={(errors as any)?.input?.channels?.email?.subject}
            />
            {/* <ReactQuill
              theme="snow"
              value={(values as any)?.input?.channels?.email?.content || ""}
              onChange={(value) => {
                setFieldValue("input.channels.email.content", value);
              }}
              onBlur={() => handleBlur("input.channels.email.content")}
              placeholder={t("main.notification.notification.content")}
              scrollingContainer={".ql-editor"}
              style={{ height: 300, marginBottom: 40, marginTop: 10, paddingBottom: 10 }}
            />
            {(errors as any)?.input?.channels?.email?.content && (
              <Typography color="error" variant="caption">
                {(errors as any)?.input?.channels?.email?.content}
              </Typography>
            )} */}
            <Stack p={2}>
              {parse((values as any)?.input?.channels?.email?.content || "")}
            </Stack>
            <TextField
              fullWidth
              name="input.channels.email.content"
              label={"HTML Content"}
              multiline
              rows={15}
              sx={{ my: 2 }}
              value={(values as any)?.input?.channels?.email?.content || ""}
              onChange={(e) => {
                setFieldValue("input.channels.email.content", e.target.value);
              }}
              onBlur={() => handleBlur("input.channels.email.content")}
              error={!!(errors as any)?.input?.channels?.email?.content}
              helperText={(errors as any)?.input?.channels?.email?.content}
            />


            <Autocomplete
              multiple
              freeSolo
              size="small"
              id="input.channels.email.variables"
              options={[]}
              value={(values as any).input?.channels.email?.variables || []}
              inputValue={variable}
              onInputChange={(event, newInputValue) => {
                setVariable(newInputValue);
              }}
              onChange={(event, newValue) => {
                setFieldValue("input.channels.email.variables", newValue);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ',') {
                  event.preventDefault();
                  const inputValueTrimmed = variable.trim();
                  if (inputValueTrimmed !== '') {
                    const updatedVariables = [...(values as any).input?.channels.email?.variables, inputValueTrimmed];
                    setFieldValue("input.channels.email.variables", updatedVariables);
                    setVariable('');
                  }
                }
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  return (
                    <Chip
                      key={key}
                      size="small"
                      variant="outlined"
                      label={option}
                      {...tagProps}
                    />
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={t("main.notification.notification.variables")}
                  error={Boolean((errors as any).input?.channels.email?.variables)}
                  helperText={(errors as any).input?.channels.email?.variables}
                />
              )}
            />

          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
          >
            SMS
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              fullWidth
              name="input.channels.sms.subject"
              label={t("main.notification.notification.subject")}
              value={(values as any)?.input?.channels?.sms?.subject || ""}
              onChange={(e) => {
                setFieldValue("input.channels.sms.subject", e.target.value);
              }}
              onBlur={() => handleBlur("input.channels.sms.subject")}
              error={!!(errors as any)?.input?.channels?.sms?.subject}
              helperText={(errors as any)?.input?.channels?.sms?.subject}
            />
            <ReactQuill
              theme="snow"
              value={(values as any)?.input?.channels?.sms?.content || ""}
              onChange={(value) => {
                setFieldValue("input.channels.sms.content", value);
              }}
              onBlur={() => handleBlur("input.channels.sms.content")}
              placeholder={t("main.notification.notification.content")}
              scrollingContainer={".ql-editor"}
              style={{ height: 300, marginBottom: 40, marginTop: 10, paddingBottom: 10 }}
            />
            {(errors as any)?.input?.channels?.sms?.content && (
              <Typography color="error" variant="caption">
                {(errors as any)?.input?.channels?.sms?.content}
              </Typography>
            )}
            <Autocomplete
              multiple
              freeSolo
              size="small"
              id="input.channels.sms.variables"
              options={[]}
              value={(values as any).input?.channels.sms?.variables || []}
              inputValue={variable}
              onInputChange={(event, newInputValue) => {
                setVariable(newInputValue);
              }}
              onChange={(event, newValue) => {
                setFieldValue("input.channels.sms.variables", newValue);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ',') {
                  event.preventDefault();
                  const inputValueTrimmed = variable.trim();
                  if (inputValueTrimmed !== '') {
                    const updatedVariables = [...(values as any).input?.channels.sms?.variables, inputValueTrimmed];
                    setFieldValue("input.channels.sms.variables", updatedVariables);
                    setVariable('');
                  }
                }
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  return (
                    <Chip
                      key={key}
                      size="small"
                      variant="outlined"
                      label={option}
                      {...tagProps}
                    />
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={t("main.notification.notification.variables")}
                  error={Boolean((errors as any).input?.channels.sms?.variables)}
                  helperText={(errors as any).input?.channels.sms?.variables}
                />
              )}
            />

          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
          >
            WEB
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              fullWidth
              name="input.channels.web.subject"
              label={t("main.notification.notification.subject")}
              value={(values as any)?.input?.channels?.web?.subject || ""}
              onChange={(e) => {
                setFieldValue("input.channels.web.subject", e.target.value);
              }}
              onBlur={() => handleBlur("input.channels.web.subject")}
              error={!!(errors as any)?.input?.channels?.web?.subject}
              helperText={(errors as any)?.input?.channels?.web?.subject}
            />
            {/* <ReactQuill
              theme="snow"
              value={(values as any)?.input?.channels?.web?.content || ""}
              onChange={(value) => {
                setFieldValue("input.channels.web.content", value);
              }}
              onBlur={() => handleBlur("input.channels.web.content")}
              placeholder={t("main.notification.notification.content")}
              scrollingContainer={".ql-editor"}
              style={{ height: 300, marginBottom: 40, marginTop: 10, paddingBottom: 10 }}
            />
            {(errors as any)?.input?.channels?.web?.content && (
              <Typography color="error" variant="caption">
                {(errors as any)?.input?.channels?.web?.content}
              </Typography>
            )} */}

            <Stack p={2}>
              {parse((values as any)?.input?.channels?.web?.content || "")}
            </Stack>
            <TextField
              fullWidth
              name="input.channels.web.content"
              label={"HTML Content"}
              multiline
              rows={15}
              sx={{ my: 2 }}
              value={(values as any)?.input?.channels?.web?.content || ""}
              onChange={(e) => {
                setFieldValue("input.channels.web.content", e.target.value);
              }}
              onBlur={() => handleBlur("input.channels.web.content")}
              error={!!(errors as any)?.input?.channels?.web?.content}
              helperText={(errors as any)?.input?.channels?.web?.content}
            />


            <Autocomplete
              multiple
              freeSolo
              size="small"
              id="input.channels.web.variables"
              options={[]}
              value={(values as any).input?.channels.web?.variables || []}
              inputValue={variable}
              onInputChange={(event, newInputValue) => {
                setVariable(newInputValue);
              }}
              onChange={(event, newValue) => {
                setFieldValue("input.channels.web.variables", newValue);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ',') {
                  event.preventDefault();
                  const inputValueTrimmed = variable.trim();
                  if (inputValueTrimmed !== '') {
                    const updatedVariables = [...(values as any).input?.channels.web?.variables, inputValueTrimmed];
                    setFieldValue("input.channels.web.variables", updatedVariables);
                    setVariable('');
                  }
                }
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  return (
                    <Chip
                      key={key}
                      size="small"
                      variant="outlined"
                      label={option}
                      {...tagProps}
                    />
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={t("main.notification.notification.variables")}
                  error={Boolean((errors as any).input?.channels.web?.variables)}
                  helperText={(errors as any).input?.channels.web?.variables}
                />
              )}
            />

          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
          >
            PUSH
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              fullWidth
              name="input.channels.push.subject"
              label={t("main.notification.notification.subject")}
              value={(values as any)?.input?.channels?.push?.subject || ""}
              onChange={(e) => {
                setFieldValue("input.channels.push.subject", e.target.value);
              }}
              onBlur={() => handleBlur("input.channels.push.subject")}
              error={!!(errors as any)?.input?.channels?.push?.subject}
              helperText={(errors as any)?.input?.channels?.push?.subject}
            />
            <ReactQuill
              theme="snow"
              value={(values as any)?.input?.channels?.push?.content || ""}
              onChange={(value) => {
                setFieldValue("input.channels.push.content", value);
              }}
              onBlur={() => handleBlur("input.channels.push.content")}
              placeholder={t("main.notification.notification.content")}
              scrollingContainer={".ql-editor"}
              style={{ height: 300, marginBottom: 40, marginTop: 10, paddingBottom: 10 }}
            />
            {(errors as any)?.input?.channels?.push?.content && (
              <Typography color="error" variant="caption">
                {(errors as any)?.input?.channels?.push?.content}
              </Typography>
            )}
            <Autocomplete
              multiple
              freeSolo
              size="small"
              id="input.channels.push.variables"
              options={[]}
              value={(values as any).input?.channels.push?.variables || []}
              inputValue={variable}
              onInputChange={(event, newInputValue) => {
                setVariable(newInputValue);
              }}
              onChange={(event, newValue) => {
                setFieldValue("input.channels.push.variables", newValue);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ',') {
                  event.preventDefault();
                  const inputValueTrimmed = variable.trim();
                  if (inputValueTrimmed !== '') {
                    const updatedVariables = [...(values as any).input?.channels.push?.variables, inputValueTrimmed];
                    setFieldValue("input.channels.push.variables", updatedVariables);
                    setVariable('');
                  }
                }
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  return (
                    <Chip
                      key={key}
                      size="small"
                      variant="outlined"
                      label={option}
                      {...tagProps}
                    />
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={t("main.notification.notification.variables")}
                  error={Boolean((errors as any).input?.channels.push?.variables)}
                  helperText={(errors as any).input?.channels.push?.variables}
                />
              )}
            />

          </AccordionDetails>
        </Accordion>

        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
          disabled={createNotificationIsLoading}
        >
          {createNotificationIsLoading ? (
            <CircularProgress size="1rem" sx={{ mr: 2 }} />
          ) : (
            ""
          )}
          Create
        </Button>
      </Stack>
    </Grid>
  );
};

export default Create;
