const locale = {
  webNotification: "Web Notification",
  webNotifications: "Web Notifications",
  create: "Create Web Notification",
  update: "Update Web Notification",
  delete: "Delete Web Notification",
  view: "View Web Notification",
  list: "List Web Notifications",
  createdSuccessfully: "Web Notification created successfully",
  updatedSuccessfully: "Web Notification updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Web Notification deleted successfully",
  user: "User",
  notification: "Notification",
  isRead: "Is Read",
  preview: "Preview",
  targetId: "Target Id",
  targetModel: "Target Model",
};

export default locale;
