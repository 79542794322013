import {
  IPoliciesInput,
  IPoliciesRequest,
  IPoliciesResponse,
  IPoliciesResult,
  policyQuery,
  policiesQuery,
  IPolicyInput,
  IPolicyRequest,
  IPolicyResponse,
  IPolicyResult,
  ICreatePolicyInput,
  ICreatePolicyRequest,
  ICreatePolicyResponse,
  ICreatePolicyResult,
  createPolicyQuery,
  IUpdatePolicyInput,
  IUpdatePolicyRequest,
  IUpdatePolicyResponse,
  IUpdatePolicyResult,
  updatePolicyQuery,
  IUpdatePolicyFilterInput,
  deletePolicyQuery,
  IDeletePolicyInput,
  IDeletePolicyRequest,
  IDeletePolicyResponse,
  IDeletePolicyResult,
} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { policyTags } from "./policy.tags";
import { IGraphqlVariables } from "corede-common";

export const policyApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    policies: builder.query<
      IPoliciesResult,
      IGraphqlVariables<IPoliciesInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IPoliciesRequest,
        IPoliciesResponse,
        IPoliciesResult,
        IPoliciesInput
      >({
        query: policiesQuery,
        providesTags: [policyTags.policies],
      })
    ),

    policy: builder.query<
      IPolicyResult,
      IGraphqlVariables<IPolicyInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IPolicyRequest,
        IPolicyResponse,
        IPolicyResult,
        IPolicyInput
      >({
        query: policyQuery,
        providesTags: [policyTags.policy],
      })
    ),

    // mutation
    createPolicy: builder.mutation<
      ICreatePolicyResult,
      IGraphqlVariables<ICreatePolicyInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICreatePolicyRequest,
        ICreatePolicyResponse,
        ICreatePolicyResult,
        ICreatePolicyInput
      >({
        query: createPolicyQuery,
        invalidatesTags: [policyTags.policies],
      })
    ),

    updatePolicy: builder.mutation<
      IUpdatePolicyResult,
      IGraphqlVariables<
        IUpdatePolicyInput,
        IUpdatePolicyFilterInput
      >
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUpdatePolicyRequest,
        IUpdatePolicyResponse,
        IUpdatePolicyResult,
        IUpdatePolicyInput,
        IUpdatePolicyFilterInput
      >({
        query: updatePolicyQuery,
        invalidatesTags: [
          policyTags.policies,
          policyTags.policy,
        ],
      })
    ),

    deletePolicy: builder.mutation<
      IDeletePolicyResult,
      IGraphqlVariables<IDeletePolicyInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IDeletePolicyRequest,
        IDeletePolicyResponse,
        IDeletePolicyResult,
        IDeletePolicyInput
      >({
        query: deletePolicyQuery,
        invalidatesTags: [
          policyTags.policies,
          policyTags.policy,
        ],
      })
    ),
  }),
});

export const {
  usePoliciesQuery,
  useLazyPoliciesQuery,

  usePolicyQuery,
  useLazyPolicyQuery,

  useCreatePolicyMutation,
  useUpdatePolicyMutation,
  useDeletePolicyMutation,
} = policyApi;
