import { getTranslatedText } from "localization";
import { ValidationHelper } from "validations/validation.helper";

export const validateUpdateBlogInput =
  ValidationHelper.BuildObjectValidationSchema({
    input: ValidationHelper.BuildObjectValidationSchema({
      title: ValidationHelper.RequiredString(
        getTranslatedText("titleIsRequired")
      ).min(25, getTranslatedText("titleIsTooShort25")).max(100, getTranslatedText("titleIsTooLong100")),
      content: ValidationHelper.RequiredString(
        getTranslatedText("contentIsRequired")
      ).min(50, getTranslatedText("contentIsTooShort50")).max(250, getTranslatedText("contentIsTooLong250")),
      description: ValidationHelper.NotRequiredString().min(400, getTranslatedText("descriptionIsTooShort400"))
        .max(20000, getTranslatedText("descriptionIsTooLong20000")),
      image: ValidationHelper.NotRequiredObject(),
      readingTime: ValidationHelper.RequiredString(
        getTranslatedText("readingTimeIsRequired")
      ),
      tags: ValidationHelper.RequiredArray(
        getTranslatedText("tagsIsRequired")
      ),
      references: ValidationHelper.NotRequiredArray(),
      relatedVideo: ValidationHelper.NotRequiredString(),
      authorId: ValidationHelper.RequiredString(
        getTranslatedText("authorIsRequired")
      ),
      categoryId: ValidationHelper.RequiredString(
        getTranslatedText("categoryIsRequired")
      ),
      targetCategoryId: ValidationHelper.RequiredString(
        getTranslatedText("targetCategoryIsRequired")
      ),
      language: ValidationHelper.RequiredString(
        getTranslatedText("languageIsRequired")
      ),
    }),
  });
