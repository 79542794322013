


import { IMenuItem } from "interfaces/menu-item.interface";
import { getTranslatedText } from "localization";
import {
  baseNotificationRoute,
  listNotificationRoute,
  listWebNotificationRoute,
} from "../routes/notification.base.route";

export const notificationDomainMenuItem: IMenuItem = {
  title: getTranslatedText("notification"),
  path: baseNotificationRoute(),
  iconName: "ic:outline-notifications",
  children: [
    {
      title: getTranslatedText("notification"),
      path: listNotificationRoute(),
    },
    {
      title: getTranslatedText("webNotification"),
      path: listWebNotificationRoute(),
    }
    // TODO: import subdomain menu items
  ],
};
