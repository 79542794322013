import helpCenterLocale from "../subdomains/helpCenter/locales/tr";
import helpCenterCategoryLocale from "../subdomains/helpCenterCategory/locales/tr";

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////

  helpCenter: {
    ...helpCenterLocale,
  },

  // TODO: import subdomain locales
  helpCenterCategory: {
    ...helpCenterCategoryLocale,
  },
};

export default locale;
