import { styled } from '@mui/material';
import { MaterialDesignContent, SnackbarProvider as Snack } from 'notistack';


export const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent': {
        backgroundColor: '#FFFFFFCC',
        borderRadius: 12,
        boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.1)',
    },
    '&.notistack-MuiContent-success': {
        color: 'green',
        border: '1px solid #888',
    },
    '&.notistack-MuiContent-error': {
        color: 'darkred',
        border: '1px solid #888',
    },
    '&.notistack-MuiContent-warning': {
        color: 'orange',
        border: '1px solid #888',
    },
    '&.notistack-MuiContent-info': {
        color: 'blue',
        border: '1px solid #888',
    },
}));

export const SnackbarProvider = ({ children }: { children: React.ReactNode }) => {
    return <Snack maxSnack={3}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        Components={{
            success: StyledMaterialDesignContent,
            error: StyledMaterialDesignContent,
            warning: StyledMaterialDesignContent,
            info: StyledMaterialDesignContent,
        }}>
        {children}
    </Snack>
}

