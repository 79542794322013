const locale = {
  subscriptionForm: "Subscriptionform",
  subscriptionForms: "Subscriptionforms",
  create: "Create Subscriptionform",
  update: "Update Subscriptionform",
  delete: "Delete Subscriptionform",
  view: "View Subscriptionform",
  list: "List Subscriptionforms",
  createdSuccessfully: "Subscriptionform created successfully",
  updatedSuccessfully: "Subscriptionform updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Subscriptionform deleted successfully",
  email: "E-posta",
  fullName: "Ad Soyad",
  status: "Durum",
  userType: "Kullanıcı Tipi",
  blog: "Blog",
  news: "Haber",
  offer: "Teklif",
  product: "Ürün",
  language: "Dil",
  page: "Sayfa",
  createdAt: "Oluşturulma Tarihi",
  updatedAt: "Güncellenme Tarihi",
};

export default locale;
