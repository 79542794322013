import contactFormLocale from "../subdomains/contactForm/locales/tr";
import subscriptionFormLocale from "../subdomains/subscriptionForm/locales/tr";

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////

  contactForm: {
    ...contactFormLocale,
  },

  // TODO: import subdomain locales
  subscriptionForm: {
    ...subscriptionFormLocale,
  },
};

export default locale;
