import React from 'react';
import { Pagination, TablePagination } from '@mui/material';
import { t } from 'i18next';
import { pageCount } from 'utils/pagination';

interface IMyPaginationProps {
    count: number,
    page: number,
    onChange: (event: React.ChangeEvent<unknown>, value: number) => void
}

interface IMyTablePaginationProps {
    count: number,
    pageSize: number,
    page: number,
    onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void,
    onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

export const MyPagination = ({ count, page, onChange }: IMyPaginationProps) => {
    return (
        <Pagination
            count={count}
            page={page}
            color="primary"
            onChange={onChange}
        />
    );
}

export const MyTablePagination = ({ count, pageSize, page, onPageChange, onRowsPerPageChange }: IMyTablePaginationProps) => {

    const labelDisplayedRows = ({ count, page }: { count: number, page: number }) => {
        return (
            <MyPagination
                count={
                    pageCount({
                        listResultCount: count,
                        pageSize: pageSize,
                        defaultPageSize: 10,
                    })}
                page={page + 1}
                onChange={(e, newPage) => onPageChange(e as any, newPage - 1)}
            />
        );
    };

    return (
        <TablePagination
            sx={{
                '& .MuiTablePagination-actions': {
                    display: 'none'
                }
            }}
            rowsPerPageOptions={[5, 10, 20]}
            count={count}
            rowsPerPage={pageSize}
            page={page}
            labelRowsPerPage={t('rowsPerPage')}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            labelDisplayedRows={labelDisplayedRows}
        />
    );
};
