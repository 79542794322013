import React from 'react'
import { Box, Grid, Typography, Container } from '@mui/material'


export const AuthLayout = ({ children }: { children: React.ReactNode }) => {
  return <Container>
    <Grid container >
      {children}
    </Grid>
  </Container >

}
