import { Card, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { usePolicyQuery } from "../context/policy.api";
import { useAppDispatch } from "context";
import { policyActions } from "../../../context";
import { useEffect } from "react";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import { IBaseError } from "corede-common";
import { getCurrentLanguage } from "localization";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { listPolicyRoute, updatePolicyRoute } from "../../../routes/policy.base.route";
import parse from "html-react-parser";
import moment from "moment";

const View = () => {
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const { id } = useParams();
  if (!id) {
    navigate(listPolicyRoute());
  }
  // queries
  const {
    data: viewPolicyData,
    isLoading: viewPolicyIsLoading,
    error: viewPolicyError,
  } = usePolicyQuery({
    input: { _id: id! },
  });

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      viewPolicyError as IBaseError,
      currentLanguage
    );
  }, [viewPolicyError, currentLanguage]);

  useEffect(() => {
    dispatch(policyActions.setTitle(t("main.policy.policy.view")));
    dispatch(policyActions.setBackButton(true));
    dispatch(
      policyActions.setRightButton({
        title: t(`main.policy.policy.update`),
        path: updatePolicyRoute(id!),
        icon: null,
      })
    );
  }, [dispatch, id]);


  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2}>
        {viewPolicyIsLoading ? (
          <CircularProgress />
        ) : (
          <Card sx={{ p: 4 }}>
            <Stack direction="column" gap={2}>
              <Typography>
                <strong>{t("main.policy.policy.title")}:</strong> {viewPolicyData?.title}
              </Typography>
              <Typography>
                <strong>{t("main.policy.policy.content")}:</strong> {parse(viewPolicyData?.content || "")}
              </Typography>
              <Typography>
                <strong>{t("main.policy.policy.domain")}:</strong> {viewPolicyData?.domain}
              </Typography>
              <Typography>
                <strong>{t("main.policy.policy.type")}:</strong> {viewPolicyData?.type}
              </Typography>
              <Typography>
                <strong>{t("main.policy.policy.target")}:</strong> {viewPolicyData?.target}
              </Typography>
              <Typography>
                <strong>{t("main.policy.policy.language")}:</strong> {viewPolicyData?.language}
              </Typography>
              <Typography>
                <strong>{t("main.policy.policy.versionCount")}:</strong> {viewPolicyData?.versionCount}
              </Typography>
              <Typography>
                <strong>{t("main.policy.policy.createdAt")}:</strong> {moment(viewPolicyData?.createdAt).format("DD-MM-YYYY HH:mm")}
              </Typography>
              <Typography>
                <strong>{t("main.policy.policy.updatedAt")}:</strong> {moment(viewPolicyData?.updatedAt).format("DD-MM-YYYY HH:mm")}
              </Typography>
            </Stack>
          </Card>
        )}
      </Stack>
    </Grid>
  );
};

export default View;
