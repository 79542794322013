const locale = {
  helpCenterCategory: "Help Center Category",
  helpCenterCategories: "Help Center Categories",
  create: "Create Help Center Category",
  update: "Update Help Center Category",
  delete: "Delete Help Center Category",
  view: "View Help Center Category",
  list: "List Help Center Categories",
  createdSuccessfully: "Help Center Category created successfully",
  updatedSuccessfully: "Help Center Category updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Help Center Category deleted successfully",
  name: "Name",
  icon: "Icon",
  parentCategory: "Parent Category",
  iconRequired: "Icon is required",
  nameRequired: "Name is required",
};

export default locale;
