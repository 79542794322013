import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import {
  useHelpCenterUpdateMutation,
  useHelpCenterQuery,
} from "../context/helpCenter.api";
import { useFormik } from "formik";
import { useAppDispatch } from "context";
import { helpCenterActions } from "../../../context";
import { IHelpCenterUpdateInput } from "corede-common-cocrm";
import { IBaseError, IFileMetadata, IGraphqlVariables, Language } from "corede-common";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { t } from "i18next";
import { useParams, useNavigate } from "react-router-dom";
import { validateUpdateHelpCenterInput } from "../validations/update.validation";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { listHelpCenterRoute } from "../../../routes/helpCenter.base.route";
import { useHelpCenterCategoriesQuery } from "../../helpCenterCategory";
import { UploadVideoAttachment } from "components/uploadFile/UploadVideoAttachment";

const Update = () => {

  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const [videoFile, setVideoFile] = useState<IFileMetadata | undefined>(undefined);
  const { id } = useParams();
  if (!id) {
    navigate(listHelpCenterRoute());
  }

  // queries
  const {
    data: helpCenterData,
    isLoading: helpCenterIsLoading,
    error: helpCenterError,
  } = useHelpCenterQuery({ input: { _id: id! } });

  const {
    data: helpCenterCategoriesData,
    isLoading: helpCenterCategoriesIsLoading,
    error: helpCenterCategoriesError,
  } = useHelpCenterCategoriesQuery({});

  // mutations
  const [
    updateHelpCenter,
    {
      data: updateHelpCenterData,
      isLoading: updateHelpCenterIsLoading,
      error: updateHelpCenterError,
    },
  ] = useHelpCenterUpdateMutation();

  // constants
  const {
    values,
    handleSubmit,
    handleBlur,
    setFieldValue,
    errors,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      input: {
        question: "",
        answer: "",
        readingTime: "",
        categoryId: "",
        language: currentLanguage,
      },
    },
    enableReinitialize: true,
    validationSchema: validateUpdateHelpCenterInput,
    onSubmit: (values: IGraphqlVariables<IHelpCenterUpdateInput>) => {
      updateHelpCenter({
        filter: { _id: id! },
        input: values.input,
      });
    },
  });

  // useEffects.success
  useEffect(() => {
    console.log(helpCenterData);
    if (helpCenterData) {
      setValues({
        input: {
          question: helpCenterData.question,
          answer: helpCenterData.answer,
          readingTime: helpCenterData.readingTime,
          categoryId: helpCenterData.category._id,
          language: helpCenterData.language,
        },
      });
    }
  }, [helpCenterData, setValues]);

  useEffect(() => {
    if (updateHelpCenterData) {
      resetForm();
      enqueueSnackbar(t("main.helpCenter.helpCenter.updatedSuccessfully"), {
        variant: "success",
      });
    }
  }, [updateHelpCenterData, resetForm]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      updateHelpCenterError as IBaseError,
      currentLanguage
    );
  }, [updateHelpCenterError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      helpCenterError as IBaseError,
      currentLanguage
    );
  }, [helpCenterError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      helpCenterCategoriesError as IBaseError,
      currentLanguage
    );
  }, [helpCenterCategoriesError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    dispatch(helpCenterActions.setTitle(t("main.helpCenter.helpCenter.update")));
    dispatch(helpCenterActions.setBackButton(true));
    dispatch(helpCenterActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2} maxWidth={400}>
        {helpCenterIsLoading ? (
          <CircularProgress />
        ) : (
          <Stack direction="column" gap={2}>
            <TextField
              fullWidth
              name="input.question"
              multiline
              rows={2}
              label={t("main.helpCenter.helpCenter.question")}
              value={(values as any).input.question}
              onChange={(e) => setFieldValue("input.question", e.target.value)}
              onBlur={() => handleBlur("input.question")}
              error={!!(errors as any).input?.question}
              helperText={(errors as any).input?.question}
            />
            <TextField
              fullWidth
              name="input.answer"
              multiline
              rows={4}
              label={t("main.helpCenter.helpCenter.answer")}
              value={(values as any).input.answer}
              onChange={(e) => setFieldValue("input.answer", e.target.value)}
              onBlur={() => handleBlur("input.answer")}
              error={!!(errors as any).input?.answer}
              helperText={(errors as any).input?.answer}
            />
            <TextField
              fullWidth
              name="input.readingTime"
              label={t("main.helpCenter.helpCenter.readingTime")}
              value={(values as any).input.readingTime}
              onChange={(e) => setFieldValue("input.readingTime", e.target.value)}
              onBlur={() => handleBlur("input.readingTime")}
              error={!!(errors as any).input?.readingTime}
              helperText={(errors as any).input?.readingTime}
            />
            <Autocomplete
              id="categoryId"
              isOptionEqualToValue={(option, value) => option._id === value._id}
              getOptionLabel={(option) => option.name}
              options={helpCenterCategoriesData?.data || []}
              loading={helpCenterCategoriesIsLoading}
              renderInput={(params) => <TextField {...params} label={t("main.helpCenter.helpCenter.category")} />}
              value={helpCenterCategoriesData?.data?.find((category) => category._id === (values as any).input.categoryId) || null}
              onChange={(e, value) => {
                setFieldValue("input.categoryId", value?._id);
              }}
              onBlur={() => handleBlur("input.categoryId")}
            />
            <Autocomplete
              id="language"
              isOptionEqualToValue={(option, value) => option === value}
              getOptionLabel={(option) => option}
              options={Object.values(Language)}
              renderInput={(params) => <TextField {...params} label={t("main.helpCenter.helpCenter.language")} />}
              value={(values as any).input.language}
              onChange={(e, value) => {
                setFieldValue("input.language", value);
              }}
              onBlur={() => handleBlur("input.language")}
            />
            <UploadVideoAttachment
              setVideoFile={setVideoFile}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
              disabled={updateHelpCenterIsLoading || !(values as any).input.question || !(values as any).input.answer || !(values as any).input.categoryId || !(values as any).input.language}
            >
              {updateHelpCenterIsLoading ? (
                <CircularProgress size="1rem" sx={{ mr: 2 }} />
              ) : (
                ""
              )}
              {t("update")}
            </Button>
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default Update;
