import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import {
  useUpdateFaqMutation,
  useFaqQuery,
} from "../context/faq.api";
import { useFormik } from "formik";
import { useAppDispatch } from "context";
import { faqActions } from "../../../context";
import { IUpdateFaqInput } from "corede-common-cocrm";
import { IBaseError, IGraphqlVariables, Language } from "corede-common";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { t } from "i18next";
import { useParams, useNavigate } from "react-router-dom";
import { validateUpdateFaqInput } from "../validations/update.validation";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { listFaqRoute } from "../../../routes/faq.base.route";
import { useFaqCategoriesQuery } from "../../faqCategory";

const Update = () => {

  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const { id } = useParams();
  if (!id) {
    navigate(listFaqRoute());
  }

  // queries
  const {
    data: faqData,
    isLoading: faqIsLoading,
    error: faqError,
  } = useFaqQuery({ input: { _id: id! } });

  const {
    data: faqCategoriesData,
    isLoading: faqCategoriesIsLoading,
    error: faqCategoriesError,
  } = useFaqCategoriesQuery({});

  // mutations
  const [
    updateFaq,
    {
      data: updateFaqData,
      isLoading: updateFaqIsLoading,
      error: updateFaqError,
    },
  ] = useUpdateFaqMutation();

  // constants
  const {
    values,
    handleSubmit,
    handleBlur,
    setFieldValue,
    errors,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      input: {
        question: "",
        answer: "",
        readingTime: "",
        categoryId: "",
        language: currentLanguage,
      },
    },
    enableReinitialize: true,
    validationSchema: validateUpdateFaqInput,
    onSubmit: (values: IGraphqlVariables<IUpdateFaqInput>) => {
      updateFaq({
        filter: { _id: id! },
        input: values.input,
      });
    },
  });

  // useEffects.success
  useEffect(() => {
    console.log(faqData);
    if (faqData) {
      setValues({
        input: {
          question: faqData.question,
          answer: faqData.answer,
          readingTime: faqData.readingTime,
          categoryId: faqData.category._id,
          language: faqData.language,
        },
      });
    }
  }, [faqData, setValues]);

  useEffect(() => {
    if (updateFaqData) {
      resetForm();
      enqueueSnackbar(t("main.faq.faq.updatedSuccessfully"), {
        variant: "success",
      });
    }
  }, [updateFaqData, resetForm]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      updateFaqError as IBaseError,
      currentLanguage
    );
  }, [updateFaqError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      faqError as IBaseError,
      currentLanguage
    );
  }, [faqError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      faqCategoriesError as IBaseError,
      currentLanguage
    );
  }, [faqCategoriesError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    dispatch(faqActions.setTitle(t("main.faq.faq.update")));
    dispatch(faqActions.setBackButton(true));
    dispatch(faqActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2} maxWidth={400}>
        {faqIsLoading ? (
          <CircularProgress />
        ) : (
          <Stack direction="column" gap={2}>
            <TextField
              fullWidth
              name="input.question"
              multiline
              rows={2}
              label={t("main.faq.faq.question")}
              value={(values as any).input.question}
              onChange={(e) => setFieldValue("input.question", e.target.value)}
              onBlur={() => handleBlur("input.question")}
              error={!!(errors as any).input?.question}
              helperText={(errors as any).input?.question}
            />
            <TextField
              fullWidth
              name="input.answer"
              multiline
              rows={4}
              label={t("main.faq.faq.answer")}
              value={(values as any).input.answer}
              onChange={(e) => setFieldValue("input.answer", e.target.value)}
              onBlur={() => handleBlur("input.answer")}
              error={!!(errors as any).input?.answer}
              helperText={(errors as any).input?.answer}
            />
            <TextField
              fullWidth
              name="input.readingTime"
              label={t("main.faq.faq.readingTime")}
              value={(values as any).input.readingTime}
              onChange={(e) => setFieldValue("input.readingTime", e.target.value)}
              onBlur={() => handleBlur("input.readingTime")}
              error={!!(errors as any).input?.readingTime}
              helperText={(errors as any).input?.readingTime}
            />
            <Autocomplete
              id="categoryId"
              isOptionEqualToValue={(option, value) => option._id === value._id}
              getOptionLabel={(option) => option.name}
              options={faqCategoriesData?.data || []}
              loading={faqCategoriesIsLoading}
              renderInput={(params) => <TextField {...params} label={t("main.faq.faq.category")} />}
              value={faqCategoriesData?.data?.find((category) => category._id === (values as any).input.categoryId) || null}
              onChange={(e, value) => {
                setFieldValue("input.categoryId", value?._id);
              }}
              onBlur={() => handleBlur("input.categoryId")}
            />
            <Autocomplete
              id="language"
              isOptionEqualToValue={(option, value) => option === value}
              getOptionLabel={(option) => option}
              options={Object.values(Language)}
              renderInput={(params) => <TextField {...params} label={t("main.faq.faq.language")} />}
              value={(values as any).input.language}
              onChange={(e, value) => {
                setFieldValue("input.language", value);
              }}
              onBlur={() => handleBlur("input.language")}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
              disabled={updateFaqIsLoading || !(values as any).input.question || !(values as any).input.answer  || !(values as any).input.categoryId  || !(values as any).input.language}
            >
              {updateFaqIsLoading ? (
                <CircularProgress size="1rem" sx={{ mr: 2 }} />
              ) : (
                ""
              )}
              {t("update")}
            </Button>
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default Update;
