import paymentProductLocale from "../subdomains/paymentProduct/locales/tr";

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////

  paymentProduct: {
    ...paymentProductLocale,
  },

  // TODO: import subdomain locales
};

export default locale;
