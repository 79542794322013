const locale = {
  paymentProduct: "Paymentproduct",
  paymentProducts: "Paymentproducts",
  create: "Create Paymentproduct",
  update: "Update Paymentproduct",
  delete: "Delete Paymentproduct",
  view: "View Paymentproduct",
  list: "List Paymentproducts",
  createdSuccessfully: "Paymentproduct created successfully",
  updatedSuccessfully: "Paymentproduct updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Paymentproduct deleted successfully",
};

export default locale;
