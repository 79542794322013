const locale = {
  notification: "Notification",
  notifications: "Notifications",
  create: "Create Notification",
  update: "Update Notification",
  delete: "Delete Notification",
  view: "View Notification",
  list: "List Notifications",
  createdSuccessfully: "Notification created successfully",
  updatedSuccessfully: "Notification updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Notification deleted successfully",
};

export default locale;
