import { FaqCategoryRouter } from "../subdomains/faqCategory/routes/FaqCategoryRouter";



import { FaqRouter } from "../subdomains/faq/routes/FaqRouter";
import { FaqDomainLayout } from "../layouts";
import { faqDomainRoutes } from "./faq.base.route";
import { Route, Routes } from "react-router-dom";

export const FaqDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<FaqDomainLayout />}>
        <Route index element={<FaqRouter />} />
        <Route
          path={`/${faqDomainRoutes.subdomains.faq.base}/*`}
          element={<FaqRouter />}
        />
        <Route
          path={`/${faqDomainRoutes.subdomains.faqCategory.base}/*`}
          element={<FaqCategoryRouter />}
        />
        {/*
          // TODO: import subdomains
        */}
      </Route>
    </Routes>
  );
};
