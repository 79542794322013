import {
  Grid,
  Stack,
  Button,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  Typography,
  TextField,
  Autocomplete,
  Chip,
  TableSortLabel
} from "@mui/material";
import { useAppDispatch } from "context";
import { faqActions } from "../../../context";
import {
  createFaqRoute,
  updateFaqRoute,
  viewFaqRoute,
} from "../../../routes/faq.base.route";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useFaqsQuery } from "../context/faq.api";
import { Delete as DeleteIcon, Edit, RemoveRedEye } from "@mui/icons-material";
import { MyTablePagination } from "components";
import { IBaseError, IGraphqlVariables, Language, TNullable } from "corede-common";
import {
  IBaseFaqEntity,
  IFaqsInput,
} from "corede-common-cocrm";
import { getCurrentLanguage } from "localization";
import { useNavigate } from "react-router-dom";
import Delete from "./Delete";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { useFaqCategoriesQuery } from "../../faqCategory";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs from "dayjs";

const List = () => {
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();

  // useStates
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 0,
  });
  const [selectedItemForDelete, setSelectedItemForDelete] =
    useState<TNullable<IBaseFaqEntity>>(null);
  const [variables, setVariables] = useState<
    IGraphqlVariables<IFaqsInput>
  >({
    input: {
      filter: {
        question: undefined,
        answer: undefined,
        categoryIds: undefined,
        languages: undefined,
        updatedAtDateFilter: undefined,
      },
      pagination: {
        pageSize: pagination.pageSize,
        page: pagination.page + 1,
        sort: undefined
      },
    },
  });

  // queries
  const {
    data: faqsData,
    isLoading: faqsIsLoading,
    error: faqsError,
  } = useFaqsQuery(variables);

  const {
    data: categoriesData,
    isLoading: categoriesIsLoading,
    error: categoriesError,
  } = useFaqCategoriesQuery({});

  // mutations

  // constants

  // action handlers
  const handleEdit = (id: string) => {
    navigate(updateFaqRoute(id));
  };

  const handleDelete = (item: IBaseFaqEntity) => {
    setSelectedItemForDelete(item);
  };

  const handleView = (id: string) => {
    navigate(viewFaqRoute(id));
  };

  const handleSortRequest = (sortName: string) => {

    sortName === "index" ?
      setVariables({
        ...variables,
        input: {
          ...variables.input,
          pagination: {
            ...variables.input?.pagination,
            sort: undefined
          }
        }
      })
      :
      setVariables({
        ...variables,
        input: {
          ...variables.input,
          pagination: {
            ...variables.input?.pagination,
            sort: {
              [sortName]: variables.input?.pagination?.sort?.[sortName] === 1 ? -1 : 1
            }
          }
        }
      })
  };

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      faqsError as IBaseError,
      currentLanguage
    );
  }, [faqsError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    setVariables({
      input: {
        pagination: {
          pageSize: pagination.pageSize,
          page: pagination.page + 1,
        },
      },
    });
  }, [pagination]);

  useEffect(() => {
    dispatch(faqActions.setTitle(t("main.faq.faq.faqs")));
    dispatch(faqActions.setBackButton(false));
    dispatch(
      faqActions.setRightButton({
        title: t(`main.faq.faq.create`),
        path: createFaqRoute(),
        icon: null,
      })
    );
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      {/* Filters */}
      <Stack direction={"column"} gap={2} mb={2} border={"1px solid #CDCFD1"} borderRadius={2} p={2}>
        <Stack direction="row" gap={2}>
          <Typography variant="h5">{t("main.faq.faq.filters")}</Typography>
          <Stack direction="row" gap={1}>
            {
              Object.values(Language).map((language) => (
                <Chip
                  key={language}
                  label={language}
                  variant={variables.input?.filter?.languages?.[0] === language ? "filled" : "outlined"}
                  onClick={() => {
                    setVariables({
                      ...variables,
                      input: {
                        ...variables.input,
                        filter: {
                          ...(variables.input?.filter || {}),
                          languages: variables.input?.filter?.languages?.[0] === language ? undefined : [language],
                        },
                      },
                    });
                  }}
                />
              ))
            }
          </Stack>
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} gap={2}>
          <TextField
            fullWidth
            label={t("main.faq.faq.question")}
            value={variables.input?.filter?.question}
            onChange={(e) => {
              setVariables({
                ...variables,
                input: {
                  ...variables.input,
                  filter: {
                    ...(variables.input?.filter || {}),
                    question: e.target.value,
                  },
                },
              });
            }}
          />
          <TextField
            fullWidth
            label={t("main.faq.faq.answer")}
            value={variables.input?.filter?.answer}
            onChange={(e) => {
              setVariables({
                ...variables,
                input: {
                  ...variables.input,
                  filter: {
                    ...(variables.input?.filter || {}),
                    answer: e.target.value,
                  },
                },
              });
            }}
          />
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} gap={2}>
          <Autocomplete
            id="categoryId"
            isOptionEqualToValue={(option, value) => option._id === value._id}
            fullWidth
            getOptionLabel={(option) => option.name}
            options={categoriesData?.data || []}
            loading={categoriesIsLoading}
            renderInput={(params) => <TextField {...params} label={t("main.faq.faq.category")} />}
            value={categoriesData?.data?.find((category) => category._id === variables.input?.filter?.categoryIds?.[0])}
            onChange={(e, value) => {
              setVariables({
                ...variables,
                input: {
                  ...variables.input,
                  filter: {
                    ...(variables.input?.filter || {}),
                    categoryIds: value?._id ? [value?._id] : undefined,
                  },
                },
              });
            }}
          />
          <DateRangePicker
            sx={{ width: "100%" }}
            label={t("main.faq.faq.updatedAtDateFilter")}
            value={variables.input?.filter?.updatedAtDateFilter ?
              [dayjs(variables.input.filter.updatedAtDateFilter.from),
              dayjs(variables.input.filter.updatedAtDateFilter.to)]
              : undefined}
            onChange={(e) => {
              setVariables({
                ...variables,
                input: {
                  ...variables.input,
                  filter: {
                    ...(variables.input?.filter || {}),
                    updatedAtDateFilter: {
                      from: e?.[0]?.toDate() || undefined,
                      to: e?.[1]?.toDate() || undefined,
                    }
                  },
                },
              });
            }}
          />
        </Stack>
      </Stack>
      <Card sx={{ p: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={variables.input?.pagination?.sort?.index !== undefined}
                    direction={variables.input?.pagination?.sort?.index === 1 ? "asc" : "desc"}
                    onClick={() => handleSortRequest("index")}
                  >#</TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={variables.input?.pagination?.sort?.question !== undefined}
                    direction={variables.input?.pagination?.sort?.question === 1 ? "asc" : "desc"}
                    onClick={() => handleSortRequest("question")}
                  >{t("question")}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={variables.input?.pagination?.sort?.answer !== undefined}
                    direction={variables.input?.pagination?.sort?.answer === 1 ? "asc" : "desc"}
                    onClick={() => handleSortRequest("answer")}
                  >{t("answer")}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={variables.input?.pagination?.sort?.readingTime !== undefined}
                    direction={variables.input?.pagination?.sort?.readingTime === 1 ? "asc" : "desc"}
                    onClick={() => handleSortRequest("readingTime")}
                  >{t("readingTime")}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={variables.input?.pagination?.sort?.category !== undefined}
                    direction={variables.input?.pagination?.sort?.category === 1 ? "asc" : "desc"}
                    onClick={() => handleSortRequest("category")}
                  >{t("category")}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={variables.input?.pagination?.sort?.language !== undefined}
                    direction={variables.input?.pagination?.sort?.language === 1 ? "asc" : "desc"}
                    onClick={() => handleSortRequest("language")}
                  >{t("language")}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">{t("actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!faqsIsLoading
                ? faqsData?.data?.map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{(pagination.page ?? 0) * pagination.pageSize + index + 1}</TableCell>
                    <TableCell>{row.question}</TableCell>
                    <TableCell>{row.answer}</TableCell>
                    <TableCell>{row.readingTime}</TableCell>
                    <TableCell>{row.category.name}</TableCell>
                    <TableCell>{row.language}</TableCell>
                    <TableCell align="right">
                      <Stack direction="row" justifyContent={"flex-end"} gap={1} flexWrap={"wrap"}>
                        <Button
                          onClick={() => handleView(row._id)}
                          variant="outlined"
                          size="small"
                          sx={{ height: 32, minWidth: 10 }}
                        >
                          <RemoveRedEye sx={{ fontSize: "16px" }} />
                        </Button>
                        <Button
                          onClick={() => handleEdit(row._id)}
                          variant="outlined"
                          size="small"
                          sx={{ height: 32, minWidth: 10 }}
                        >
                          <Edit sx={{ fontSize: "16px" }} />
                        </Button>
                        <Button
                          onClick={() => handleDelete(row)}
                          variant="outlined"
                          size="small"
                          color="error"
                          sx={{ height: 32, minWidth: 10 }}
                        >
                          <DeleteIcon sx={{ fontSize: "16px" }} />
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
                : [1, 2, 3].map((key) => (
                  <TableRow key={key}>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" sx={{ width: '100%' }} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <Stack
        direction="row"
        justifyContent={{ xs: "center", md: "flex-start" }}
        mt={2}
        mb={2}
      >
        <MyTablePagination
          count={faqsData?.count ?? 0}
          pageSize={pagination.pageSize}
          page={pagination.page ?? 0}
          onPageChange={(e, page) => {
            setPagination({
              ...pagination,
              page: page,
            });
          }}
          onRowsPerPageChange={(e) => {
            setPagination({
              page: 0,
              pageSize: parseInt(e.target.value)
            });
          }}
        />
      </Stack>

      <Delete
        open={!!selectedItemForDelete}
        item={selectedItemForDelete!}
        onClose={() => {
          setSelectedItemForDelete(null);
        }}
      />
    </Grid>
  );
};

export default List;
